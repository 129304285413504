import { Component, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private renderer: Renderer2) {}
  title = 'open-api-fe';

  ngOnInit() {
    this.initLiveChat();
  }

  private initLiveChat() {
    const script = this.renderer.createElement('script');
    script.src = 'https://client.crisp.chat/l.js';
    (window as any).$crisp = [];
    (window as any).CRISP_WEBSITE_ID = environment.CRISP_WEBSITE_ID;
    script.type = 'text/javascript';
    script.async = true;
    this.renderer.appendChild(document.head, script);
  }
}
