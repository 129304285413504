import {
  Component,
  EventEmitter,
  Input,
  Output,
  LOCALE_ID,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { wdListModel } from 'src/app/models/withdrawl-list.model';
import { BalanceService } from 'src/app/services/api/balance.service';
import { BaseService } from 'src/app/services/base.service';
import * as moment from 'moment';
import 'moment/locale/id';
import {
  LocaleConfig,
} from 'ngx-daterangepicker-material';
import { Dayjs } from 'dayjs';
import { Router } from '@angular/router';
import { LocaleConfigDate } from 'src/app/constants/date-config.const';

@Component({
  selector: 'app-table-riwayat-penarikan-saldo',
  templateUrl: './table-riwayat-penarikan-saldo.component.html',
  styleUrls: ['./table-riwayat-penarikan-saldo.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'id' }],
})
export class TableRiwayatPenarikanSaldoComponent {
  @Input() wdList: wdListModel[] = [];

  destroy$ = new Subject();

  bankNumber!: string;
  titlePage: string = 'Balance Withdrawal History';

  @Input() filterList: any = {
    start_date: moment().startOf('month').locale('id').format('YYYY-MM-DD'),
    end_date: moment().endOf('month').locale('id').format('YYYY-MM-DD'),
    type: '',
  };

  selected: any = {
    startDate: moment().startOf('month').locale('id'),
    endDate: moment().endOf('month').locale('id'),
  };

  ranges: any = {
    'Today': [moment(), moment()],
    'This Week': [moment().subtract(6, 'days'), moment()],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'All': [moment().startOf('year'), moment()],
  };

  invalidDates: moment.Moment[] = [
    moment().add(2, 'days'),
    moment().add(3, 'days'),
    moment().add(5, 'days'),
  ];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  @Output() filterResult = new EventEmitter();
  setlocale: LocaleConfig = LocaleConfigDate
  keepCalendarOpeningWithRange: boolean;
  loading: boolean = true;

  constructor(
    private balanceService: BalanceService,
    public baseService: BaseService,
    private route: Router,
  ) {
    this.keepCalendarOpeningWithRange = true;
  }

  ngOnInit(): void {
    this.getListWD();
  }

  onChangeDate(e: any) {
    const startDate: Dayjs = e.startDate;
    const endDate: Dayjs = e.endDate;

    this.filterList.start_date = startDate ? startDate.format('YYYY-MM-DD') : this.selected.startDate.format('YYYY-MM-DD');
    this.filterList.end_date = endDate ? endDate.format('YYYY-MM-DD') : this.selected.endDate.format('YYYY-MM-DD');

    this.filterResult.emit(this.filterList);
    this.getListWD();
}
  getListWD() {
    this.loading = true
    const currentRoute = this.route.url;
    if (currentRoute.endsWith('/finance/balance')) {
      this.filterList.type = 'balance';
      this.filterResult.emit(this.filterList);
      this.titlePage = 'Balance Withdrawal History';
    } else {
      this.filterList.type = 'commission';
      this.filterResult.emit(this.filterList);
      this.titlePage = 'Commission Withdrawal History';
    }

    this.balanceService
    .withdrawlList(this.filterList)
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (res) => {
        this.loading = false;
        this.wdList = res.data || [];

        for (let i = 0; i < this.wdList.length; i++) {
          const numstring = this.wdList[i]?.bank_number || '';
          this.bankNumber = JSON.stringify(numstring).replace(/"/g, '');
        }

        this.wdList.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  moveDetail(i: any) {
    const id = this.wdList[i].id;

    this.route.navigate(['finance/balance/detail/', id]);
  }

  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }
}
