import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Router } from '@angular/router';
import { BaseService } from '../../services/base.service';
import {
  EXPORT_INVOICE,
  HISTORY_BILLINGS,
  STATUS_BILLINGS,
} from 'src/app/constants/endpoint.const';
import { Subject, takeUntil } from 'rxjs';
import {
  historyBillingsType,
  statusBillingsType,
} from 'src/app/types/response/billings-response.type';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { environment as sandEnv } from 'src/environments/environment.sandbox';
import { environment as devEnv } from 'src/environments/environment';
import { BypassInterceptorContext } from 'src/app/interceptors/http.interceptor';

@Component({
  selector: 'app-billings',
  templateUrl: './billings.component.html',
  styleUrls: ['./billings.component.scss'],
  animations: [
    trigger('collapseExpand', [
      state(
        'collapsed',
        style({
          height: '0px',
          opacity: 0,
          overflow: 'hidden',
        })
      ),
      state(
        'expanded',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      transition('collapsed => expanded', [animate('300ms ease-out')]),
      transition('expanded => collapsed', [animate('300ms ease-in')]),
    ]),
  ],
})
export class BillingsComponent implements OnInit {
  activeDetail: boolean = false;
  destroy$ = new Subject();
  listOfData: historyBillingsType[] = [];
  statusBillings: statusBillingsType = {};
  loading: boolean = true;
  pageSize: number = 20;
  offset: number = 0;
  shortData: string = 'desc';
  isDescend: boolean = true;
  listOfColumns: any[] = [
    {
      name: 'Status',
    },
    {
      name: 'Date',
    },
    {
      name: 'Active Until',
    },
    {
      name: 'Membership Package',
    },
    {
      name: 'Payment',
    },
    {
      name: '',
    },
  ];
  skeleton: boolean = true;

  constructor(
    private router: Router,
    private BaseService: BaseService,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.getStatusBillings();
    this.getHistoryBillings();
    setTimeout(() => {
      this.skeleton = false;
    }, 1000);
  }

  handleUpgrade() {
    this.router.navigate(['/billings/upgrade-package']);
  }

  getStatusBillings() {
    this.BaseService.getData(STATUS_BILLINGS)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.statusBillings = res.data;
        localStorage.setItem('active_package', res.data.package_name);
      });
  }

  shortHistoryBillings() {
    this.isDescend = !this.isDescend;

    if (this.isDescend === true) {
      this.shortData = 'desc';
    } else {
      this.shortData = 'asc';
    }

    this.BaseService.getData(
      HISTORY_BILLINGS + `?order_by=${this.shortData}&limit=20&offset=1`
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.loading = false;
          this.listOfData = res.data;
        },
        (err) => {
          this.loading = false;
        }
      );
  }
  getHistoryBillings() {
    this.BaseService.getData(
      HISTORY_BILLINGS +
        `?order_by=${this.shortData}&limit=${this.pageSize}&offset=${
          this.offset * this.pageSize
        }`
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.loading = false;
          this.listOfData = [...this.listOfData, ...res.data];
        },
        (err) => {
          this.loading = false;
        }
      );
  }

  styleStatus(e: string) {
    if (e === 'pending') {
      return 'unpaid';
    } else if (e === 'cancel') {
      return 'cancel';
    } else {
      return 'success-pkg';
    }
  }

  iconPackage(e: string) {
    if (e === 'enterprise') {
      return 'https://storage.googleapis.com/komerce/rajaongkir/Label-enterprise.svg';
    } else if (e === 'pro') {
      return 'https://storage.googleapis.com/komerce/rajaongkir/Label-pro.svg';
    } else {
      return 'https://storage.googleapis.com/komerce/rajaongkir/Label-starter.svg';
    }
  }

  handleStatus(e: string) {
    if (e === 'success') return 'Success';
    else if (e === 'cancel') return 'Cancel';
    else if (e === 'pending') return 'Unpaid';
    return e;
  }

  onScroll(): void {
    if (
      this.loading ||
      this.listOfData.length < this.pageSize * (this.offset + 1)
    ) {
      return;
    }

    this.loading = true;
    this.offset += 1;
    this.getHistoryBillings();
  }

  downloadInvoice(invoice_id: string) {
    const baseUrl = sandEnv.BASE_URL;
    const endPoint = '/user/api/v1/invoice/export';
    const apiUrl = baseUrl + endPoint;

    const token = localStorage.getItem('auth-token');
    const name = localStorage.getItem('auth-user');

    const headers = new HttpHeaders({
      Accept: 'application/pdf',
      Authorization: `Bearer ${token}`,
    });

    const context = new HttpContext().set(BypassInterceptorContext, true);

    this.httpClient
      .post(apiUrl, { invoice_id }, { headers, context, responseType: 'blob', observe: 'response' })
      .subscribe({
        next: (res) => {
          let filename = `Invoice-RO${name}.pdf`;

          const contentDisposition = res.headers.get('Content-Disposition') || res.headers.get('content-disposition');

          if (contentDisposition) {
            const matches = contentDisposition.match(/filename="(.+)"/);
            if (matches && matches.length > 1) {
              filename = matches[1];
            }
          }

          const newBlob = new Blob([res.body!], { type: 'application/pdf' });
          const downloadURL = window.URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        error: (err) => {
          console.error('Error downloading invoice:', err);
        }
      });
}

}
