import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() totalItems: number = 0;
  @Input() perPage: number = 20;
  @Input() itemsPerPage: number = 0;
  @Input() currentPage: number = 1;
  @Input() perPageOptions: number[] = [20, 50, 100];
  @Input() disablePrev: boolean = false;
  @Input() disableNext: boolean = false;

  @Output() pageChange = new EventEmitter<number>();
  @Output() perPageChange = new EventEmitter<number>();

  get totalPages(): number {
    return Math.ceil(this.totalItems / this.perPage);
  }

  get isPrevDisabled(): boolean {
    return this.currentPage <= 1;
  }

  get isNextDisabled(): boolean {
    return this.currentPage >= this.totalPages;
  }

  changePage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.pageChange.emit(page);
    }
  }

  changePerPage(perPage: number): void {
    this.perPageChange.emit(perPage);
  }
}
