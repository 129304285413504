import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject, takeUntil } from 'rxjs';
import { validatePassword } from 'src/app/constants/auth-config.const';
import { CHANGE_PASSWORD } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';
import { FormValidationService } from 'src/app/services/form-validation.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  destroy$ = new Subject();

  formGroupChangePass: FormGroup = new FormGroup({
    oldPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(32),
    ]),
    newPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(32),
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(32),
    ]),
  });

  eyeOn =
    'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye.svg';
  eyeOff =
    'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye-slash.svg';

  validationPassword = validatePassword

  errMessPass: string = '';
  visibleValPass: boolean = false;
  isValidPass: boolean = true;
  isShowOldPassword: boolean = false;
  isShowPassword: boolean = false;
  isShowPassword2: boolean = false;
  errMessPass2: string = '';
  isErrPass: boolean = false;
  errMessPassOld: string = '';

  constructor(
    private route: Router,
    private baseService: BaseService,
    private message: NzMessageService,
    private formValidation: FormValidationService
  ) {}
  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }

  handleCancel() {
    this.route.navigate(['/profile']);
  }

  setRegexPassword(event: any) {
    const regex = /^[a-zA-Z0-9`~!()\-_+={}\[\]:;'.<>\/]+$/g;
    return regex.test(event.key);
  }

  validatePassword() {
    this.visibleValPass = true;
    const value = this.formGroupChangePass.get('newPassword')?.value || '';
  
    const result = this.formValidation.validatePassword(value, this.validationPassword);
    
    this.isValidPass = result.isValid;
    this.errMessPass = result.isValid ? '' : 'Password does not comply with the provisions';
  
    if (value.length === 0) {
      this.errMessPass = 'Password must be filled';
    }
  
    const value2 = this.formGroupChangePass.get('confirmPassword')?.value || '';
    this.errMessPass2 = value !== value2 && value2 !== '' ? 
      'Make sure the password confirmation is the same as the previous password.' : '';
  
    this.formGroupChangePass.get('newPassword')?.setErrors(
      result.errors.length > 0 ? { passwordInvalid: result.errors.join(', ') } : null
    );
  }

  oldPassword() {
    const value = this.formGroupChangePass.get('oldPassword')?.value || '';

    if (value.length === 0) {
      this.errMessPassOld = 'Old Password is must be filled';
    } else if (value.length < 8) {
      this.errMessPassOld = 'Minimum password is 8 characters';
    } else if (value.length > 32) {
      this.errMessPassOld = 'Maximum password is 32 characters';
    } else {
      this.errMessPassOld = '';
    }
  }
  confirmPassword() {
    const value1 = this.formGroupChangePass.get('newPassword')?.value || '';
    const value2 = this.formGroupChangePass.get('confirmPassword')?.value || '';

    if (value1 !== value2 && value2 !== '') {
      this.errMessPass2 =
        'Make sure the password confirmation is the same as the new password.';
    } else if (value2.length === 0) {
      this.errMessPass2 = 'Confirm Password is must be filled';
    } else {
      this.errMessPass2 = '';
    }
  }

  handleSubmit() {
    const params = {
      password: this.formGroupChangePass.get('oldPassword')?.value,
      new_password: this.formGroupChangePass.get('newPassword')?.value,
      confirm_password: this.formGroupChangePass.get('confirmPassword')?.value,
    };
    this.baseService
      .putData(CHANGE_PASSWORD, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.message.create(
            'success',
            'Your password has been successfully changed.'
          );
          this.route.navigate(['/profile']);
        },
        (err) => {
          if (err.error.meta.message === 'old password does not match') {
            this.message.create(
              'error',
              'Your old password is incorrect, please check again.'
            );
            this.errMessPassOld =
              'Your old password is incorrect, please check again.';
          } else {
            this.message.create(
              'error',
              'Failed to save change password, please try again'
            );
          }
        }
      );
  }
}
