import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-business-questionaire-input',
  standalone: false,
  templateUrl: './business-questionaire-input.component.html',
  styleUrl: './business-questionaire-input.component.scss'
})
export class BusinessQuestionaireInputComponent {
  [key: string]: any;
  businessForm!: FormGroup;
  current: number = 2;
  radioValue: string = '1';
  @Input() slaDataInput: any;
  @Output() businessData = new EventEmitter<any>();
  errMessEmail: string = '';
  validEmail: boolean = true;
  errMessBName: string = '';
  validBName: boolean = true;
  errMessJName: string = '';
  validJName: boolean = true;
  validNumber: boolean = true;
  errMessNumber: string = '';
  validAddress: boolean = true;
  errMessAddress: string = '';
  validTax: boolean = true;
  errMessTax: string = '';
  validNPWP: boolean = true;
  errMessNPWP: string = '';
  validNIB: boolean = true;
  errMessNIB: string = '';

  constructor(
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit() {
    this.initForm();

    if (this.businessForm) {
      this.businessForm.patchValue(this.slaDataInput);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes['slaDataInput'] && changes['slaDataInput'].currentValue) {
        const slaData = changes['slaDataInput'].currentValue;

        if (this.businessForm) {
          this.businessForm.patchValue(slaData);
        }
      }
    }

    initForm() {
      this.businessForm = this.fb.group({
        has_business_entity_deed: [null],
        has_taxable_entrepreneur_certificate: [null],
        taxable_certificate_number: [{ value: '', disabled: true }, [Validators.required, Validators.pattern('^\\d{15}$')]],
        has_taxpayer_identification_number: [null],
        taxpayer_number: [{ value: '', disabled: true }, [Validators.required, Validators.pattern('^\\d{15}$')]],
        is_sla_under_power_of_attorney: [null],
        has_authorization_ktp_or_kitas: [null],
        has_business_identification_number: [null],
        business_identification_number: [{ value: '', disabled: true }, [Validators.required, Validators.pattern('^\\d{13}$')]],
        authorization_holder_name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
        authorization_holder_email: ['', [Validators.required, Validators.email]],
        authorization_holder_job_title: ['', Validators.required],
        authorization_holder_address: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(100)]],
        authorization_holder_phone: ['', [Validators.required, Validators.pattern('^[0-9]*\\.?[0-9]*$')]]
      });
    
      this.setupConditionalValidation('has_taxable_entrepreneur_certificate', 'taxable_certificate_number', '^\\d{15}$', 'errMessTax', 'validTax');
      this.setupConditionalValidation('has_taxpayer_identification_number', 'taxpayer_number', '^\\d{15}$', 'errMessNPWP', 'validNPWP');
      this.setupConditionalValidation('has_business_identification_number', 'business_identification_number', '^\\d{13}$', 'errMessNIB', 'validNIB');
    }
    
    setupConditionalValidation(toggleField: string, targetField: string, pattern: string, errorField: string, validField: string) {
      this.businessForm.get(toggleField)?.valueChanges.subscribe(value => {
        const control = this.businessForm.get(targetField);
        if (value) {
          control?.setValidators([Validators.required, Validators.pattern(pattern)]);
          control?.enable();
        } else {
          control?.clearValidators();
          control?.disable();
          control?.setValue('');
          (this as any)[errorField] = '';
          (this as any)[validField] = true;
        }
        control?.updateValueAndValidity();
      });
    }    

  preventNonNumeric(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;

    if (
      !/^\d$/.test(event.key) &&
      !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(
        event.key
      )
    ) {
      event.preventDefault();
    }

    if (input.value.length === 0 && event.key !== '8') {
      event.preventDefault();
    }
  }
  preventNonNumericTax(event: KeyboardEvent): void {if (
      !/^\d$/.test(event.key) &&
      !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(
        event.key
      )
    ) {
      event.preventDefault();
    }
  }

  validateField(event: Event, toggleField: string, targetField: string, length: number, errorField: string, validField: string, errorMessage: string) {
    const input = event.target as HTMLInputElement;
    const hasCertificate = this.businessForm.get(toggleField)?.value;
  
    if (!hasCertificate) {
      (this as any)[errorField] = '';
      (this as any)[validField] = true;
      return;
    }
  
    if (input.value.length === 0) {
      (this as any)[validField] = false;
      (this as any)[errorField] = `${errorMessage} must be filled`;
    } else if (!new RegExp(`^\\d{${length}}$`).test(input.value)) {
      (this as any)[errorField] = `${errorMessage} must be exactly ${length} digits.`;
      (this as any)[validField] = false;
    } else {
      (this as any)[validField] = true;
      (this as any)[errorField] = '';
    }
  }
  
  validateTax(event: Event) {
    this.validateField(event, 'has_taxable_entrepreneur_certificate', 'taxable_certificate_number', 15, 'errMessTax', 'validTax', 'SPPKP number');
  }
  
  validateNPWP(event: Event) {
    this.validateField(event, 'has_taxpayer_identification_number', 'taxpayer_number', 15, 'errMessNPWP', 'validNPWP', 'Taxpayer Identification Number');
  }
  
  validateNIB(event: Event) {
    this.validateField(event, 'has_business_identification_number', 'business_identification_number', 13, 'errMessNIB', 'validNIB', 'Business Identification Number');
  }
  

  validateInput(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.value.length === 0) {
      this.validNumber = false;
      this.errMessNumber = 'Phone number must be filled';
    } else if (!/^\d+$/.test(input.value)) {
      this.validNumber = false;
      this.errMessNumber = 'Must be a number';
    } else if (!input.value.startsWith('8')) {
      this.validNumber = false;
      this.errMessNumber = 'Phone number must start with number 8';
      input.value = '';
    } else if (input.value.length < 9) {
      this.validNumber = false;
      this.errMessNumber = 'Minimum 9 digits';
    } else if (input.value.length > 12) {
      this.validNumber = false;
      this.errMessNumber = 'Maximum 12 digits';
    } else {
      this.validNumber = true;
      this.errMessNumber = '';
    }
  }

  setRegex(event: any) {
    const regex = /^[a-zA-Z0-9+@._-]+$/g;
    return regex.test(event.key);
  }

  validateEmail(event: Event) {
    const input = event.target as HTMLInputElement || '';

    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const doubleSymbolRegex = /(\.\.|@@|--|\+\+|__|\.@|@\.|-@|@-|_\.|@\+|\+@)/;

    if (input.value === '') {
        this.validEmail = false;
        this.errMessEmail = 'Email must be filled in';
    } else if (input.value.length < 10) {
        this.errMessEmail = 'Minimum 10 characters';
        this.validEmail = false;
    } else if (input.value.length > 50) {
        this.errMessEmail = 'Maximum 50 characters';
        this.validEmail = false;
    } else if (!regex.test(input.value)) {
        this.validEmail = false;
        this.errMessEmail = 'Email must be a valid email address';
    } else if (doubleSymbolRegex.test(input.value)) {
        this.validEmail = false;
        this.errMessEmail = 'Email contains invalid consecutive symbols';
    } else {
        this.validEmail = true;
        this.errMessEmail = '';
    }
}

  setRegexBName(event: any) {
    const regex = /^[a-zA-Z .-]+$/g;
    return regex.test(event.key);
  }

  validateInputField(event: any, fieldType: 'BName' | 'JName') {
    const input = event.target as HTMLInputElement;
    const minLength = 3;
    const maxLength = 50;
    const fieldValidKey = `valid${fieldType}`;
    const fieldErrorKey = `errMess${fieldType}`;

    if (input.value === '') {
      this[fieldValidKey] = false;
      this[fieldErrorKey] = `Authorization Holder ${fieldType === 'BName' ? 'Name' : 'Job'} must be filled in`;
    } else if (input.value.length < minLength) {
      this[fieldValidKey] = false;
      this[fieldErrorKey] = `Minimum ${minLength} characters`;
    } else if (input.value.length > maxLength) {
      this[fieldValidKey] = false;
      this[fieldErrorKey] = `Maximum ${maxLength} characters`;
    } else {
      this[fieldValidKey] = true;
      this[fieldErrorKey] = '';
    }
  }


  validateAddress(event: any) {
    const input = event.target as HTMLInputElement;

    if (input.value.length === 0) {
      this.validAddress = false;
      this.errMessAddress = 'Address cannot be empty';
    } else if (input.value.length > 0 && input.value.length < 10) {
      this.validAddress = false;
      this.errMessAddress = 'Minimum 10 characters';
    } else if (input.value.length > 100) {
      this.validAddress = false;
      this.errMessAddress = 'Maximum 100 characters';
    } else {
      this.validAddress = true;
      this.errMessAddress = '';
    }
  }

  onBack(): void {
    if (this.current > 1) {
      this.current -= 1;
      this.router.navigate(['/document/sla-data-input'], { queryParams: { step: 1 } });
    }
  }
  onSubmit(): void {
    const mode = localStorage.getItem('mode-sla-input')
    if (this.businessForm.valid) {
      this.current = 2;
      const formValue = {
        ...this.businessForm.value,
        taxable_certificate_number: String(this.businessForm.value.taxable_certificate_number),
        taxpayer_number: String(this.businessForm.value.taxpayer_number),
        business_identification_number: String(this.businessForm.value.business_identification_number)
      };
        this.businessData.emit(formValue);
        if (mode === 'create') {
          this.router.navigate(['/document/sla-data-input'], {
            queryParams: { step: 3 },
          });
        } else {
          this.router.navigate(['/document/sla-data-input'], {
            queryParams: { step: 3, mode: mode },
          });
        }
    } else {
      console.log('Form is invalid!');
    }
  }

  get isFormInvalid(): boolean {
    return (
      this.businessForm.invalid ||
      this.errMessEmail !== '' ||
      this.errMessBName !== '' ||
      this.errMessAddress !== '' ||
      this.errMessNumber !== ''
    );
  }
}
