import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject, takeUntil } from 'rxjs';
import { DOC_STATE } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-uat-input',
  templateUrl: './uat-input.component.html',
  styleUrl: './uat-input.component.scss',
})
export class UatInputComponent {
  loading = true;
  current = 0;
  isMediumScreen = false;
  destroy$ = new Subject<void>();

  stepList = [
    'Destination',
    'Calculate Shipping',
    'Order',
    'Pickup',
    'Print Label',
    'History AWB',
  ];

   isConfirmBack: boolean = false
  @Output() openModalConfirmBack = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private baseService: BaseService,
    private message: NzMessageService
  ) {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize.bind(this));
  }

  ngOnInit(): void {
    setTimeout(() => (this.loading = false), 1000);
    this.route.queryParams.subscribe((params) => {
      this.current = +params['step'] - 1 || 0;
    });
    if (localStorage.getItem('mode-uat-input') === 'edit') {
      this.handleEdit();
    }
  }

  back(): void {
    this.isConfirmBack = true
  }

  closeConfirmBack() {
    this.isConfirmBack = false
  }

  next(): void {
    if (this.current < this.stepList.length - 1) {
      this.router.navigate(['/document/uat-data-input'], {
        queryParams: { step: ++this.current + 1 },
      });
    }
  }

  previous(): void {
    if (this.current > 0) {
      this.router.navigate(['/document/uat-data-input'], {
        queryParams: { step: --this.current + 1 },
      });
    }
  }

  handleEdit(): void {
    this.stepList.forEach((step) => localStorage.setItem(`uat-${step.toLowerCase().replace(' ', '-')}`, 'complete'));
  }

  handleStep(targetStep: number): void {
    const steps = this.stepList.map(step => localStorage.getItem(`uat-${step.toLowerCase().replace(' ', '-')}`));
    const maxCompletedStep = steps.findIndex(step => step !== 'complete') + 1 || this.stepList.length;
    if (targetStep <= maxCompletedStep) {
      this.router.navigate(['/document/uat-data-input'], {
        queryParams: { step: targetStep },
      });
    } else {
      console.warn(`Navigation to step ${targetStep} is not allowed.`);
    }
  }

  checkScreenSize(): void {
    this.isMediumScreen = window.innerWidth >= 768 && window.innerWidth <= 1024;
  }

  updateState(): void {
    this.baseService.putData(DOC_STATE, { document_type: 'UAT', state: 4 })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => this.message.create('success', 'Succeeded in submitting UAT'),
        (err) => console.error(err)
      );
  }
}
