<div class="col-12 d-flex flex-column justify-content-between">
    <div class="col d-flex">
        <div class="card w-100">
            <div class="d-flex align-items-center mt-2 p-4">
                <Button
                    style="border: none; background-color: transparent"
                    (click)="back()"
                    >
                    <img
                        src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
                        alt=""
                        width="40px"
                    />
                </Button>
                <h5>
                    Balance Withdrawal Details
                </h5>
            </div>
            <div class="d-flex ms-4 mt-2">
                <div class="left">
                    <p>Request Date</p>
                    <p>Bank Owner Name</p>
                    <p>Bank Name</p>
                    <p>Account number</p>
                    <p>Withdrawal Status</p>
                </div>
                <div class="ms-4">
                    <p>: {{detailBalance.date ? (detailBalance.date | date : "dd MMMM yyyy") : '-' }} ({{detailBalance.date ? (detailBalance.date | date : "HH:mm") : ''}} WIB)</p>
                    <p>: {{detailBalance.bank_owner}}</p>
                    <p>: {{detailBalance.bank_code}}</p>
                    <p class="align-items-center">: {{detailBalance.bank_number}}
                        <button class="btn ms-2 p-0" (click)="salinCode()">
                            <img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/copy.svg" alt="">
                        </button>
                    </p>
                    <span class="d-flex" style="margin-top: -5px;">
                        : &nbsp;
                        <p *ngIf="detailBalance.status === 'review'" class="pending">On Review</p>
                        <p *ngIf="detailBalance.status === 'request'" class="request">Requested</p>
                        <p *ngIf="detailBalance.status === 'rejected'" class="failed">Rejected</p>
                        <p *ngIf="detailBalance.status === 'approved'" class="success">Approved</p>
                    </span>
                </div>
            </div>
            <div class="row col-12 d-flex flex-column flex-md-row justify-content-start gap-4 p-4">
                <div class="col-12 col-md-auto col-lg-3 card card-saldo">
                    <div class="title d-flex align-items-center">
                        <img class="me-2" src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/dollar-square.svg" alt="" width="25px">
                        Current Balance
                    </div>
                    <div class="card-body">{{(detailBalance.beginning_balance || 0) | idr}}</div>
                </div>
                <div class="col-12 col-md-auto col-lg-3 card card-saldo">
                    <div class="title d-flex align-items-center">
                        <img class="me-2" src="https://storage.googleapis.com/komerce/assets/icons/card-receive-green.svg" alt="" width="25px">
                        Withdrawal Request
                    </div>
                    <div class="card-body">{{(detailBalance.nominal || 0) | idr}}</div>
                </div>
                <div class="col-12 col-md-auto col-lg-3 card card-saldo">
                    <div class="title d-flex align-items-center">
                        <img class="me-2" src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/empty-wallet-tick.svg" alt="" width="25px">
                        Balance If Approved
                    </div>
                    <div class="card-body">{{(detailBalance.ending_balance || 0) | idr}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
