import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { LocaleConfig, LocaleService } from 'ngx-daterangepicker-material';
import { Dayjs } from 'dayjs';
import { EMPTY, Subject, Subscription, catchError, interval, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { BaseService } from 'src/app/services/base.service';
import { DOWNLOAD_ORDER } from 'src/app/constants/endpoint.const';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { LocaleConfigDate } from 'src/app/constants/date-config.const';

@Component({
  selector: 'modal-dl-order',
  templateUrl: './modal-download-data-order.component.html',
  styleUrls: ['./modal-download-data-order.component.scss'],
})
export class ModalDlDataOrder implements OnInit {
  destroy$ = new Subject();
  selected: any = {
    startDate: moment().startOf('month').locale('id'),
    endDate: moment().endOf('month').locale('id'),
  };

  ranges: any = {
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'Last 60 Days': [moment().subtract(59, 'days'), moment()],
    Semua: [moment().startOf('year').subtract(1, 'year'), moment()],
  };

  invalidDates: moment.Moment[] = [
    moment().add(2, 'days'),
    moment().add(3, 'days'),
    moment().add(5, 'days'),
  ];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  @Output() filterResult = new EventEmitter();
  setlocale: LocaleConfig = LocaleConfigDate
  keepCalendarOpeningWithRange: boolean;
  status: any = [
    {
      value: 'Diajukan',
      label: 'Order Created',
      id: 'dibuat',
      checked: true,
    },
    {
      value: 'Dipacking',
      label: 'Packed',
      id: 'packing',
      checked: true,
    },
    {
      value: 'Dikirim',
      label: 'Sent',
      id: 'kirim',
      checked: true,
    },
    {
      value: 'Diterima',
      label: 'Received',
      id: 'Ongkir',
      checked: true,
    },
    {
      value: 'Retur',
      label: 'Return',
      id: 'retur',
      checked: true,
    },
    {
      value: 'Hilang',
      label: 'Missing',
      id: 'hilang',
      checked: true,
    },
    {
      value: 'Rusak',
      label: 'Damaged',
      id: 'rusak',
      checked: true,
    },
  ];
  selectedStatuses: string[] = ['Diajukan', 'Dipacking', 'Dikirim', 'Diterima', 'Retur', 'Hilang', 'Rusak'];
  ekspedisi: any = [
    {
      value: 'JNE',
      label: 'JNE',
      id: 'JNE',
      checked: true,
    },
    {
      value: 'IDEXPRESS',
      label: 'IDExpress',
      id: 'packing',
      checked: true,
    },
    {
      value: 'SICEPAT',
      label: 'SiCepat',
      id: 'sicepat',
      checked: true,
    },
    {
      value: 'SAP',
      label: 'SAP',
      id: 'SAP',
      checked: true,
    },
  ];
  payment: any = [
    {
      value: 'COD',
      label: 'COD',
      id: 'COD',
      checked: true,
    },
    {
      value: 'BANK TRANSFER',
      label: 'Bank Transfer',
      id: 'transfer',
      checked: true,
    },
  ]
  progress: number = 0;
  txt_download: string = 'Download Excel';
  downloaded: boolean = false;
  downloading: boolean = false;
  private intervalSubscription!: Subscription;
  selectedValue: string = 'order';
  selectedPayment: string[] = ['COD', 'BANK TRANSFER'];
  selectedEkspedisi: string[] = ['JNE', 'IDEXPRESS', 'SICEPAT', 'SAP'];
  isOrder: boolean = true;

  @Input() filterList: any = {
    start_date: moment().startOf('month').locale('id').format('YYYY-MM-DD 00:00:01'),
    end_date: moment().endOf('month').locale('id').format('YYYY-MM-DD 23:59:59'),
    shipping: 'JNE,IDEXPRESS,SICEPAT,SAP',
    payment_method: 'COD,BANK TRANSFER',
    order_status: 'Diajukan,Dipacking,Dikirim,Diterima,Retur,Hilang,Rusak',
    date_status: 'order',
  };

  constructor(
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    private http: HttpClient
  ) {
    this.keepCalendarOpeningWithRange = true;
  }
  ngOnInit(): void {}

  onChangeDate(e: any) {
    const endDate: Dayjs = e.endDate;
    const startDate: Dayjs = e.startDate;

    this.filterList.start_date = startDate ? startDate.format('YYYY-MM-DD 00:00:01') : this.selected.startDate.format('YYYY-MM-DD 00:00:01');
    this.filterList.end_date = endDate ? endDate.format('YYYY-MM-DD 23:59:59') : this.selected.endDate.format('YYYY-MM-DD 23:59:59');

    this.filterResult.emit(this.filterList);
    this.downloading = false;
    this.downloaded = false;
    this.progress = 0;
  }
  handleClose() {
    if (this.progress > 0 && this.progress < 100) {
      this.toastService.show(null, 'Download canceled', 'danger', {
        classname: 'bg-danger',
        delay: 3000,
      });
    }
    this.downloading = false;
    this.downloaded = false;
    this.progress = 0;
    this.activeModal.dismiss();
    this.intervalSubscription.unsubscribe();
  }

  onRadioChange() {
    if (this.selectedValue === 'order') {
      this.isOrder = true;
      this.filterList.date_status = this.selectedValue;
      this.filterList.order_status = 'Diajukan,Dipacking,Dikirim,Diterima,Retur,Hilang,Rusak'
      this.filterResult.emit(this.filterList);
    } else {
      this.isOrder = false;
      this.filterList.order_status = ''
      this.filterList.date_status = this.selectedValue;
      this.filterResult.emit(this.filterList);
    }
    this.downloading = false;
    this.downloaded = false;
    this.progress = 0;
  }

  getStatus(event: any, list: any) {
    const inputElement = event.target as HTMLInputElement;
    const isChecked = inputElement.checked;

    if (isChecked && !this.selectedStatuses.includes(list.value)) {
      this.selectedStatuses.push(list.value);
    } else if (!isChecked && this.selectedStatuses.includes(list.value)) {
      this.selectedStatuses = this.selectedStatuses.filter(
        (value) => value !== list.value
      );
    }

    if (this.selectedStatuses.length > 0) {
      this.filterList.order_status = this.selectedStatuses.join(',');
    } else {
      this.filterList.order_status = '';
    }

    this.filterResult.emit(this.filterList);
    this.downloading = false;
    this.downloaded = false;
    this.progress = 0;
  }

  getRadio(v: any) {
    this.filterList.order_status = v;
    this.filterResult.emit(this.filterList);
    this.downloading = false;
    this.downloaded = false;
    this.progress = 0;
  }

  paymentMethod(event: any, item: any) {
    const inputElement = event.target as HTMLInputElement;
    const isChecked = inputElement.checked;

    if (isChecked && !this.selectedPayment.includes(item.value)) {
        this.selectedPayment.push(item.value);
    } else if (!isChecked && this.selectedPayment.includes(item.value)) {
        this.selectedPayment = this.selectedPayment.filter(
            (value) => value !== item.value
        );
    }

    if (this.selectedPayment.length > 0) {
        this.filterList.payment_method = this.selectedPayment.join(',');
    } else {
        this.filterList.payment_method = '';
    }

    this.filterResult.emit(this.filterList);
    this.downloading = false;
    this.downloaded = false;
    this.progress = 0;
}

  selectEkspedisi(event: any, list: any) {
    const inputElement = event.target as HTMLInputElement;
    const isChecked = inputElement.checked;

    if (isChecked && !this.selectedEkspedisi.includes(list.value)) {
      this.selectedEkspedisi.push(list.value);
    } else if (!isChecked && this.selectedEkspedisi.includes(list.value)) {
      this.selectedEkspedisi = this.selectedEkspedisi.filter(
        (value) => value !== list.value
      );
    }

    if (this.selectedEkspedisi.length > 0) {
      this.filterList.shipping = this.selectedEkspedisi.join(',');
    } else {
      this.filterList.shipping = '';
    }

    this.filterResult.emit(this.filterList);
    this.downloading = false;
    this.downloaded = false;
    this.progress = 0;
  }
  download() {
    if (this.filterList.order_status === '' && this.filterList.payment_method === '' && this.filterList.shipping === '') {
      this.toastService.show(null, 'Select Order Status, Payment Method & Shipping First', 'danger', {
        classname: 'bg-danger',
        delay: 3000,
      });
    } else if(this.filterList.order_status === '') {
        this.toastService.show(null, 'Select Order Status First', 'danger', {
          classname: 'bg-danger',
          delay: 3000,
        });
    } else if(this.filterList.payment_method === '') {
      this.toastService.show(null, 'Select Payment Method First', 'danger', {
        classname: 'bg-danger',
        delay: 3000,
      });
    } else if(this.filterList.shipping === '') {
      this.toastService.show(null, 'Select Expedition First', 'danger', {
        classname: 'bg-danger',
        delay: 3000,
      });
    } else {
      this.progress = 0;
      this.downloading = true;
      this.downloaded = true;
      this.txt_download = 'Downloading...';

      this.toastService.show(
        null,
        'The file will be downloaded soon, please wait',
        'success',
        {
          classname: 'bg-success',
          delay: 3000,
        }
      );

      this.intervalSubscription = interval(50).subscribe(() => {
        if (this.progress < 100) {
          this.progress++;
        } else {
          const url = DOWNLOAD_ORDER as string;
          const params = new URLSearchParams(this.filterList).toString();

          const headers = new HttpHeaders({
            'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
          });
        this.http.get(url + '?' + params, { headers, responseType: 'blob' })
        .pipe(
          catchError(error => {
            console.error('Download failed:', error);
            this.toastService.show(null, 'Download failed', 'danger', {
              classname: 'bg-danger',
              delay:  3000,
            });
            this.progress = 0
            this.txt_download = 'Download Excel';
            this.downloaded = false;
            return EMPTY;
          })
        )
        .subscribe((blob: Blob) => {
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement('a');
              a.href = url;
              a.download =
                localStorage.getItem('auth-user')?.slice(1).replace(/\s+/g, '_') +
                'Data_Order_' +
                moment().format('YYYY-MM-DD') +
                '.xlsx';
              document.body.appendChild(a);
              a.click();
              a.remove();
              this.downloaded = false;
              this.txt_download = 'Download Excel';

              this.toastService.show(null, 'Successfully downloaded the file', 'success', {
                classname: 'bg-success',
                delay: 5000,
              });
            });
          this.intervalSubscription.unsubscribe();
        }
      });
    }
  }
  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }
}
