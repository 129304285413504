import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-confirm-back',
  standalone: false,
  templateUrl: './modal-confirm-back.component.html',
  styleUrl: './modal-confirm-back.component.scss'
})
export class ModalConfirmBackComponent {
  @Input() docType: string = '';
  @Input() isShowConfirm: boolean = false;
  @Output() isShowConfirmEvent = new EventEmitter<boolean>();

  constructor(
    private router: Router,
  ) {}

  handleCancel() {
    this.isShowConfirm = false
    this.isShowConfirmEvent.emit()
  }

  handleContinue() {
    if (this.docType === 'SLA') {
      localStorage.removeItem('slaData')
    }
    this.router.navigate(['/document'])
  }
}
