import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { TicketService } from 'src/app/services/api/ticket.service';

@Component({
  selector: 'app-ticketing',
  templateUrl: './ticketing.component.html',
  styleUrls: ['./ticketing.component.scss']
})
export class TicketingComponent {
  private destroy$ = new Subject<boolean>();
  countTicket: any = [];
  loading: boolean = true;
  constructor(
    private ticketService: TicketService,
  ) {
  }
  ngOnInit(): void {
    this.getCountTicket()
    setTimeout(()=>{
      this.loading = false
    }, 1000)
  }

  getCountTicket() {
    this.ticketService
    .countTicket()
    .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.countTicket = res.data
        },
        () => {}
      );
  }

  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }
}
