<div class="mt-5">
    <nz-table #basicTable [nzShowPagination]="false" [nzData]="listOfData">
        <thead>
          <tr>
            <th>Courier</th>
            <th>Price</th>
            <th>Estimated</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let data of listOfData; let i = index">
            <tr>
              <td>
                <div class="d-flex gap-3 align-items-center">
                  <img [src]="generateIcon(data.shipping_name)" alt="courier" width="80px">
                  {{ data.service_name }}
                </div>
              </td>
              <td>
                <div class="d-flex flex-column">
                  <span>
                    {{ data.shipping_cost_net | idr }}
                  </span>
                </div>
              </td>
              <td>{{ data.etd }}</td>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
</div>