import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ProfileService } from 'src/app/services/profile.service';
import { Subscription } from 'rxjs';
import { navigation, subMenu } from 'src/app/constants/navigation.const';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Output() isActive = new EventEmitter();
  @Input() active: boolean = false;

  public isOpen = true;
  isRateOpen = true;
  activeButton: string = 'dashboard';
  isRajaOngkir: boolean = false;
  profileData!: any;
  private profileSubscription!: Subscription;
  isShowDoc: boolean = true;
  isShowBillings: boolean = true;
  isShowSimulator: boolean = true;

  icons = {
    settings: {
      active:
        'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/setting-2.svg',
      default:
        'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/setting-2.svg',
    },
    arrow: {
      up: 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-up-2.svg',
      down: 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg',
    },
  };

  menuItems = navigation

  subMenuItems = subMenu

  constructor(
    private elementRef: ElementRef,
    private route: Router,
    private profileService: ProfileService,
    private cdr: ChangeDetectorRef,
  ) {
    route.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const url = val.url || '';
        this.setActiveButtonFromUrl(url);
      }
    });
  }

  inside = false;

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.isActive.emit(false);
    }
  }

  ngOnInit() {
    const mode = localStorage.getItem('mode');
    this.profileSubscription = this.profileService.profileData$.subscribe((data) => {
      if (data) {
        this.profileData = data;

        const { rajaongkir_level, is_openapi, is_lifetime } = this.profileData;
        const isAdvancedUser = is_openapi || is_lifetime;

        this.isShowDoc = rajaongkir_level !== undefined || isAdvancedUser;
        this.isShowBillings = rajaongkir_level !== 0 && rajaongkir_level !== undefined;
        this.isShowSimulator = (rajaongkir_level === 3 || isAdvancedUser) && mode !== 'production';
      }
    });
  }

  setActiveButtonFromUrl(url: string) {
    this.menuItems.forEach((item) => {
      if (item.link && url.startsWith(item.link)) {
        this.activeButton = item.name;
        this.isOpen =
          item.name !== 'Profile' &&
          item.name !== 'PIN' &&
          item.name !== 'Bank Account' &&
          item.name !== 'Document';
      }
      
      item.children?.forEach((sub) => {
        if (url.startsWith(sub.link as string)) {
          this.isRateOpen = false
          this.activeButton = sub.name;
        }
      })

    });


    this.subMenuItems.forEach((subItem) => {
      if (url.startsWith(subItem.link)) {
        this.activeButton = subItem.name;
        this.isOpen = false;
      }
    });
  }

  toggleSettings() {
    this.isOpen = !this.isOpen;
  }

  toggleMenu() {
    this.isRateOpen = !this.isRateOpen
  }

  setNavActive(data: any): void {
    this.isActive.emit(false);
  }

  ngOnDestroy(): void {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
  }
}
