<div class="d-flex flex-column p-4" [formGroup]="businessForm">
  <h6 class="text-bold">Service Usage</h6>
  <hr />
  <div class="d-flex flex-column gap-3 mt-3">
    <div class="d-flex gap-2">
      <span
        >What types of services are provided by your company’s system when
        utilizing the RajaOngkir X Komship OpenAPI?</span
      >
      <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
    </div>
    <div class="d-flex gap-2">
      <nz-radio-group formControlName="type_of_service">
        <label nz-radio [nzValue]="1">
          <div class="d-flex flex-column">
            <span>Corporate Single Seller</span>
            <small class="text-grey font-italic"
              >(Your platform has many users or sellers who use the delivery
              service)</small
            >
          </div>
        </label>
        <label nz-radio [nzValue]="2">
          <div class="d-flex flex-column">
            <span>Corporate Multiple Seller</span>
            <small class="text-grey font-italic"
              >(Your platform only has one user or seller who uses the delivery
              service)</small
            >
          </div>
        </label>
      </nz-radio-group>
    </div>
  </div>
  <div class="d-flex flex-column mt-5">
    <div class="d-flex gap-2">
      <span>Your Business Application URL</span>
      <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
    </div>
    <div class="col-4 mt-1">
      <input
        formControlName="business_application_url"
        nz-input
        placeholder="e.g https://yourwebsite.com"
        type="text"
        minlength="7"
        (keydown.enter)="$event.preventDefault()"
          (input)="setValueRulePlatform()"
          nzSize="large"
          [nzStatus]="isValidPlatform ? '' : 'error'"
      />
      <span class="text-danger">
        <img
          *ngIf="errPN !== ''"
          src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
          alt="warning"
          style="max-width: 10px; max-height: 10px"
        />
        {{ errPN }}</span
      >
    </div>
  </div>
  <div class="d-flex flex-column gap-3 mt-5">
    <div class="d-flex gap-2">
      <span
        >Is your company willing to allow us to retrieve the information from
        the account settings?</span
      >
      <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
    </div>
    <div class="d-flex gap-2">
      <nz-radio-group formControlName="account_settings">
        <label nz-radio [nzValue]="true">Yes</label>
        <label nz-radio [nzValue]="false">No</label>
      </nz-radio-group>
    </div>
  </div>

  <div class="d-flex align-self-end gap-2 mt-5">
    <button class="btn btn-outline-secondary" (click)="onBack()">Back</button>
    <button
      type="submit"
      class="btn text-white"
      [disabled]="businessForm.invalid"
      [ngClass]="{
        'btn-secondary': businessForm.invalid || !isValidPlatform,
        'btn-primary': !businessForm.invalid || isValidPlatform
      }"
      (click)="onSubmit()"
    >
      Submit
    </button>
  </div>
</div>
