import { Component } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-integration',
  standalone: false,
  templateUrl: './integration.component.html',
  styleUrl: './integration.component.scss',
})
export class IntegrationComponent {
  skeleton: boolean = true;

  constructor(
    private BaseService: BaseService,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.skeleton = false;
    }, 1000);
  }
}
