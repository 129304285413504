import { NgModule, LOCALE_ID } from '@angular/core';
import { RatesTrackComponent } from './rates-track.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  NgLabelTemplateDirective,
  NgOptionTemplateDirective,
  NgSelectComponent,
  NgSelectModule,
} from '@ng-select/ng-select';
import { AntDModule } from 'src/app/modules/shared/antd.module';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { RatesTrackRoutingModule } from './rates-track-routing.module';
import { RatesComponent } from './pages/rates/rates.component';
import { TrackComponent } from './pages/track/track.component';
import { RegularRatesComponent } from './components/regular-rates/regular-rates.component';
import { InstantRatesComponent } from './components/instant-rates/instant-rates.component';
import { TableInstantRatesComponent } from './components/table-instant-rates/table-instant-rates.component';
import { TableRegularRatesComponent } from './components/table-regular-rates/table-regular-rates.component';
import { LibsModule } from '../../libs/libs.module';
import { ModalMapPickerComponent } from './components/modal-map-picker/modal-map-picker.component';

@NgModule({
  declarations: [
    RatesTrackComponent,
    RatesComponent,
    TrackComponent,
    RegularRatesComponent,
    InstantRatesComponent,
    TableInstantRatesComponent,
    TableRegularRatesComponent,
    ModalMapPickerComponent,
  ],
  imports: [
    RatesTrackRoutingModule,
    CommonModule,
    NgbModalModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AntDModule,
    NgbModule,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
    NgSelectComponent,
    NgSelectModule,
    LibsModule,
  ],
})
export class RatesTrackModule {}
