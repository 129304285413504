import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  CHECK_WA,
  RECAPTCHA_TOKEN,
} from 'src/app/constants/endpoint.const';
import { takeUntil, Subject, debounceTime} from 'rxjs';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ModalRegisterSuccessComponent } from './modal-register-success/modal-register-success.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from 'src/app/services/api/user.service';
import { Title } from '@angular/platform-browser';
import { ModalExistingEmailComponent } from './modal-alert-existing/modal-existing-email.component';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { BypassInterceptorContext } from 'src/app/interceptors/http.interceptor';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
  formGroup: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(50),
    ]),
    fullname: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50),
    ]),
    address: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(100),
    ]),
    telephone: new FormControl('', [
      Validators.required,
      Validators.minLength(9),
      Validators.maxLength(12),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(32),
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(32),
    ]),
    checked: new FormControl(false, [Validators.required]),
  });

  isLoading: boolean = false;
  isShowPassword: boolean = false;
  isShowPassword2: boolean = false;
  destroy$ = new Subject<void>();
  errorUser = '';
  checked: boolean = false;

  eyeOn =
    'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye.svg';
  eyeOff =
    'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye-slash.svg';

  validationPassword = [
    {
      key: 'min8',
      status: false,
      text: 'Minimum 8 characters',
      error: '8 characters',
    },
    {
      key: 'minCapital',
      status: false,
      text: 'Minimum 1 capital letter A-Z',
      error: '1 capital letter',
    },
    {
      key: 'minText',
      status: false,
      text: 'Minimum 1 lower case letter a-z',
      error: '1 lower case',
    },
    {
      key: 'minNum',
      status: false,
      text: 'Minimum 1 digit 0-9',
      error: '1 digit number',
    },
    {
      key: 'minChar',
      status: false,
      text: "Minimum 1 symbol: ` ~ ! ( ) - + _ = { [ ] } : ; ' . < > /",
      error: '1 symbol',
    },
    { key: 'maxChar', status: false, error: 'Maximum 32 characters' },
  ];
  visibleValPass: boolean = false;
  isValidPass: boolean = true;
  errMessPass: string = '';
  errMessPass2: string = '';
  isErrPass: boolean = false;
  captchaToken: string = '';
  addressLength: number = 0;
  validAddress: boolean = true;
  errMessAddress: string = '';
  validNumber: boolean = true;
  errMessNumber: string = '';
  errMessFullName: string = '';
  validFullName: boolean = true;
  errMessEmail: string = '';
  validEmail: boolean = true;
  confirmModal?: NzModalRef;
  debounceTimeout: any;

  constructor(
    private userService: UserService,
    private renderer: Renderer2,
    private modalService: NzModalService,
    private message: NzMessageService,
    private titleService: Title,
    private httpClient: HttpClient,
  ) {}

  ngOnInit(): void {
    this.addScriptToHead(
      `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_TOKEN}`
    );
    this.titleService.setTitle('RajaOngkir - Registration');
  }

  checkEvent() {
    this.checked = !this.checked;
  }

  validateEmail() {
    const value = this.formGroup.get('email')?.value || '';
    const regex = /^[a-zA-Z0-9+@._-]+$/g;

    if (value === '') {
      this.validEmail = false;
      this.errMessEmail = 'Email must be filled in';
    } else {
      if (value.length < 10) {
        this.errMessEmail = 'Minimum 10 characters';
        this.validEmail = false;
      } else if (value.length > 50) {
        this.errMessEmail = 'Maximum 50 characters';
        this.validEmail = false;
      } else if (!regex.test(value)) {
        this.validEmail = false;
        this.errMessEmail = 'Email must be a valid email address';
      } else {
        const atIndex = value.indexOf('@');
        const lastDotIndex = value.lastIndexOf('.');
        const dotBeforeAt = value.substring(0, atIndex).includes('.');
        const dotAfterAt = value.substring(atIndex + 1).includes('.');

        if (
          atIndex === -1 ||
          lastDotIndex === -1 ||
          lastDotIndex === value.length - 1 ||
          value[atIndex + 1] === '.' ||
          !dotAfterAt
        ) {
          this.errMessEmail = 'Email must be a valid email address';
          this.validEmail = false;
        } else {
          this.validEmail = true;
          this.errMessEmail = '';
        }
      }
    }
  }

  validateFullname() {
    const value = this.formGroup.get('fullname')?.value || '';
    const regex = /^[a-zA-Z ]+$/g;

    if (value === '') {
      this.validFullName = false;
      this.errMessFullName = 'Full Name/Company must be filled';
    } else if (value.length < 3) {
      this.validFullName = false;
      this.errMessFullName = 'Minimum 3 characters';
    } else if (value.length > 50) {
      this.validFullName = false;
      this.errMessFullName = 'Maximum 50 characters';
    } else if (!regex.test(value)) {
      this.validFullName = false;
      this.errMessFullName = 'There are characters that are not allowed';
    } else {
      this.validFullName = true;
      this.errMessFullName = '';
    }
  }

  addScriptToHead(src: string): void {
    const script = this.renderer.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    script.async = true;
    this.renderer.appendChild(document.head, script);
  }

  disableEnterKey(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  setRegex(event: any) {
    const regex = /^[a-zA-Z0-9+@._-]+$/g;
    return regex.test(event.key);
  }
  setRegexName(event: any) {
    const regex = /^[a-zA-Z ]+$/g;
    return regex.test(event.key);
  }

  setRegexPassword(event: any) {
    const regex = /^[a-zA-Z0-9`~!()\-_+={}\[\]:;'.<>\/]+$/g;
    return regex.test(event.key);
  }

  get form() {
    return {
      formGroup: this.formGroup,
      email: this.formGroup.get('email'),
      password: this.formGroup.get('password'),
    };
  }
  preventNonNumeric(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;

    if (
      !/^\d$/.test(event.key) &&
      !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(
        event.key
      )
    ) {
      event.preventDefault();
    }

    if (input.value.length === 0 && event.key !== '8') {
      event.preventDefault();
    }
  }

  validateInput(event: Event): void {
    clearTimeout(this.debounceTimeout);
    const input = event.target as HTMLInputElement;

    this.debounceTimeout = setTimeout(() => {
    if (input.value.length > 12) {
      input.value = input.value.slice(0, 12);
    }

    if (input.value.length === 0) {
      this.validNumber = false;
      this.errMessNumber = 'Phone number must be filled';
    } else if (!/^\d+$/.test(input.value)) {
      this.validNumber = false;
      this.errMessNumber = 'Must be a number';
    } else if (!input.value.startsWith('8')) {
      this.validNumber = false;
      this.errMessNumber = 'Phone number must start with number 8';
    } else if (input.value.length < 9) {
      this.validNumber = false;
      this.errMessNumber = 'Minimum 9 digits';
    } else {
      this.checkWA();
    }
  }, 500);
  }

  checkWA() {
      const phone = this.formGroup.get('telephone')?.value;
      const baseUrl = CHECK_WA;
      const endPoint = `?phone_no=${phone}`;
      const apiUrl = baseUrl + endPoint;

      const token = localStorage.getItem('auth-token');

      const headers = new HttpHeaders({
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      });

      const context = new HttpContext().set(BypassInterceptorContext, true);
      this.errMessNumber = 'Checking Whatsapp...';
      this.httpClient.post<any>(apiUrl, {}, { headers, context }).subscribe({
        next: (res) => {
          if (res.data === 'valid') {
            this.validNumber = true;
            this.errMessNumber = 'Number connected with Whatsapp';
          } else {
            this.validNumber = true;
            this.errMessNumber = 'Number not connected with Whatsapp';
          }
        },
        error: (error) => {
          if (error.error.data === 'invalid') {
            this.validNumber = true;
            this.errMessNumber = 'Number not connected with Whatsapp';
          } else if (error.error.code !== 1000 && error.error.code !== 1001) {
            this.message.create('error', 'Failed to check WhatsApp number');
          }
        },
      });
    }

  validatePassword() {
    this.visibleValPass = true;
    this.isValidPass = false;
    const min8 = /.{8,}/;
    const minCapital = /(?=.*[A-Z])/;
    const minText = /(?=.*[a-z])/;
    const minNum = /(?=.*[0-9])/;
    const minChar = /[`~!()\-_+={}\[\]:;'.<>\/]/;
    const disallowedChar = /[^a-zA-Z0-9`~!()\-_+={}\[\]:;'.<>\/]/;
    const maxChar = /^.{1,32}$/;

    const value = this.formGroup.get('password')?.value || '';
    let allRulesValid = true;

    this.validationPassword.forEach((rule) => {
      switch (rule.key) {
        case 'min8':
          rule.status = min8.test(value);
          break;
        case 'minCapital':
          rule.status = minCapital.test(value);
          break;
        case 'minText':
          rule.status = minText.test(value);
          break;
        case 'minNum':
          rule.status = minNum.test(value);
          break;
        case 'minChar':
          rule.status = minChar.test(value) && !disallowedChar.test(value);
          break;
        case 'maxChar':
          rule.status = maxChar.test(value);
          break;
      }

      if (!rule.status) {
        allRulesValid = false;
      }
    });

    this.isValidPass = allRulesValid;

    if (value.length === 0) {
      this.errMessPass = 'Password must be filled';
    } else if (!allRulesValid) {
      this.errMessPass = 'Password does not comply with the provisions';
    } else this.errMessPass = '';

    const value2 = this.formGroup.get('confirmPassword')?.value || '';

    if (value !== value2 && value2 !== '') {
      this.errMessPass2 =
        'Make sure the password confirmation is the same as the previous password.';
    } else {
      this.errMessPass2 = '';
    }

    const invalidRules = this.validationPassword.filter((rule) => !rule.status);
    const errors = invalidRules.map((rule) => rule.error);

    this.formGroup
      .get('password')
      ?.setErrors(
        errors.length > 0 ? { passwordInvalid: errors.join(', ') } : null
      );
  }

  validateAddress() {
    const value = this.formGroup.get('address')?.value || '';
    const regex = /^[a-zA-Z0-9.\-():=/, ]+$/;

    this.addressLength = value.length;

    if (value.length === 0) {
      this.validAddress = false;
      this.errMessAddress = 'Address cannot be empty';
    } else if (!regex.test(value)) {
      this.validAddress = false;
      this.errMessAddress = 'There are characters that are not allowed';
    } else if (value.length > 0 && value.length < 6) {
      this.validAddress = false;
      this.errMessAddress = 'Minimum 6 characters';
    } else {
      this.validAddress = true;
      this.errMessAddress = '';
    }
  }

  confirmPassword() {
    const value1 = this.formGroup.get('password')?.value || '';
    const value2 = this.formGroup.get('confirmPassword')?.value || '';

    if (value1 !== value2 && value2 !== '') {
      this.errMessPass2 =
        'Make sure the password confirmation is the same as the previous password.';
    } else if (value2.length === 0) {
      this.errMessPass2 = 'Password confirmation must be filled in';
    } else {
      this.errMessPass2 = '';
    }
  }

  async getCaptchaToken(event: Event) {
    event.preventDefault();

    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(
          `${RECAPTCHA_TOKEN}`,
          { action: 'REGISTER_RAJAONGKIR' }
        );
        this.captchaToken = token;
        this.handleRegister(token);
      } catch (error) {
        console.error('Error executing reCAPTCHA:', error);
        this.message.create('error', 'Error executing reCAPTCHA');
      }
    });
  }
  handleRegister(e: string) {
    if (this.formGroup.valid) {
      const params = {
        email: this.formGroup.get('email')?.value,
        name: this.formGroup.get('fullname')?.value,
        phone_number: '0' + this.formGroup.get('telephone')?.value,
        address: this.formGroup.get('address')?.value,
        password: this.formGroup.get('password')?.value,
        confirm_password: this.formGroup.get('confirmPassword')?.value,
        recaptcha_token: e,
      };

      this.isLoading = true;
      this.userService
        .register(params)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response: any) => {
            this.modalService.create({
              nzContent: ModalRegisterSuccessComponent,
              nzFooter: null,
            });
            this.isLoading = false;
          },
          (err) => {
            this.isLoading = false;
            if (err.error.data.errors === 'Email already exist') {
              this.modalService.create({
                nzContent: ModalExistingEmailComponent,
                nzFooter: null,
              });
              this.validEmail = false;
            } else {
              this.message.create(
                'error',
                err.error.data.errors || 'Registration Failed, Please Try Again'
              );
            }
          }
        );
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
