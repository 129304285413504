import { Component, ElementRef, Input, LOCALE_ID, SimpleChanges, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { Subject, takeUntil } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeId from '@angular/common/locales/id';
import { OrderService } from 'src/app/services/api/order.service';
import {
  DetailDataOrderModel,
  orderDetails,
} from 'src/app/models/detail-data-order.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { awbHistoryModel } from 'src/app/models/awb-history.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-detail-data-order',
  templateUrl: './detail-data-order.component.html',
  styleUrls: ['./detail-data-order.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'id' }],
})
export class DetailDataOrderComponent {
  @Input() detailOrder!: DetailDataOrderModel;
  orderDetails: orderDetails[] = [];
  awbHistory: awbHistoryModel[] = [];
  destroy$ = new Subject();
  orderID!: number;
  public isCollapsed = true;
  totalPrice!: number;
  shipping!: string;
  awb!: string;
  isAwbAvail!: boolean;
  loading: boolean = true;

  @Input() paramAwbHistory: any = {
    shipping: '',
    airway_bill: '',
  };

  map: mapboxgl.Map | undefined;
  style = 'mapbox://styles/mapbox/streets-v11';
  lat: number = -6.2088;
  lng: number = 106.8456;
  location: string = '';

  constructor(
    private orderService: OrderService,
    private route: Router,
    private activeRoute: ActivatedRoute,
    private modalService: NgbModal,
    private clipboard: Clipboard,
    private message: NzMessageService
  ) { }

  @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef;

  ngAfterViewInit() {
    this.initializeMap();
  }

  ngOnInit(): void {
    registerLocaleData(localeId);
    this.getDetailOrder();

    this.activeRoute.params.subscribe((params) => {
      this.orderID = Number(params['id']);
    });
    setTimeout(() => {
      this.loading = false;
      this.initializeMap()
    }, 1000);
  }

  back() {
    this.route.navigate(['order-data']);
  }

  copyValue(e: string) {
    this.clipboard.copy(e);
    this.message.create('success', 'Successfully copy Tracking Number');
  }

  openLacakResi(lacakresi: any) {
    this.modalService.open(lacakresi, {
      scrollable: true,
      centered: true,
      size: 'md',
    });
    this.getAwbHistory();
  }

  getDetailOrder() {
    this.activeRoute.params.subscribe((params) => {
      this.orderID = Number(params['id']);
    });

    this.orderService
      .detailDataOrder(this.orderID)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.detailOrder = res.data;
          this.orderDetails = res.data.order_details;

          this.paramAwbHistory.shipping = res.data.shipping;
          this.paramAwbHistory.airway_bill = res.data.awb;

          const dateParts = this.detailOrder.order_date.split('-');
          const formattedDate = new Date(
            `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
          );
          this.detailOrder.formatted_date = formattedDate;

          let sum: number = res.data.order_details
            .map((a: any) => a.subtotal)
            .reduce(function (a: any, b: any) {
              return a + b;
            });
          this.totalPrice = sum;
          this.getAwbHistory();
        },
        () => {}
      );
  }

  splitAndConvertCoordinates(coordinateString: string): number[] {
    return coordinateString.split(',').map(coord => parseFloat(coord.trim()));
  }
  

  initializeMap() {

    if (this.map) {
      this.map.remove();
      this.map = undefined;
    }

    const coordinates = this.splitAndConvertCoordinates(this.detailOrder.destination_pin_point);
  
    this.map = new mapboxgl.Map({
      accessToken: environment.mapbox_token,
      container: this.mapContainer.nativeElement,
      style: this.style,
      zoom: 13,
      center: [ coordinates[1], coordinates[0] ],
    });

    setTimeout(() => {
      this.map?.resize();
    }, 500);
  
    this.createMarker(this.lng, this.lat);
  }
  
  trackPack(e: string) {
    window.open(e, '_blank');
  }

  createMarker(lng: number, lat: number) {
    const marker = new mapboxgl.Marker({ draggable: false })
      .setLngLat([lng, lat])
      .addTo(this.map!);
    
    marker.setLngLat([this.lng, this.lat]);
  }

  getAwbHistory() {
    this.orderService
      .awbHistory(this.paramAwbHistory)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.awbHistory = res.data.history;
      });
  }

  submitRequest() {
    const params = {
      orders: [
        {
          order_no: this.detailOrder.order_no,
        },
      ],
    }
    this.orderService.requestPickup(params).subscribe(
      (res) => {
        this.message.success('Request submitted successfully!');

        this.getDetailOrder()
      },
      (err) => {
        this.message.error('Request failed to submit, please try again later');}
    );
  }

  cancelRequest() {
    this.orderService.cancelPickup({ order_no: this.detailOrder.order_no }).subscribe(
      (res) => {
        this.message.success('Request canceled successfully!');
        this.getDetailOrder()
      }, (err) => {
        this.message.error('Request failed to cancel, please try again later');}
    );
  }

  iconAwb(value: string) {
    let result = '';
    if (value === 'Pickup') {
      result =
        'https://storage.googleapis.com/komerce/assets/icons/resi-pickup.svg';
    } else if (value === 'Process') {
      result =
        'https://storage.googleapis.com/komerce/assets/icons/resi-kirim.svg';
    } else if (value === 'Problem') {
      result =
        'https://storage.googleapis.com/komerce/assets/icons/resi-problem.svg';
    } else if (value === 'Delivered') {
      result =
        'https://storage.googleapis.com/komerce/assets/icons/resi-terima.svg';
    } else if (value === 'Return') {
      result =
        'https://storage.googleapis.com/komerce/assets/icons/resi-retur.svg';
    } else if (value === 'Delivery') {
      result =
        'https://storage.googleapis.com/komerce/assets/icons/resi-delivery.svg';
    }
    return result;
  }

  handleType(e: string) {
    if (e === 'SAPFlat') {
      return 'Flat';
    } else if (e === 'JNEFlat') {
      return 'Flat';
    } else if (e === 'Instant') {
      return 'Instant';
    } else {
      return '';
    }
  }

  handleClassStatus(e: string) {
    if (e === 'Diterima') {
      return 'status-success';
    } else if (e === 'Dipacking') {
      return 'status-info';
    } else if (e === 'Retur') {
      return 'status-danger';
    } else if (e === 'Dikirim') {
      return 'status-warning';
    } else if (e === 'Diajukan') {
      return 'status-danger';
    } else if (e === 'Batal') {
      return 'status-danger';
    } else {
      return '';
    }
  }

  handleStatusOrder(e: string) {
    if (e === 'Dipacking') {
      return 'Packed'
    } else if (e === 'Dikirim') {
      return 'Sent'
    } else if (e === 'Diterima') {
      return 'Received'
    } else if (e === 'Retur') {
      return 'Return'
    } else if (e === 'Diajukan') {
      return 'Submitted'
    } else if (e === 'Batal') {
      return 'Canceled'
    } else return ''
  }
}
