<div class="card p-3 d-flex flex-column gap-3 mt-2">
  <nz-skeleton [nzLoading]="loading" [nzActive]="true">
    <div class="title">Go Live Document</div>
  </nz-skeleton>
  <div
    class="d-flex flex-wrap flex-lg-nowrap flex-column flex-lg-row gap-3 col-12 mt-3"
  >
    <nz-card
      class="col"
      [nzLoading]="loading"
      nzHoverable
    >
      <div class="p-4 rounded bg-white">
        <div class="d-flex justify-content-between align-items-center">
          <h6 class="text-bold">Service Level Agreement</h6>
          <div class="d-flex gap-1 align-items-center">
            <div [ngClass]="handleDotStatus(docState[0].current_state)"></div>
            <span class="text-grey">
              {{ handleStatus(docState[0].current_state) || ''}}
            </span>
        </div>
        </div>
        <div class="bg-light p-3 rounded border mt-2" >
          <nz-timeline>
              <nz-timeline-item [nzColor]="docState[0].state === 'Init' ? 'red' : 'green'">
                <span [ngClass]="docState[0].state === 'Init' ? 'text-bold' : ''">
                  Proceed your SLA data
                </span>
              </nz-timeline-item>
              <nz-timeline-item [nzColor]="docState[0].current_state >= 3 ? 'green' : (docState[0].current_state === 2 ? 'red' : 'gray')">
                  <span [ngClass]="{
                    'text-grey': docState[0].current_state < 2,
                    'text-bold': docState[0].current_state === 2
                  }">
                      Download your SLA request
                  </span>
              </nz-timeline-item>
              <nz-timeline-item [nzColor]="docState[0].current_state >= 4 ? 'green' : (docState[0].current_state === 3 ? 'red' : 'gray')">
                  <span
                  [ngClass]="{
                    'text-grey': docState[0].current_state < 4 && docState[0].current_state !== 3,
                    'text-bold': docState[0].current_state === 3
                  }"
                  >
                      Sending SLA request
                  </span>
              </nz-timeline-item>
              <nz-timeline-item [nzColor]="docState[0].current_state === 5 ? 'green' : (docState[0].current_state === 4 || docState[0].current_state === 6 ? 'red' : 'gray')">
                  <span *ngIf="docState[0].current_state !== 6" [ngClass]="docState[0].current_state < 5 ? 'text-grey' : ''">
                      SLA approved
                  </span>
                  <div class="d-flex flex-column" *ngIf="docState[0].current_state === 6">
                        <span class="text-bold">SLA decline</span>
                        <span class="mt-2 text-bold">Note:</span>
                        <span>{{ docState[0].notes }}</span>
                  </div>
              </nz-timeline-item>
          </nz-timeline>
        </div>
        <button *ngIf="docState[0].current_state === 1" class="btn btn-primary text-white col-12 mt-2" (click)="inputSLAData('create')">Input SLA Data</button>
        <div class="d-flex gap-3 mt-3" *ngIf="docState[0].current_state >= 2">
          <button class="btn text-nowrap" (click)="inputSLAData('edit')">Edit SLA</button>
          <div class="d-flex gap-2">
            <button class="btn btn-outline-primary text-nowrap" (click)="downloadPdf('SLA')">Download SLA</button>
            <button class="btn btn-primary text-white text-nowrap" (click)="sendSLA()">Send SLA</button>
          </div>
        </div>
      </div>
    </nz-card>
    <nz-card
      class="col"
      [nzLoading]="loading"
      nzHoverable
    >
      <div class="p-4 rounded bg-white">
        <div class="d-flex justify-content-between align-items-center">
          <h6 class="text-bold">Collaboration Agreement</h6>
          <div class="d-flex gap-1 align-items-center">
            <div [ngClass]="handleDotStatus(docState[1].current_state)"></div>
            <span class="text-grey">
              {{ handleStatus(docState[1].current_state) || ''}}</span>
        </div>
        </div>
        <div class="bg-light p-3 rounded border mt-2">
          <nz-timeline>
              <nz-timeline-item [nzColor]="docState[1].state === 'Init' ? 'red' : 'green'">
                <span [ngClass]="docState[1].state === 'Init' ? 'text-bold' : ''">
                  Proceed your CA data
                </span>
              </nz-timeline-item>
              <nz-timeline-item [nzColor]="docState[1].current_state >= 3 ? 'green' : (docState[1].current_state === 2 ? 'red' : 'gray')">
                  <span [ngClass]="{
                    'text-grey': docState[1].current_state < 2,
                    'text-bold': docState[1].current_state === 2
                  }">
                      Download your CA request
                  </span>
              </nz-timeline-item>
              <nz-timeline-item [nzColor]="docState[1].current_state >= 4 ? 'green' : (docState[1].current_state === 3 ? 'red' : 'gray')">
                <span
                  [ngClass]="{
                    'text-grey': docState[1].current_state < 4 && docState[1].current_state !== 3,
                    'text-bold': docState[1].current_state === 3
                  }"
                  >
                    Sending CA request
                </span>
            </nz-timeline-item>
            <nz-timeline-item [nzColor]="docState[1].current_state === 5 ? 'green' : (docState[1].current_state === 4 || docState[1].current_state === 6 ? 'red' : 'gray')">
                <span *ngIf="docState[1].current_state !== 6" [ngClass]="docState[1].current_state < 5 ? 'text-grey' : ''">
                    CA approved
                </span>
                <div class="d-flex flex-column" *ngIf="docState[1].current_state === 6">
                      <span class="text-bold">CA decline</span>
                      <span class="mt-2 text-bold">Note:</span>
                      <span>{{ docState[1].notes }}</span>
                </div>
            </nz-timeline-item>
          </nz-timeline>
        </div>
        <div class="d-flex gap-3 mt-2">
          <button *ngIf="docState[1].current_state <= 2" class="btn btn-primary text-white col-12 mt-2" (click)="inputCA('create')">Input CA Data</button>
          <div class="d-flex gap-3 mt-2" *ngIf="docState[1].current_state > 2">
            <button class="btn text-nowrap" (click)="inputCA('edit')">Edit CA</button>
            <div class="d-flex gap-2">
              <button class="btn btn-outline-primary text-nowrap" (click)="downloadPdf('CA')">Download CA</button>
              <button class="btn btn-primary text-white text-nowrap" (click)="sendCA()">Send CA</button>
            </div>
          </div>
        </div>
      </div>
    </nz-card>
    <nz-card
      class="col"
      [nzLoading]="loading"
      nzHoverable
    >
      <div class="p-4 rounded bg-white">
        <div class="d-flex justify-content-between">
          <h6 class="text-bold">User Acceptance Test</h6>
          <div class="d-flex gap-1 align-items-center">
            <div [ngClass]="handleDotStatus(docState[2].current_state)"></div>
            <span class="text-grey">
              {{ handleStatus(docState[2].current_state) || ''}}</span>
        </div>
        </div>
        <div class="bg-light p-3 rounded border mt-2" style="min-height: 230px;">
          <nz-timeline>
              <nz-timeline-item [nzColor]="docState[2].state === 'Init' ? 'red' : 'green'">
                <span [ngClass]="docState[2].state === 'Init' ? 'text-bold' : ''">
                  Proceed your UAT data
                </span>
              </nz-timeline-item>
              <nz-timeline-item [nzColor]="docState[2].current_state >= 3 ? 'green' : (docState[2].current_state === 2 ? 'red' : 'gray')">
                <span
                  [ngClass]="{
                    'text-grey': docState[2].current_state < 4 && docState[2].current_state !== 2,
                    'text-bold': docState[2].current_state === 2
                  }"
                  >
                      Sending UAT request
                  </span>
              </nz-timeline-item>
              <nz-timeline-item [nzColor]="docState[2].current_state === 5 ? 'green' : (docState[2].current_state === 4 || docState[2].current_state === 6 ? 'red' : 'gray')">
                  <span *ngIf="docState[2].current_state !== 6" [ngClass]="docState[2].current_state < 5 ? 'text-grey' : ''">
                      UAT approved
                  </span>
                  <div class="d-flex flex-column" *ngIf="docState[2].current_state === 6">
                    <span class="text-bold">UAT decline</span>
                    <span class="mt-2 text-bold">Note:</span>
                    <span>{{ docState[2].notes }}</span>
                  </div>
              </nz-timeline-item>
          </nz-timeline>
        </div>
        <button *ngIf="docState[2].current_state === 1" class="btn btn-primary text-white text-nowrap w-100 mt-3 " (click)="inputUATData('create')">Input UAT</button>
        <button *ngIf="docState[2].current_state !== 1" class="btn btn-outline-primary text-nowrap w-100 mt-3 " (click)="inputUATData('edit')">Edit UAT</button>
      </div>
    </nz-card>
  </div>
</div>
