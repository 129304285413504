import { Injectable } from '@angular/core';
import { validatePassword } from '../constants/auth-config.const';

@Injectable({
  providedIn: 'root',
})
export class FormValidationService {
  constructor() {}

  validateEmail(email: string): { valid: boolean; message: string } {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const doubleSymbolRegex = /(\.\.|@@|--|\+\+|__|\.@|@\.|-@|@-|_\.|@\+|\+@)/;

    if (!email.trim()) {
      return { valid: false, message: 'Email must be filled in' };
    } else if (email.length < 10) {
      return { valid: false, message: 'Minimum 10 characters' };
    } else if (email.length > 50) {
      return { valid: false, message: 'Maximum 50 characters' };
    } else if (!regex.test(email)) {
      return { valid: false, message: 'Email must be a valid email address' };
    } else if (doubleSymbolRegex.test(email)) {
      return { valid: false, message: 'Email contains invalid consecutive symbols' };
    } else {
      return { valid: true, message: '' };
    }
    }
    

  validatePhoneNumber(phoneNumber: string): { valid: boolean; message: string } {
    const regex = /^\d+$/;
  
    if (!phoneNumber.trim()) {
      return { valid: false, message: 'Phone number must be filled in' };
    } else if (!regex.test(phoneNumber)) {
      return { valid: false, message: 'Phone number must be a number' };
    } else if (!phoneNumber.startsWith('8')) {
      return { valid: false, message: 'Phone number must start with number 8' };
    } else if (phoneNumber.length < 9) {
      return { valid: false, message: 'Minimum 9 digits' };
    } else if (phoneNumber.length > 12) {
      return { valid: false, message: 'Maximum 12 digits' };
    } else {
      return { valid: true, message: '' };
    }
    }
    
    validatePassword(value: string, validationRules: any[]) {
        const min8 = /.{8,}/;
        const minCapital = /(?=.*[A-Z])/;
        const minText = /(?=.*[a-z])/;
        const minNum = /(?=.*[0-9])/;
        const minChar = /[`~!()\-_+={}\[\]:;'.<>\/]/;
        const disallowedChar = /[^a-zA-Z0-9`~!()\-_+={}\[\]:;'.<>\/]/;
        const maxChar = /^.{1,32}$/;
      
        let allRulesValid = true;
      
        validationRules.forEach((rule) => {
          switch (rule.key) {
            case 'min8':
              rule.status = min8.test(value);
              break;
            case 'minCapital':
              rule.status = minCapital.test(value);
              break;
            case 'minText':
              rule.status = minText.test(value);
              break;
            case 'minNum':
              rule.status = minNum.test(value);
              break;
            case 'minChar':
              rule.status = minChar.test(value) && !disallowedChar.test(value);
              break;
            case 'maxChar':
              rule.status = maxChar.test(value);
              break;
          }
      
          if (!rule.status) {
            allRulesValid = false;
          }
        });
      
        return {
          isValid: allRulesValid,
          errors: validationRules.filter((rule) => !rule.status).map((rule) => rule.error),
        };
      }
}