export const validatePassword = [
    {
        key: 'min8',
        status: false,
        text: 'Minimal 8 karakter',
        error: '8 karakter',
      },
      {
        key: 'minCapital',
        status: false,
        text: 'Minimal 1 huruf besar A-Z',
        error: '1 huruf besar',
      },
      {
        key: 'minText',
        status: false,
        text: 'Minimal 1 huruf kecil a-z',
        error: '1 huruf kecil',
      },
      {
        key: 'minNum',
        status: false,
        text: 'Minimal 1 angka 0-9',
        error: '1 angka',
      },
      {
        key: 'minChar',
        status: false,
        text: "Minimal 1 symbol : ` ~ !  ( ) - + _ = { [ ] } : ; ' . < > /",
        error: '1 symbol',
      },
      { key: 'maxChar', status: false, error: 'Maximal 32 karakter' },
    ]