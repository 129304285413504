import {
  Component,
  OnInit,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'src/app/services/base.service';
import { VALIDATE_PHONE } from 'src/app/constants/endpoint.const';
import { ModalChangeNumberComponent } from '../modal-change-number/modal-change-number.component';
import { WhatsappService } from 'src/app/services/whatsapp.service';

@Component({
  selector: 'app-modal-input-number',
  templateUrl: './modal-input-number.component.html',
  styleUrls: ['./modal-input-number.component.scss'],
})
export class ModalInputNumberComponent implements OnInit {
  destroy$ = new Subject();
  formGroupInputNumber: FormGroup = new FormGroup({
    telephone: new FormControl('', [
      Validators.required,
      Validators.minLength(5),
    ]),
  });
  isValid: boolean = true;
  validNumber: boolean = true;
  errMessNumber: string = '';
  errMessNumberWA: string = '';
  isDisabled: boolean = true;
  isWA: boolean = true;
  isChecked: boolean = false;
  private inputSubject: Subject<string> = new Subject();

  constructor(
    private activeModal: NgbActiveModal,
    private message: NzMessageService,
    private baseService: BaseService,
    private modalService: NgbModal,
    private whatsappService: WhatsappService,
  ) {}
  ngOnInit(): void {
    this.inputSubject
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(() => {
        this.checkWA();
      });
  }

  close() {
    this.activeModal.close();
  }

  preventNonNumeric(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;

    if (
      !/^\d$/.test(event.key) &&
      !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(
        event.key
      )
    ) {
      event.preventDefault();
    }

    if (input.value.length === 0 && event.key !== '8') {
      event.preventDefault();
    }
  }

  validateInput(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.value.length > 12) {
      input.value = input.value.slice(0, 12);
    }

    if (input.value.length === 0) {
      this.validNumber = false;
      this.errMessNumber = 'Phone number must be filled';
      this.isDisabled = true;
      this.errMessNumberWA = '';
    } else if (!/^\d+$/.test(input.value)) {
      this.validNumber = false;
      this.errMessNumber = 'Must be a number';
      this.isDisabled = true;
      this.errMessNumberWA = '';
    } else if (!input.value.startsWith('8')) {
      this.validNumber = false;
      this.errMessNumber = 'Phone number must start with "8"';
      this.isDisabled = true;
      this.errMessNumberWA = '';
    } else if (input.value.length < 9) {
      this.validNumber = false;
      this.errMessNumber = 'Minimum 9 numbers';
      this.isDisabled = true;
      this.errMessNumberWA = '';
    } else {
      this.validNumber = true;
      this.errMessNumber = '';
      this.isDisabled = false;
      this.inputSubject.next(input.value);
    }
  }
  async checkWA() {
    const result = await this.whatsappService.checkWA(this.formGroupInputNumber.get('telephone')?.value);
    this.isWA = result.valid;
    this.errMessNumberWA = result.message;
  }

  validateNumber() {
    const phone = this.formGroupInputNumber.get('telephone')?.value;
    this.baseService
      .getData(VALIDATE_PHONE + `?phone_number=${phone}`, null)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.isChecked = true;
          if (res.data.status === 'used') {
            this.errMessNumber = 'Number already used';
            this.message.create('error', 'Number already used');
          }
          this.checkWA();
        },
        (err) => {
          this.message.create('error', err.error.meta.message);
        }
      );
  }

  handleConfirm() {
    this.activeModal.close();
    const modal = this.modalService.open(ModalChangeNumberComponent, {
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });

    modal.componentInstance.phoneNumber =
      this.formGroupInputNumber.get('telephone')?.value;
    modal.componentInstance.isConfirm = true;
  }
}
