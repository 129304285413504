import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BaseService } from 'src/app/services/base.service';
import { DOC_STATE, PDF_CA, PDF_SLA } from 'src/app/constants/endpoint.const';
import { Subject, takeUntil } from 'rxjs';
import { ResponseCustomType } from 'src/app/types/response/response.type';
import { DocumentStateResponseType } from 'src/app/types/response/document-state.response.type';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-document',
  standalone: false,
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class DocumentComponent {
  loading: boolean = true;
  destroy$ = new Subject();
  docState: DocumentStateResponseType[] = [];
  docSLA: string = '';
  docCA: string = '';
  docUAT: string = '';

  constructor(
    private router: Router,
    private BaseService: BaseService,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    this.getDocState();
  }

  inputSLAData(v: string) {
    if (v === 'create') {
      this.router.navigate(['/document/sla-data-input'], {
        queryParams: { step: 1 },
      });
    } else {
      this.router.navigate(['/document/sla-data-input'], {
        queryParams: { step: 1, mode: v },
      });
      localStorage.setItem('mode-sla-input', v);
    }
  }

  sendSLA() {
    this.router.navigate(['/document/submission-sla']);
  }
  sendCA() {
    this.router.navigate(['/document/submission-ca']);
  }

  inputCA(v: string) {
    if (v === 'create') {
      this.router.navigate(['/document/ca-data-input']);
    } else {
      this.router.navigate(['/document/ca-data-input'], {
        queryParams: { mode: v },
      });
    }
    localStorage.setItem('mode-ca-input', v);
  }

  inputUATData(v: string) {
    this.router.navigate(['/document/uat-data-input'], {
      queryParams: { step: 1 },
    });
    localStorage.setItem('mode-uat-input', v);
  }

  getDocState() {
    this.BaseService.getData(DOC_STATE)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: ResponseCustomType<DocumentStateResponseType[]>) => {
          this.docState = res.data
        },
        (err) => {
          console.error(err);
          this.message.create('error', err.error.data.errors);
        }
      );
  }
  downloadPdf(v: string) {
    const url = v === 'SLA' ? PDF_SLA : PDF_CA
    this.BaseService.getData(url)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.docSLA = res.data.uploaded_file_path
            window.open(this.docSLA, '_blank');
          this.updateState(v)
        },
        (err) => {
          this.message.create('error', 'The document is still in progress or has not been inputted yet');
        }
      );
  }

  updateState(v: string): void {
    const params = {
      document_type: v,
      state: 3,
    }
    this.BaseService.putData(DOC_STATE, params)
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (res) => {
        this.getDocState()
      },
      (err) => {
        console.error(err)
      }
    )
  }

  handleStatus(v: number) {
    if (v === 1) return 'Not Activated'
    if (v === 2 || v === 3 || v === 4) return 'On Process'
    if (v === 5) return 'Active'
    if (v === 6) return 'Request Decline'
    return ''
  }

  handleDotStatus(v: number) {
    if (v === 1) return 'dot-notactived'
    if (v === 2 || v === 3 || v === 4) return 'dot-proceed'
    if (v === 5) return 'dot-actived'
    if (v === 6) return 'dot-reject'
    return ''
  }
}
