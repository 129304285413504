import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseService } from '../../services/base.service';
import {
  LOGIN,
  RECAPTCHA_TOKEN,
  RESEND_VERIFY,
} from 'src/app/constants/endpoint.const';
import { takeUntil, Subject, Subscription } from 'rxjs';
import { LocalStorageService } from '../../services/local-storage.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ModalRegisterSuccessComponent } from 'src/app/pages/register/modal-register-success/modal-register-success.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LoaderService } from 'src/app/services/loader.service';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { BypassInterceptorContext } from 'src/app/interceptors/http.interceptor';
import { ModeSwitchService } from 'src/app/services/mode-switch.service';
import { environment as devEnv } from 'src/environments/environment';
import { environment as sandEnv } from 'src/environments/environment.sandbox';
import { environment as prodEnv } from 'src/environments/environment.prod';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
})
export class AuthLayoutComponent implements OnInit {
  formGroup: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(50),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(32),
    ]),
  });

  isLoading: boolean = false;
  isShowPassword: boolean = false;
  destroy$ = new Subject();
  errorUser = '';
  errorRole = '';
  eyeOn =
    'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye.svg';
  eyeOff =
    'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye-slash.svg';

  errMessPass: string = '';
  isValidPass: boolean = true;
  validEmail: boolean = true;
  failedLogin: boolean = false;
  profileData!: any;
  private profileSubscription!: Subscription;

  constructor(
    private BaseService: BaseService,
    private renderer: Renderer2,
    private localStorageService: LocalStorageService,
    private router: Router,
    private message: NzMessageService,
    private modalService: NzModalService,
    public loader: LoaderService,
    private httpClient: HttpClient,
    private modeSwitchService: ModeSwitchService,
    private profileService: ProfileService,
  ) {}
  ngOnInit(): void {
    this.addScriptToHead(
      `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_TOKEN}`
    );
  }

  addScriptToHead(src: string): void {
    const script = this.renderer.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    script.async = true;
    this.renderer.appendChild(document.head, script);
  }

  disableEnterKey(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  validateEmail() {
    const value = this.formGroup.get('email')?.value || '';
    const regex = /^[a-zA-Z0-9+@._-]+$/g;

    if (value === '') {
      this.validEmail = false;
      this.errorUser = 'Email must be filled in';
    } else {
      if (value.length < 10) {
        this.errorUser = 'Minimum 10 characters';
        this.validEmail = false;
      } else if (value.length > 50) {
        this.errorUser = 'Maximum 50 characters';
        this.validEmail = false;
      } else if (!regex.test(value)) {
        this.validEmail = false;
        this.errorUser = 'Email must be a valid email address';
      } else {
        const atIndex = value.indexOf('@');
        const lastDotIndex = value.lastIndexOf('.');
        const dotBeforeAt = value.substring(0, atIndex).includes('.');
        const dotAfterAt = value.substring(atIndex + 1).includes('.');

        if (
          atIndex === -1 ||
          lastDotIndex === -1 ||
          lastDotIndex === value.length - 1 ||
          value[atIndex + 1] === '.' ||
          !dotAfterAt
        ) {
          this.errorUser = 'Email must be a valid email address';
          this.validEmail = false;
        } else {
          this.validEmail = true;
          this.errorUser = '';
        }
      }
    }
  }

  validatePassword() {
    const value = this.formGroup.get('password')?.value || '';
    const regex = /^[a-zA-Z0-9`~!()\-_+={}\[\]:;'.<>\/]+$/g;

    if (value.length === 0) {
      this.errMessPass = 'Password must be filled';
      this.isValidPass = false;
    } else if (value.length < 8) {
      this.errMessPass = 'Minimum 8 characters';
      this.isValidPass = false;
    } else if (!regex.test(value)) {
      this.isValidPass = false;
      this.errMessPass = 'There are unsupported characters';
    } else {
      this.errMessPass = '';
      this.isValidPass = true;
    }
  }

  setRegex(event: any) {
    const regex = /^[a-zA-Z0-9+@._-]+$/g;
    return regex.test(event.key);
  }

  setRegexPassword(event: any) {
    const regex = /^[a-zA-Z0-9`~!()\-_+={}\[\]:;'.<>\/]+$/g;
    return regex.test(event.key);
  }

  showPasswordClick(): void {
    this.isShowPassword = !this.isShowPassword;
  }

  get form() {
    return {
      formGroup: this.formGroup,
      email: this.formGroup.get('email'),
      password: this.formGroup.get('password'),
    };
  }

  async getCaptchaToken(event: Event) {
    event.preventDefault();

    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(
          `${RECAPTCHA_TOKEN}`,
          { action: 'REGISTER_RAJAONGKIR' }
        );
        this.handleLogin(token);
      } catch (error) {
        console.error('Error executing reCAPTCHA:', error);
        this.message.create('error', 'Error executing reCAPTCHA');
      }
    });
  }

  resendVerif(event: Event) {
    event.preventDefault();

    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(
          `${RECAPTCHA_TOKEN}`,
          { action: 'RESEND_ACTIVATION' }
        );

        const params = {
          email: this.formGroup.get('email')?.value,
          recaptcha_token: token,
        };

        this.BaseService.postData(RESEND_VERIFY, params)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (response) => {
              this.modalService.create({
                nzContent: ModalRegisterSuccessComponent,
                nzFooter: null,
              });
            },
            (err) => {
              this.message.create(
                'error',
                'An error occurred. Please try again later.'
              );
            }
          );
      } catch (error) {
        console.error('Error executing reCAPTCHA:', error);
        this.message.create('error', 'Error executing reCAPTCHA');
      }
    });
  }

  handleLoginProd(e: string) {
    if (this.formGroup.get('password')?.value.length >= 6) {
      const params: object = {
        email: this.formGroup.get('email')?.value,
        password: this.formGroup.get('password')?.value,
        recaptcha_token: e,
        fcm_token: environment.fcm_token,
      };
      const baseUrl = devEnv.BASE_URL;
      const endPoint = '/user/api/v2/auth/login';
      const apiUrl = baseUrl + endPoint;
      const headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      });

      const context = new HttpContext().set(BypassInterceptorContext, true);

      this.httpClient
        .post<any>(apiUrl, params, { headers, context })
        .subscribe({
          next: (res) => {
            const { data } = res;
            this.modeSwitchService.switchMode('sandbox');
            localStorage.setItem('mode', 'sandbox');
            localStorage.setItem('auth-token', data.access_token);
            localStorage.setItem('refresh-token', data.refresh_token);
            this.localStorageService.saveUser(data.name);
            this.getProfile()
          },
          error: (err) => {
            this.message.create(
              'error',
              'An error occurred. Please try again later.'
            );
            this.failedLogin = true;
            this.validEmail = false;
            this.isValidPass = false;
            if (err.error.data.errors === 'user not activated') {
              this.errorUser = 'Your email has not been verified';
              this.validEmail = false;
              this.isValidPass = false;
              if (err.error.data.errors === 'user not activated') {
                this.errorUser = 'Your email has not been verified';
                this.validEmail = false;
                this.failedLogin = false;
                this.isValidPass = true;
              }
            }
            if (err.error.data.errors === 'email not registered') {
              this.errorUser = 'Email not registered yet';
              this.validEmail = false;
              this.failedLogin = false;
              this.isValidPass = true;
            }
          },
        });
    }
  }


  handleLogin(e: string) {
    if (this.formGroup.get('password')?.value.length >= 6) {
      const params: object = {
        email: this.formGroup.get('email')?.value,
        password: this.formGroup.get('password')?.value,
        recaptcha_token: e,
        fcm_token: environment.fcm_token,
      };
      const baseUrl = sandEnv.BASE_URL;
      const endPoint = '/user/api/v2/auth/login';
      const apiUrl = baseUrl + endPoint;

      const headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      });

      const context = new HttpContext().set(BypassInterceptorContext, true);

      this.httpClient
        .post<any>(apiUrl, params, { headers, context })
        .subscribe({
          next: (res) => {
            const { data } = res;
            this.modeSwitchService.switchMode('production');
            localStorage.setItem('mode', 'production');
            localStorage.setItem('auth-token', data.access_token);
            localStorage.setItem('refresh-token', data.refresh_token);
            this.localStorageService.saveUser(data.name);
            this.getProfile()
          },
          error: (err) => {
            if (
              err.error.data.errors === 'email not registered' ||
              err.error.data.errors === 'user not activated by admin'
            ) {
              this.handleLoginProd(e);
            } else {
              this.message.create(
                'error',
                'An error occurred. Please try again later.'
              );
              this.failedLogin = true;
              this.validEmail = false;
              this.isValidPass = false;
              if (err.error.data.errors === 'user not activated') {
                this.errorUser = 'Your email has not been verified';
                this.validEmail = false;
                this.failedLogin = false;
                this.isValidPass = true;
              }
            }
          },
        });
    }
  }

  async getProfile() {
    this.profileSubscription = this.profileService.profileData$.subscribe((data) => {
      this.profileData = data;
      console.log('Profile data received:', data);

      this.router.navigate(this.profileData.is_rajaongkir === false && this.profileData.is_openapi === false ? ['/billings'] : ['/dashboard']);
    });

    // Fetch profile data
    this.profileService.getProfile();
  }

  ngOnDestroy() {}
}
