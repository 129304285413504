import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  LOCALE_ID,
  ViewChild,
  HostListener,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { OrderService } from '../../services/api/order.service';
import { AccumulationDataOrderResponseType } from '../../types/response/accumulation-data-order-response.type';
import * as moment from 'moment';
import 'moment/locale/id';
import {
  LocaleConfig,
  DaterangepickerDirective,
} from 'ngx-daterangepicker-material';
import { Dayjs } from 'dayjs';
import localeId from '@angular/common/locales/id';
import { registerLocaleData } from '@angular/common';
import {
  NgbDateParserFormatter,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { OrderModel } from 'src/app/models/data-order.model';
import { PaginationModelDashboard } from 'src/app/models/pagination.model';
import { SHOW_PER_PAGE_DASHBOARD } from 'src/app/constants/pagination.const';
import { ModalDlDataOrder } from 'src/app/modules/shared/components/modal-download-data-order/modal-download-data-order.component';
import { LocaleConfigDate, rangesDate } from 'src/app/constants/date-config.const';
registerLocaleData(localeId);

@Component({
  selector: 'app-data-order',
  templateUrl: './data-order.component.html',
  styleUrls: ['./data-order.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'id' }],
})
export class DataOrderComponent implements OnInit {
  dataOrder: OrderModel[] = [];
  @Input() pagination!: PaginationModelDashboard;
  totalOrder!: number;
  accumulationDataOrder!: AccumulationDataOrderResponseType;
  destroy$ = new Subject();
  @ViewChild(DaterangepickerDirective, { static: false })
  pickerDirective!: DaterangepickerDirective;
  constructor(
    private orderService: OrderService,
    public formatter: NgbDateParserFormatter,
    private modalService: NgbModal,
  ) {
    this.keepCalendarOpeningWithRange = true;
  }

  status: any = [
    {
      value: 'Diajukan,Dikirim,Dipacking,Diterima,Retur',
      label: 'All',
      id: 'Semua',
      checked: false,
    },
    {
      value: 'Diajukan',
      label: 'Submitted',
      id: 'Diajukan',
      checked: false,
    },
    {
      value: 'Dikirim',
      label: 'Sent',
      id: 'Dikirim',
      checked: false,
    },
    {
      value: 'Dipacking',
      label: 'Packed',
      id: 'Dipacking',
      checked: false,
    },
    {
      value: 'Diterima',
      label: 'Received',
      id: 'Diterima',
      checked: false,
    },
    {
      value: 'Retur',
      label: 'Return',
      id: 'Retur',
      checked: false,
    },
  ];

  selectedStatuses: string[] = [];
  isSemua: boolean = false;

  page: number = 1;
  perPage: number = 20;

  totalPage: number = 0;

  selected: any = {
    startDate: moment().subtract(6, 'days'),
    endDate: moment(),
  };

  ranges: any = rangesDate

  invalidDates: moment.Moment[] = [
    moment().add(2, 'days'),
    moment().add(3, 'days'),
    moment().add(5, 'days'),
  ];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  @Input() filterAcOrder: any = {
    start_date: moment().startOf('month').locale('id').format('YYYY-MM-DD'),
    end_date: moment().endOf('month').locale('id').format('YYYY-MM-DD'),
  };

  @Input() filterOrder: any = {
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    order_status: 'Diajukan,Dikirim,Dipacking,Diterima,Retur',
    awb: '',
    page: this.page,
    per_page: SHOW_PER_PAGE_DASHBOARD[0],
  };

  @Output() filterResult = new EventEmitter();

  setlocale: LocaleConfig = LocaleConfigDate
  keepCalendarOpeningWithRange: boolean;
  isDatePickerVisible: boolean = false;
  isDisPrev: boolean = true;
  isDisNext: boolean = false;
  loading: boolean = true;
  loadData: boolean = true;

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event): void {
    this.clearLocalStorage();
  }

  private clearLocalStorage(): void {
    localStorage.removeItem('start-date');
    localStorage.removeItem('end-date');
    localStorage.removeItem('status');
    localStorage.removeItem('per-page');
    localStorage.removeItem('page');
    localStorage.removeItem('selectedSDate');
    localStorage.removeItem('selectedEDate');
  }

  ngOnInit(): void {
    const storedStartDate = localStorage.getItem('start-date');
    const storedEndDate = localStorage.getItem('end-date');
    const storedStatus = localStorage.getItem('status');
    const storedPerPage = localStorage.getItem('per-page');
    const storedPage = localStorage.getItem('page');
    const selectedSDate = localStorage.getItem('selectedSDate');
    const selectedEDate = localStorage.getItem('selectedEDate');

    this.filterOrder.start_date =
      storedStartDate || moment().subtract(6, 'days').format('YYYY-MM-DD');
    this.filterOrder.end_date =
      storedEndDate || moment().format('YYYY-MM-DD') ;
    this.filterOrder.order_status =
      storedStatus || 'Diajukan,Dikirim,Dipacking,Diterima,Retur';
    this.filterOrder.page = storedPage || this.page;
    this.filterOrder.per_page = storedPerPage || SHOW_PER_PAGE_DASHBOARD[0];

    if (selectedSDate) {
      this.selected.startDate = moment(JSON.parse(selectedSDate as string));
    } else {
      this.selected.startDate = moment().subtract(6, 'days');
    }

    if (selectedEDate) {
      this.selected.endDate = moment(JSON.parse(selectedEDate as string))
    } else {
      this.selected.endDate = moment()
    }

    this.getDataOrder();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  openDatepicker() {
    this.pickerDirective.open();
  }

  download() {
      const modal = this.modalService.open(ModalDlDataOrder, {
        backdrop: 'static',
        centered: true,
        size: 'xl',
      });
  }
  onChangeSearch(value: any) {
    this.filterOrder.awb = value;
    this.filterResult.emit(this.filterOrder);
    this.getDataOrder();
  }

  getStatus(event: any, list: any) {
    const inputElement = event.target as HTMLInputElement;
    const isChecked = inputElement.checked;

    if (list.id === 'Semua') {
      this.selectedStatuses = isChecked
        ? ['Diajukan', 'Dikirim', 'Dipacking', 'Diterima', 'Retur']
        : [];

      this.status = this.status.map((item: any) => {
        return { ...item, checked: isChecked };
      });
    } else {
      if (isChecked && !this.selectedStatuses.includes(list.value)) {
        this.selectedStatuses.push(list.value);
      } else if (!isChecked && this.selectedStatuses.includes(list.value)) {
        this.selectedStatuses = this.selectedStatuses.filter(
          (value) => value !== list.value
        );
      }

      const semuaCheckbox = this.status.find(
        (item: any) => item.id === 'Semua'
      );
      if (semuaCheckbox) {
        semuaCheckbox.checked = this.selectedStatuses.length === 5;
      }
    }

    if (this.selectedStatuses.length > 0) {
      this.filterOrder.order_status = this.selectedStatuses.join(',');
    } else {
      this.filterOrder.order_status =
        'Diajukan,Dikirim,Dipacking,Diterima,Retur';
    }

    localStorage.setItem('status', this.filterOrder.order_status);

    this.filterOrder.page = 1;
    this.filterResult.emit(this.filterOrder);
    this.getDataOrder();
  }

  onChangeDate(e: any) {
    const endDate: Dayjs  = e.endDate;
    const startDate: Dayjs  = e.startDate;

    this.filterOrder.start_date = startDate ? startDate.format('YYYY-MM-DD') : this.selected.startDate.format('YYYY-MM-DD');
    this.filterAcOrder.start_date = startDate ? startDate.format('YYYY-MM-DD') : this.selected.startDate.format('YYYY-MM-DD');
    this.filterOrder.end_date = endDate ? endDate.format('YYYY-MM-DD') : this.selected.endDate.format('YYYY-MM-DD');
    this.filterAcOrder.end_date = endDate ? endDate.format('YYYY-MM-DD') : this.selected.endDate.format('YYYY-MM-DD');
    this.filterOrder.page = 1;

    localStorage.setItem('start-date', this.filterOrder.start_date);
    localStorage.setItem('end-date', this.filterOrder.end_date);

    this.filterResult.emit(this.filterOrder);
    this.filterResult.emit(this.filterAcOrder);
    this.getDataOrder();

    localStorage.setItem('selectedSDate', JSON.stringify(startDate ? startDate as Dayjs : this.selected.startDate as Dayjs));
    localStorage.setItem('selectedEDate', JSON.stringify(endDate ? endDate as Dayjs : this.selected.endDate as Dayjs));
  }

  getDataOrder(): void {
    this.loadData = true
    this.orderService
      .dataOrder(this.filterOrder)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.dataOrder = res.data.orders;
          this.totalOrder = res.data.total_order;
          this.totalPage = res.data.total_page;
          this.dataOrder.forEach((item) => {
            item.isCollapsed = false;
          });
          this.dataOrder.sort(
            (a, b) =>
              new Date(b.order_date).getTime() -
              new Date(a.order_date).getTime()
          );

          if (
            this.totalPage === 1 ||
            this.totalOrder <= this.filterOrder.per_page
          ) {
            this.isDisNext = true;
            this.isDisPrev = true;
          } else if (this.filterOrder.page === this.totalPage) {
            this.isDisNext = true;
            this.isDisPrev = false;
          } else if (this.filterOrder.page === 1) {
            this.isDisPrev = true;
            this.isDisNext = false;
          } else {
            this.isDisPrev = false;
            this.isDisNext = false;
          }
          this.loadData = false
        },
        (err) => {
          this.loadData = false
        }
      );
  }

  onPerPageChange(perPage: number) {
    this.filterOrder.per_page = perPage;
    this.filterResult.emit(this.filterOrder);
    this.getDataOrder();
  }

  onPageChange(newPage: number) {
    if (newPage < this.filterOrder.page) {
      this.prevPage();
    } else if (newPage > this.filterOrder.page) {
      if (newPage === this.filterOrder.page + 2) {
        this.nextThree();
      } else {
        this.nextPage();
      }
    }
  }

  getListPage(event: any) {
    this.filterOrder.page = 1;
    this.updateLocalStorage('page', this.filterOrder.page);

    const selectedValue = Number(event.target.value);
    this.filterOrder.per_page = selectedValue;
    this.updateLocalStorage('per-page', selectedValue);

    this.updatePaginationState();
    this.filterResult.emit(this.filterOrder);
    this.getDataOrder();
  }

  prevPage() {
    if (this.filterOrder.page > 1) {
      this.filterOrder.page -= 1;
      this.updateLocalStorage('page', this.filterOrder.page);
      this.updatePaginationState();
      this.filterResult.emit(this.filterOrder);
      this.getDataOrder();
    }
  }

  nextPage() {
    this.filterOrder.page += 1;
    this.updateLocalStorage('page', this.filterOrder.page);
    this.updatePaginationState();
    this.filterResult.emit(this.filterOrder);
    this.getDataOrder();
  }

  nextThree() {
    this.filterOrder.page += 2;
    this.updateLocalStorage('page', this.filterOrder.page);
    this.updatePaginationState();
    this.filterResult.emit(this.filterOrder);
    this.getDataOrder();
  }

  private updatePaginationState() {
    const { page, per_page } = this.filterOrder;

    this.isDisPrev = page === 1;
    this.isDisNext = this.totalPage === 1 || this.totalOrder <= per_page || page === this.totalPage;
  }

  private updateLocalStorage(key: string, value: any) {
    localStorage.setItem(key, value.toString());
  }


  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }
}
