import { Component } from '@angular/core';

@Component({
  selector: 'app-rates-track',
  standalone: false,
  templateUrl: './rates-track.component.html',
  styleUrl: './rates-track.component.scss'
})
export class RatesTrackComponent {

}
