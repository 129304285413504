<nz-modal
  [(nzVisible)]="isVisibleInputNumber"
  nzCentered
  [nzFooter]="null"
  nzClosable="false"
>
  <ng-container *nzModalContent>
    <div class="d-flex flex-column align-items-center py-2">
      <h5 class="mt-3 text-bold text-capitalize">Add Phone Number</h5>
      <form [formGroup]="formGroup" class="d-flex gap-1">
        <div class="form-group mt-3 w-100">
          <nz-input-group nzAddOnBefore="+62" nzSize="large">
            <input
              type="text"
              nz-input
              formControlName="telephone"
              nzSize="large"
              placeholder="81234567890"
              aria-label="Telephone Number"
              maxlength="12"
              style="border-radius: 0 8px 8px 0 !important"
              (input)="validateInput($event)"
              [nzStatus]="validNumber ? '' : 'error'"
              />
          </nz-input-group>
          <span
            [ngClass]="
              validNumber
                ? 'text-green'
                : 'text-danger'
            "
            class="d-flex align-items-center gap-1"
          >
            <img
              *ngIf="!validNumber"
              [src]="
                errMessNumber !== 'Number connected with Whatsapp'
                  ? 'https://storage.googleapis.com/komerce/assets/icons/danger-red.svg'
                  : 'https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg'
              "
              alt="warning"
              style="max-width: 10px; max-height: 10px"
            />
            {{ errMessNumber }}</span
          >
        </div>
        <button
          [disabled]="
            formGroup.invalid
          "
          [ngClass]="{
            'btn-secondary':
              formGroup.invalid,
            'btn-primary': !formGroup.invalid
          }"
          class="btn btn-secondary text-white w-90 mt-3"
          style="height: 40px"
          (click)="handleOk()"
        >
          Submit
        </button>
      </form>
    </div>
  </ng-container>
</nz-modal>
