<div class="mt-4">
  <form [formGroup]="instantForm">
    <div class="d-flex flex-column gap-3">
      <div class="d-flex">
        <span class="d-flex flex-column col-3 gap-4">Origin City</span>
        <div class="col-6">
          <nz-input-group [nzSuffix]="mapTemplateOrigin">
            <input
              nz-input
              formControlName="originCity"
              placeholder="Search via map"
              type="text"
              (click)="openMapOrigin('Origin')"
            />
          </nz-input-group>
        </div>
      </div>

      <ng-template #mapTemplateOrigin>
        <img
          src="https://storage.googleapis.com/komerce/icon/map-red.svg"
          alt="map"
          (click)="openMapOrigin('Origin')"
        />
      </ng-template>
      <ng-template #mapTemplateDestination>
        <img
          src="https://storage.googleapis.com/komerce/icon/map-red.svg"
          alt="map"
          (click)="openMapOrigin('Destination')"
        />
      </ng-template>

      <div class="d-flex">
        <span class="d-flex flex-column col-3 gap-4">Destination City</span>
        <div class="col-6">
          <nz-input-group [nzSuffix]="mapTemplateDestination">
            <input
              nz-input
              formControlName="destinationCity"
              placeholder="Search via map"
              type="text"
              (click)="openMapOrigin('Destination')"
            />
          </nz-input-group>
        </div>
      </div>

      <div class="d-flex">
        <span class="d-flex flex-column col-3 gap-4">Package Value</span>
        <div class="col-6">
          <nz-input-group nzAddOnBefore="Rp" class="rounded">
            <input
              nz-input
              formControlName="itemValue"
              placeholder="Package Value"
              type="text"
              style="
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
              "
            />
          </nz-input-group>
        </div>
      </div>

      <div class="d-flex">
        <span class="d-flex flex-column col-3 gap-4">Package Weight</span>
        <div class="col-6">
          <nz-input-group nzAddOnAfter="grams" class="rounded">
            <input
              nz-input
              formControlName="packageWeight"
              placeholder="Package Weight"
              type="text"
              style="
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
              "
            />
          </nz-input-group>
        </div>
      </div>

      <div class="d-flex">
        <span class="d-flex col-3 gap-4">Package Volume</span>
        <div class="col-lg-6 col-12 d-flex flex-column flex-lg-row gap-1">
          <div class="col-6 col-lg-4">
            <nz-input-group nzAddOnAfter="cm" class="rounded">
              <input
                nz-input
                formControlName="packageVolume1"
                placeholder="Package Volume"
                type="text"
                style="
                  border-top-right-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                "
              />
            </nz-input-group>
          </div>
          <div class="col-6 col-lg-4">
            <nz-input-group nzAddOnAfter="cm" class="rounded">
              <input
                nz-input
                formControlName="packageVolume2"
                placeholder="Package Volume"
                type="text"
                style="
                  border-top-right-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                "
              />
            </nz-input-group>
          </div>
          <div class="col-6 col-lg-4">
            <nz-input-group nzAddOnAfter="cm" class="rounded">
              <input
                nz-input
                formControlName="packageVolume3"
                placeholder="Package Volume"
                type="text"
                style="
                  border-top-right-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                "
              />
            </nz-input-group>
          </div>
        </div>
      </div>

      <div class="d-flex mt-3">
        <span class="d-flex flex-column col-3 gap-4"></span>
        <div class="d-flex col-4 gap-2">
          <button
            class="btn btn-outline-secondary col-6"
            (click)="resetValue()"
          >
            Reset
          </button>
          <button
            class="btn btn-primary text-white col-6"
            (click)="calculateDestination()"
            [disabled]="instantForm.invalid"
            [ngClass]="{ 'btn-secondary': instantForm.invalid }"
          >
            Check
          </button>
        </div>
      </div>
    </div>
  </form>

  <ng-container *ngIf="listCalculate.length > 0">
    <app-table-instant-rates [listOfData]="listCalculate" />
  </ng-container>
</div>

<app-modal-map-picker
  [title]="titleModal"
  [isOpenMap]="openOriginMap"
  (locationChange)="onLocationChange($event)"
  (closeModal)="closeMapOrigin()"
/>
