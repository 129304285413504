import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseService } from '../../services/base.service';
import {
  RECAPTCHA_TOKEN,
  RESET_PASSWORD,
} from 'src/app/constants/endpoint.const';
import { takeUntil, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { validatePassword } from 'src/app/constants/auth-config.const';
import { FormValidationService } from 'src/app/services/form-validation.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  isLoading: boolean = false;
  destroy$ = new Subject();
  errorUser = '';
  validEmail: boolean = true;
  isValidPass: boolean = true;
  errMessPass: string = '';
  errMessPass2: string = '';
  isErrPass: boolean = false;
  isShowPassword: boolean = false;
  isShowPassword2: boolean = false;
  success: boolean = false;
  eyeOn =
    'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye.svg';
  eyeOff =
    'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye-slash.svg';

  validationPassword = validatePassword
  visibleValPass: boolean = false;

  formGroup: FormGroup = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(32),
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(32),
    ]),
    checked: new FormControl(false, [Validators.required]),
  });

  constructor(
    private baseService: BaseService,
    private renderer: Renderer2,
    private message: NzMessageService,
    private activatedRoute: ActivatedRoute,
    private formValidation: FormValidationService
  ) {}
  ngOnInit(): void {
    this.addScriptToHead(
      `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_TOKEN}`
    );
  }

  addScriptToHead(src: string): void {
    const script = this.renderer.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    script.async = true;
    this.renderer.appendChild(document.head, script);
  }

  disableEnterKey(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  validateEmail() {
    const value = this.formGroup.get('email')?.value || '';

    if (value === '') {
      this.validEmail = false;
      this.errorUser = 'Email is required';
    } else {
      this.validEmail = true;
      this.errorUser = '';
    }
  }

  setRegexPassword(event: any) {
    const regex = /^[a-zA-Z0-9`~!()\-_+={}\[\]:;'.<>\/]+$/g;
    return regex.test(event.key);
  }

  get form() {
    return {
      formGroup: this.formGroup,
      password: this.formGroup.get('password'),
      confirmPassword: this.formGroup.get('confirmPassword'),
    };
  }

  validatePassword() {
    this.visibleValPass = true;
    const value = this.formGroup.get('password')?.value || '';
  
    const result = this.formValidation.validatePassword(value, this.validationPassword);
    
    this.isValidPass = result.isValid;
    this.errMessPass = result.isValid ? '' : 'Password does not comply with the provisions';
  
    if (value.length === 0) {
      this.errMessPass = 'Password must be filled';
    }
  
    const value2 = this.formGroup.get('confirmPassword')?.value || '';
    this.errMessPass2 = value !== value2 && value2 !== '' ? 
      'Make sure the password confirmation is the same as the previous password.' : '';
  
    this.formGroup.get('password')?.setErrors(
      result.errors.length > 0 ? { passwordInvalid: result.errors.join(', ') } : null
    );
  }

  confirmPassword() {
    const value1 = this.formGroup.get('password')?.value || '';
    const value2 = this.formGroup.get('confirmPassword')?.value || '';

    if (value1 !== value2 && value2 !== '') {
      this.errMessPass2 =
        'Make sure the password confirmation matches the original password';
    } else if (value2.length === 0) {
      this.errMessPass2 = 'Password confirmation is required';
    } else {
      this.errMessPass2 = '';
    }
  }

  async getCaptchaToken(event: Event) {
    event.preventDefault();

    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(
          `${RECAPTCHA_TOKEN}`,
          { action: 'RESET_PASSWORD' }
        );
        this.handleResetPass(token);
      } catch (error) {
        console.error('Error executing reCAPTCHA:', error);
        this.message.create('error', 'Error executing reCAPTCHA');
      }
    });
  }

  handleResetPass(e: string) {
    const params = {
      new_password: this.formGroup.get('password')?.value,
      confirm_password: this.formGroup.get('confirmPassword')?.value,
      token: this.activatedRoute.snapshot.queryParamMap.get('token'),
      recaptcha_token: e,
    };
    this.baseService
      .putData(RESET_PASSWORD, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.success = true;
        },
        (err) => {
          this.isLoading = false;
          if (err.error.data.errors === 'token expired') {
            this.message.create(
              'error',
              'Forgot password access has expired, please request again!'
            );
          } else {
            this.message.create(
              'error',
              'Password Reset Failed, Please Try Again'
            );
          }
        }
      );
  }

  ngOnDestroy() {}
}
