import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject, takeUntil } from 'rxjs';
import { CALCULATE_DESTINATION, GET_CITY } from 'src/app/constants/endpoint.const';
import { BypassInterceptorContext } from 'src/app/interceptors/http.interceptor';
import { BaseService } from 'src/app/services/base.service';
import { environment as devEnv } from 'src/environments/environment';

@Component({
  selector: 'app-regular-rates',
  standalone: false,
  templateUrl: './regular-rates.component.html',
  styleUrl: './regular-rates.component.scss'
})
export class RegularRatesComponent {
  $destroy = new Subject
  regulerForm: FormGroup = new FormGroup({
    originCity: new FormControl(null, [Validators.required]),
    destinationCity: new FormControl(null, [Validators.required]),
    packageWeight: new FormControl(null, [Validators.required]),
    itemValue: new FormControl(null, [Validators.required]),
    packageVolume1: new FormControl(null),
    packageVolume2: new FormControl(null),
    packageVolume3: new FormControl(null),
  })

  cityList: any[] = [];
  listCalculate: any;
  listCargo: any;
  loading: boolean = false;

  constructor(
    private baseService: BaseService,
    private http: HttpClient,
    private message: NzMessageService
  ) { }
  
  getCityList(searchTerm: any) {
    if (!searchTerm) return;
    const baseUrl = devEnv.BASE_URL
    const token = localStorage.getItem('auth-token');
    const headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    });

    const context = new HttpContext().set(BypassInterceptorContext, true);

    this.http.get(baseUrl + `/tariff/api/v1/internal/destination/search-destination?keyword=${searchTerm.term}`, { headers, context })
      .subscribe((res: any) => {
        this.cityList = res.data;
      });
  }

  calculateDestination() {
    this.loading = true;
    const params = {
      shipper_destination_id: this.regulerForm.value.originCity,
      receiver_destination_id: this.regulerForm.value.destinationCity,
      weight: this.regulerForm.value.packageWeight / 1000,
      length: this.regulerForm.value.packageVolume1,
      width: this.regulerForm.value.packageVolume2,
      height: this.regulerForm.value.packageVolume3,
      item_value: this.regulerForm.value.itemValue
    }
    this.baseService.getData(CALCULATE_DESTINATION, params)
      .pipe(takeUntil(this.$destroy))
      .subscribe((res: any) => {
        this.listCalculate = res.data.calculate_reguler
        this.listCargo = res.data.calculate_cargo
        this.loading = false;

        if (res.data.calculate_reguler.length === 0 && res.data.calculate_reguler.length === 0) {
          this.message.error('Rates Data not found');
        }
      }, (err) => {
        this.loading = false;
        this.message.error(err.error.message);})
  }

  resetValue() {
    this.regulerForm.reset();
    this.listCalculate = [];
    this.listCargo = [];
  }
}
