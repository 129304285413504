import { Component, Input } from '@angular/core';
import { RatesTableType } from 'src/app/models/rates-track.model';

@Component({
  selector: 'app-table-regular-rates',
  standalone: false,
  templateUrl: './table-regular-rates.component.html',
  styleUrl: './table-regular-rates.component.scss'
})
  
export class TableRegularRatesComponent {
  @Input() listReguler: any[] = [];
  @Input() listCargo: any[] = [];

  generateIcon(courier: string) {
    if(courier === 'NINJA') return 'https://storage.googleapis.com/komerce/assets/illustration/NINJA.png';
    if (courier === 'JNE') return 'https://storage.googleapis.com/komship-bucket/shipment/jne-logo.png';
    if (courier === 'SICEPAT') return 'https://storage.googleapis.com/komship-bucket/shipment/sicepat-logo.png';
    if (courier === 'IDEXPRESS') return 'https://storage.googleapis.com/komship-bucket/shipment/idexpress-logo.png';
    if (courier === 'SAP') return 'https://storage.googleapis.com/komerce/rajaongkir/sapx-color.svg';
    if (courier === 'JNT') return 'https://storage.googleapis.com/komerce/assets/illustration/JNT.png';
    if (courier === 'GOSEND') return 'https://storage.googleapis.com/komerce/assets/illustration/gosend.svg';
    return ''
  }
}
