import { Component } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject, takeUntil } from 'rxjs';
import { CHANGE_ORDER_STATUS, CHECK_ORDER_STATUS } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-simulator',
  standalone: false,
  templateUrl: './simulator.component.html',
  styleUrl: './simulator.component.scss',
})
export class SimulatorComponent {
  skeleton: boolean = true;
  listStatus: any[] = [];
  statusOrder: string = '';
  orderNumber: string = '';
  awb: string = '';
  selectedStatus!: string;
  destroy$ = new Subject();
  isValidOrder: boolean = true;
  isValidAwb: boolean = true;

  constructor(
    private BaseService: BaseService,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.skeleton = false;
    }, 1000);
  }

  checkStatus(): void {
    const params = {
      order_no: this.orderNumber,
      awb: this.awb,
    };
    this.BaseService.getData(CHECK_ORDER_STATUS, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.statusOrder = res.data.status;
          this.updateListStatus();
        },
        (err) => {
          if (err.error.meta.message === 'order not found') {
            this.message.create('error', 'Order Not Found');
          } else {
            this.message.create('error', err.error.meta.message);
          }
        }
      );
  }

  changeStatus(): void {
    const params = {
      order_no: this.orderNumber,
      cnote: this.awb,
      status: this.selectedStatus,
    };
    this.BaseService.postData(CHANGE_ORDER_STATUS, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.message.create('success', 'Your delivery status has been successfully changed.');
          this.statusOrder = this.selectedStatus;
          this.selectedStatus = '';
          this.updateListStatus();
        },
        (err) => {
          if (err.error.meta.message === 'order not found') {
            this.message.create('error', 'Order Not Found');
          } if (err.error.meta.message === 'awb not match') {
            this.message.create('error', 'AWB Not Match, Please Check Again');
          } else {
            this.message.create('error', err.error.meta.message);
          }
        }
      );
  }

  updateListStatus(): void {
    const statusMap: { [key: string]: any[] } = {
      packed: [
        { label: 'Sent', value: 'sent' },
        { label: 'Problem', value: 'problem' },
        { label: 'Return', value: 'return' },
        { label: 'Return Done', value: 'return_done' },
        { label: 'Received', value: 'received' }
      ],
      sent: [
        { label: 'Problem', value: 'problem' },
        { label: 'Return', value: 'return' },
        { label: 'Return Done', value: 'return_done' },
        { label: 'Received', value: 'received' }
      ],
      problem: [
        { label: 'Return', value: 'return' },
        { label: 'Return Done', value: 'return_done' },
        { label: 'Received', value: 'received' }
      ],
      return: [
        { label: 'Return Done', value: 'return_done' },
      ],
      return_done: [],
      received: []
    };
    
    this.listStatus = statusMap[this.statusOrder] || [];
  }

  classStatus(v: string) {
    if (v === 'packed') {
      return 'packed'
    } if (v === 'sent') {
      return 'sent'
    } if (v === 'problem') {
      return 'problem'
    } if (v === 'return') {
      return 'return'
    } if (v === 'return_done') {
      return 'return'
    } if (v === 'received') {
      return 'received'
    }
    return 'default'
  }
  labelStatus(v: string) {
    if (v === 'return_done') return 'Return Done'
    return v
  }
  setRegex(event: any) {
    const regex = /^[a-zA-Z0-9]+$/g;
    return regex.test(event.key);
  }

  validateInput(event: Event, type: 'awb' | 'order') {
    const input = event.target as HTMLInputElement;
    const regex = /^[a-zA-Z0-9]+$/g;

    if (!regex.test(input.value)) {
      if (type === 'awb') {
        this.isValidAwb = false
      } else {
        this.isValidOrder = false
      }
    } else {
      if (type === 'awb') {
        this.isValidAwb = true
      } else {
        this.isValidOrder = true
      }
    }
  }
}
