import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import {
  NzTableComponent,
  NzTableFilterFn,
  NzTableSortFn,
  NzTableSortOrder,
} from 'ng-zorro-antd/table';
import {
  DaterangepickerDirective,
  LocaleConfig,
} from 'ngx-daterangepicker-material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import localeId from '@angular/common/locales/id';
import { Dayjs } from 'dayjs';
import { registerLocaleData } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCreateTicketComponent } from '../modal-create-ticket/modal-create-ticket.component';
import { TicketService } from 'src/app/services/api/ticket.service';
import { LocaleConfigDate } from 'src/app/constants/date-config.const';
registerLocaleData(localeId);

interface ticketList {
  no_ticket: string;
  awb: string;
  shipper: string;
  customer: string;
  type_ticket: string;
  delay_action: string;
  status_ticket: string;
  status_order: string;
  created_at: string;
  updated_at: string;
  is_return: number;
}

interface ColumnItem {
  title: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<ticketList> | null;
  filterFn: NzTableFilterFn<ticketList> | null;
  sortDirections: NzTableSortOrder[];
}

@Component({
  selector: 'app-table-ticket',
  templateUrl: './table-ticket.component.html',
  styleUrls: ['./table-ticket.component.scss'],
})
export class TableTicketingComponent {
  @ViewChild('virtualTable', { static: false })
  nzTableComponent?: NzTableComponent<any>;
  private destroy$ = new Subject<boolean>();
  listOfData!: ticketList[];
  @ViewChild(DaterangepickerDirective, { static: false })
  pickerDirective!: DaterangepickerDirective;
  @ViewChild('picker') pickerTemplate!: ElementRef;
  @Output() filterResult = new EventEmitter();
  keepCalendarOpeningWithRange: boolean;
  selected: any = {
    startDate: moment().subtract(6, 'days'),
    endDate: moment(),
  };

  ranges: any = {
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
  };

  invalidDates: moment.Moment[] = [
    moment().add(2, 'days'),
    moment().add(3, 'days'),
    moment().add(5, 'days'),
  ];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  setlocale: LocaleConfig = LocaleConfigDate

  @Input() filterTicketing: any = {
    start_date:
      moment().subtract(29, 'days').format('YYYY-MM-DD') + ' 00:00:00',
    end_date: moment().format('YYYY-MM-DD') + ' 23:59:59',
    shipping: '',
    type_ticket_id: '',
    status_ticket: '',
    page: 1,
    per_page: 250,
    search: '',
    unread: false,
  };
  ekspedisi: any = [];
  selectedEkspedisi: string[] = [];
  selectedTypeTicket: string[] = [];
  statusTicket: any = [
    {
      value: '0',
      label: 'Further Action Required',
      id: '0',
      checked: false,
    },
    {
      value: '1',
      label: 'Unprocessed',
      id: '1',
      checked: false,
    },
    {
      value: '2',
      label: 'Processing',
      id: '2',
      checked: false,
    },
    {
      value: '3',
      label: 'Done',
      id: '3',
      checked: false,
    },
    {
      value: '4',
      label: 'Canceled',
      id: '4',
      checked: false,
    },
  ];
  selectedStatusTicket: string[] = [];
  isAll: boolean = false;
  dateOpen: boolean = false;
  listOfColumn: ColumnItem[] = [
    {
      title: 'Ticket Number',
      sortOrder: null,
      sortFn: null,
      sortDirections: [null],
      filterFn: null,
    },
    {
      title: 'Receipt Number',
      sortOrder: null,
      sortFn: null,
      sortDirections: [null],
      filterFn: null,
    },
    {
      title: 'Expedition',
      sortOrder: null,
      sortFn: null,
      sortDirections: [null],
      filterFn: null,
    },
    {
      title: 'Customer',
      sortOrder: null,
      sortFn: null,
      sortDirections: [null],
      filterFn: null,
    },
    {
      title: 'Type Ticket',
      sortOrder: null,
      sortFn: (a: ticketList, b: ticketList) =>
        a.type_ticket.localeCompare(b.type_ticket),
      sortDirections: ['ascend', 'descend'],
      filterFn: (list: string[], item: ticketList) =>
        list.some((name) => item.type_ticket.indexOf(name) !== -1),
    },
    {
      title: 'Delay Action',
      sortOrder: null,
      sortFn: null,
      sortDirections: [null],
      filterFn: null,
    },
    {
      title: 'Ticket Status',
      sortOrder: null,
      sortFn: (a: ticketList, b: ticketList) =>
        a.status_ticket.localeCompare(b.status_ticket),
      sortDirections: ['ascend', 'descend'],
      filterFn: (list: string[], item: ticketList) =>
        list.some((name) => item.status_ticket.indexOf(name) !== -1),
    },
    {
      title: 'Date Created',
      sortFn: (a: ticketList, b: ticketList) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      sortDirections: ['ascend', 'descend'],
      sortOrder: null,
      filterFn: null,
    },
    {
      title: 'Date Updated',
      sortFn: (a: ticketList, b: ticketList) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
      sortDirections: ['ascend', 'descend'],
      sortOrder: null,
      filterFn: null,
    },
  ];
  isDisPrev: boolean = true;
  isDisNext: boolean = false;
  page: number = 1;
  perPage: number = 250;
  totalPage: number = 0;
  totalTicket!: number;
  listTypeTicket: any = [];
  searchFilter: string = '';
  unreadTicket: number = 0;
  loading: boolean = true;

  constructor(
    private modalService: NgbModal,
    private ticketService: TicketService
  ) {
    this.keepCalendarOpeningWithRange = true;
  }

  ngOnInit(): void {
    this.getExpedition();
    this.getListTicket();
    this.getTypeTicket();
    this.getUnreadTicket();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }
  getTypeTicket() {
    const params = {
      all: true,
    };
    this.ticketService
      .typeTicket(params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.listTypeTicket = res.data.types.map((item: any) => ({
            ...item,
            checked: false,
          }));
        },
        () => {}
      );
  }
  getExpedition() {
    this.ticketService
      .expeditionList()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.ekspedisi = res.data;
        },
        () => {}
      );
  }

  onPerPageChange(perPage: number) {
    this.filterTicketing.per_page = perPage;
    this.filterResult.emit(this.filterTicketing);
    this.getListTicket();
  }

  onPageChange(newPage: number) {
    if (newPage < this.filterTicketing.page) {
      this.prevPage();
    } else if (newPage > this.filterTicketing.page) {
      if (newPage === this.filterTicketing.page + 2) {
        this.nextThree();
      } else {
        this.nextPage();
      }
    }
  }

  getListPage(event: any) {
    this.filterTicketing.page = 1;

    const selectedValue = event.target.value;
    this.filterTicketing.per_page = selectedValue;

    if (this.totalPage === 1 || this.totalTicket <= selectedValue) {
      this.isDisNext = true;
      this.isDisPrev = true;
    } else if (this.filterTicketing.page === this.totalPage) {
      this.isDisNext = true;
      this.isDisPrev = false;
    } else if (this.filterTicketing.page === 1) {
      this.isDisPrev = true;
      this.isDisNext = false;
    } else {
      this.isDisPrev = false;
      this.isDisNext = false;
    }
    this.updatePaginationState();
    this.filterResult.emit(this.filterTicketing);
    this.getListTicket();
  }
  prevPage() {
    if (this.filterTicketing.page > 1) {
      this.filterTicketing.page -= 1;
      this.updatePaginationState();
      this.filterResult.emit(this.filterTicketing);
      this.getListTicket();
    }
  }

  nextPage() {
    this.filterTicketing.page += 1;
    this.updatePaginationState();
    this.filterResult.emit(this.filterTicketing);
    this.getListTicket();
  }

  nextThree() {
    this.filterTicketing.page += 2;
    this.updatePaginationState();
    this.filterResult.emit(this.filterTicketing);
    this.getListTicket();
  }

  private updatePaginationState() {
    const { page, per_page } = this.filterTicketing;

    this.isDisPrev = page === 1;
    this.isDisNext =
      this.totalPage <= 1 ||
      this.totalTicket <= per_page ||
      page === this.totalPage;
  }

  createTicket() {
    const modal = this.modalService.open(ModalCreateTicketComponent, {
      backdrop: 'static',
      centered: true,
      size: 'md',
    });
  }
  selectEkspedisi(event: any, list: any) {
    const inputElement = event.target as HTMLInputElement;
    const isChecked = inputElement.checked;

    const index = this.ekspedisi.findIndex(
      (item: any) => item.value === list.value
    );
    if (index !== -1) {
      this.ekspedisi[index].checked = isChecked;
    }

    if (isChecked && !this.selectedEkspedisi.includes(list.value)) {
      this.selectedEkspedisi.push(list.value);
    } else if (!isChecked && this.selectedEkspedisi.includes(list.value)) {
      this.selectedEkspedisi = this.selectedEkspedisi.filter(
        (value) => value !== list.value
      );
    }

    if (this.selectedEkspedisi.length > 0) {
      this.filterTicketing.shipping = this.selectedEkspedisi.join(',');
    } else {
      this.filterTicketing.shipping = '';
    }

    this.filterResult.emit(this.filterTicketing);
    this.getListTicket();
  }
  selectTypeTicket(event: any, list: any) {
    const inputElement = event.target as HTMLInputElement;
    const isChecked = inputElement.checked;

    list.checked = isChecked;

    if (isChecked && !this.selectedTypeTicket.includes(list.id)) {
      this.selectedTypeTicket.push(list.id);
    } else if (!isChecked && this.selectedTypeTicket.includes(list.id)) {
      this.selectedTypeTicket = this.selectedTypeTicket.filter(
        (id) => id !== list.id
      );
    }

    if (this.selectedTypeTicket.length > 0) {
      this.filterTicketing.type_ticket_id = this.selectedTypeTicket.join(',');
    } else {
      this.filterTicketing.type_ticket_id = '';
    }

    this.filterResult.emit(this.filterTicketing);
    this.getListTicket();
  }
  selectStatusTicket(event: any, list: any) {
    const inputElement = event.target as HTMLInputElement;
    const isChecked = inputElement.checked;

    const index = this.statusTicket.findIndex(
      (item: any) => item.value === list.value
    );
    if (index !== -1) {
      this.statusTicket[index].checked = isChecked;
    }

    if (isChecked && !this.selectedStatusTicket.includes(list.value)) {
      this.selectedStatusTicket.push(list.value);
    } else if (!isChecked && this.selectedStatusTicket.includes(list.value)) {
      this.selectedStatusTicket = this.selectedStatusTicket.filter(
        (value) => value !== list.value
      );
    }

    if (this.selectedStatusTicket.length > 0) {
      this.filterTicketing.status_ticket = this.selectedStatusTicket.join(',');
    } else {
      this.filterTicketing.status_ticket = '';
    }

    this.filterResult.emit(this.filterTicketing);
    this.getListTicket();
  }
  openDatepicker(e: Event) {
    this.pickerDirective.open();
  }
  onChangeDate(e: any) {
    const endDate: Dayjs = e.endDate;
    const startDate: Dayjs = e.startDate;

    this.filterTicketing.start_date = startDate
      ? startDate.format('YYYY-MM-DD') + ' 00:00:00'
      : this.selected.startDate.format('YYYY-MM-DD') + ' 00:00:00';
    this.filterTicketing.end_date = endDate
      ? endDate.format('YYYY-MM-DD') + ' 23:59:59'
      : this.selected.endDate.format('YYYY-MM-DD') + ' 23:59:59';
    this.filterTicketing.page = 1;

    this.filterResult.emit(this.filterTicketing);
    this.getListTicket();
  }
  cariFilter() {
    this.filterTicketing.search = this.searchFilter;

    this.filterResult.emit(this.filterTicketing);
    this.getListTicket();
  }
  deleteFilter() {
    this.ekspedisi.forEach((item: any) => (item.checked = false));
    this.statusTicket.forEach((item: any) => (item.checked = false));
    this.listTypeTicket.forEach((item: any) => (item.checked = false));

    (this.filterTicketing.start_date =
      moment().subtract(29, 'days').format('YYYY-MM-DD') + ' 00:00:00'),
      (this.filterTicketing.end_date =
        moment().format('YYYY-MM-DD') + ' 23:59:59'),
      (this.filterTicketing.shipping = ''),
      (this.filterTicketing.type_ticket_id = ''),
      (this.filterTicketing.status_ticket = '');

    this.selectedEkspedisi = [];
    this.selectedTypeTicket = [];
    this.selectedStatusTicket = [];

    this.selected = {
      startDate: moment().subtract(6, 'days'),
      endDate: moment(),
    };

    this.filterResult.emit(this.filterTicketing);
    this.getListTicket();
  }
  detail(i: number) {
    window.open(`/ticketing/detail/${i}`, '_blank');
  }
  getUnread(v: string) {
    if (v === 'unread') {
      this.isAll = true;
      this.filterTicketing.unread = true;
      this.filterResult.emit(this.filterTicketing);
      this.getListTicket();
    } else {
      this.isAll = false;
      this.filterTicketing.unread = false;
      this.filterResult.emit(this.filterTicketing);
      this.getListTicket();
    }
  }
  getListTicket() {
    this.ticketService
      .listTicket(this.filterTicketing)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.listOfData = res.data.tickets;
          this.totalTicket = res.data.total_ticket;
          this.totalPage = res.data.total_page;
          this.updatePaginationState()
        },
        () => {}
      );
  }
  getUnreadTicket() {
    this.ticketService
      .countUnread()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.unreadTicket = res.data.unread;
        },
        () => {}
      );
  }

  classStatus(value: string) {
    let result = '';
    if (value === 'Selesai') {
      result = 'text-green';
    } else if (value === 'Sedang Diproses') {
      result = 'process';
    } else if (value === 'Belum Diproses') {
      result = 'process';
    } else if (value === 'Perlu Tindakan Lanjut') {
      result = 'fu';
    }
    return result;
  }
  classStatusOrder(v: string) {
    let result = '';
    if (v === 'Diterima') {
      result = 'text-green';
    } else if (v === 'Dipacking' || v === 'Diajukan' || v === 'Dikirim') {
      result = 'process';
    } else if (
      v === 'Batal' ||
      v === 'Retur' ||
      v === 'Hilang' ||
      v === 'Rusak'
    ) {
      result = 'text-danger';
    }
    return result;
  }
  statusOrderValue(v: string) {
    let result = '';
    if (v === 'Diterima') {
      result = '🙌🏻 finally received';
    } else if (v === 'Dipacking') {
      result = 'Packed';
    } else if (v === 'Diajukan') {
      result = 'Submitted';
    } else if (v === 'Batal') {
      result = 'Canceled';
    } else if (v === 'Retur') {
      result = 'Return';
    } else if (v === 'Hilang') {
      result = 'Lost Package 🙏';
    } else if (v === 'Rusak') {
      result = 'Package Damaged 🙏';
    }
    return result;
  }
  ListTypeTicket(e: string) {
    if (e === 'Alamat/Nomor HP Penerima Tidak Lengkap') {
      return `Recipient's Address/Mobile Number is Incomplete`;
    } else if (e === 'Penerima Tidak Dikenal') {
      return 'Unknown Recipient';
    } else if (e === 'Penerima Tidak Berada Ditempat') {
      return 'Recipient Not at the Location';
    } else if (e === 'Penerima Menolak Paket') {
      return 'Recipient Rejects the Package';
    } else if (e === 'Permintaan Pengiriman Ulang') {
      return 'Resend Request';
    } else if (e === 'Salah Kirim') {
      return 'Wrong Delivery';
    } else if (e === 'Request Antar Ulang dari Pembeli') {
      return 'Re-delivery Request from the Buyer';
    } else if (e === 'Paket Hilang') {
      return 'Lost Package';
    } else if (e === 'Paket Rusak') {
      return 'Damaged Package';
    } else if (e === 'Penerima Tidak Order') {
      return 'Recipient Did Not Order';
    } else if (e === 'Penerima Ambil Paket Sendiri') {
      return 'Recipient Picks Up the Package Himself/Herself';
    } else if (e === 'Kondisi Akses Sangat Sulit') {
      return 'Access Conditions Are Very Difficult';
    } else if (e === 'Paket Kelamaan Tidak Bergerak ') {
      return 'Package Has Not Moved For Too Long';
    } else if (e === 'Paketannya Ditolak Bandara') {
      return 'The Package Was Rejected by the Airport';
    } else if (e === 'Perubahan Alamat Customer') {
      return 'Customer Address Change';
    } else if (e === 'Seller Meminta Agar Paket Ditahan ') {
      return 'Seller Requests Package to be Held';
    } else if (e === 'Permintaan Ganti Alamat Dari Pembeli') {
      return 'Change of Address Request From Buyer';
    } else if (e === 'Nominal Beda') {
      return 'Different Amount';
    } else if (e === 'Ingin Buka Paket') {
      return 'Cusomer Want to Open the Package';
    } else if (e === 'Pengiriman Sedikit Melambat') {
      return 'Delivery Slightly Delayed';
    } else if (e === 'Dibatalkan Seller') {
      return 'Canceled by Seller';
    }
    return e;
  }

  statusTicketName(e: string) {
    if (e === 'Selesai') {
      return 'Done';
    } else if (e === 'Sedang Diproses') {
      return 'Processing';
    } else if (e === 'Belum Diproses') {
      return 'Unprocess';
    } else if (e === 'Perlu Tindakan Lanjut') {
      return 'Further Action Needed';
    } else if (e === 'Dibatalkan') {
      return 'Canceled';
    }
    return e;
  }
  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }
}
