import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpContext } from '@angular/common/http';
import * as moment from 'moment';
import { CryptoService } from './crypto.service';
import { environment as sandEnv } from 'src/environments/environment';
import { BypassInterceptorContext } from '../interceptors/http.interceptor';

@Injectable({
  providedIn: 'root',
})
export class WhatsappService {
  constructor(private httpClient: HttpClient, private cryptoService: CryptoService) {}

  async checkWA(phoneNumber: string): Promise<{ valid: boolean; message: string }> {
    const baseUrl = sandEnv.BASE_URL + '/user/api/v1/user/check-whatsapp';
    const endPoint = `?phone_number=${phoneNumber}`;
    const apiUrl = baseUrl + endPoint;

    const dateTime = moment().format('YYYY-MM-DD HH:mm:ss');
    const signature = await this.cryptoService.hashSHA256HMAC(
      'OpenApiKoMerCe',
      'X-OPEN-API-KOMERCE' + dateTime
    );

    const headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'X-OPEN-API-KOMERCE',
      'Date-Time': dateTime,
      Signature: signature,
    });

    const context = new HttpContext().set(BypassInterceptorContext, true);

    return new Promise((resolve, reject) => {
      this.httpClient.get<any>(apiUrl, { headers, context }).subscribe({
        next: (res) => {
          if (res.data.is_wa === 'valid') {
            resolve({ valid: true, message: 'Number connected with WhatsApp' });
          } else {
            resolve({ valid: false, message: 'Number not connected with WhatsApp' });
          }
        },
        error: (error) => {
          resolve({
            valid: false,
            message: error.error?.data === 'invalid' ? 'Number not connected with WhatsApp' : 'Failed to check WhatsApp number',
          });
        },
      });
    });
  }
}
