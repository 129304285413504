import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as mapboxgl from 'mapbox-gl';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { BypassInterceptorContext } from 'src/app/interceptors/http.interceptor';
import { environment as devEnv } from 'src/environments/environment';
import { debounceTime, distinctUntilChanged, filter, Subject } from 'rxjs';

@Component({
  selector: 'app-modal-map-picker',
  templateUrl: './modal-map-picker.component.html',
  styleUrl: './modal-map-picker.component.scss',
})
export class ModalMapPickerComponent implements OnChanges {
  @Input() isOpenMap: boolean = false;
  @Input() title: string = '';
  @Output() closeModal = new EventEmitter()
  @Output() locationChange = new EventEmitter<any>();
  cityList: any[] = [];
  selectedCity!: string;

  map: mapboxgl.Map | undefined;
  style = 'mapbox://styles/mapbox/streets-v11';
  lat: number = -6.2088;
  lng: number = 106.8456;
  location: string = '';

  constructor(private httpClient: HttpClient) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isOpenMap'] && this.isOpenMap) {
      setTimeout(() => {
        this.initializeMap();
      }, 500);
    }
  }

  initializeMap() {
    if (this.map) {
      this.map.remove();
      this.map = undefined;
    }
  
    this.map = new mapboxgl.Map({
      accessToken: environment.mapbox_token,
      container: 'map',
      style: this.style,
      zoom: 13,
      center: [this.lng, this.lat],
    });
  
    this.createMarker(this.lng, this.lat);
    this.getLocation();
  }
  

  createMarker(lng: number, lat: number) {
    const marker = new mapboxgl.Marker({ draggable: true })
      .setLngLat([lng, lat])
      .addTo(this.map!);

    marker.on('drag', () => {
      const LngLat = marker.getLngLat();
      this.lat = LngLat.lat;
      this.lng = LngLat.lng;

      this.getLocation();
    });

    this.map?.on('click', (event) => {
      const clickedLngLat = event.lngLat;
      this.lat = clickedLngLat.lat;
      this.lng = clickedLngLat.lng;

      marker.setLngLat([this.lng, this.lat]);

      this.getLocation();
    });
  }

  getLocation() {
    const apiMapbox = environment.api_mapbox;

    const params = {
      access_token: environment.mapbox_token,
    };

    const token = localStorage.getItem('auth-token');
    const headers = new HttpHeaders({
      Accept: 'application/pdf',
      Authorization: `Bearer ${token}`,
    });

    const context = new HttpContext().set(BypassInterceptorContext, true);

    this.httpClient
      .get(apiMapbox + this.lng + ',' + this.lat + '.json', {
        headers,
        params,
        context,
      })
      .subscribe((res: any) => {
        this.location = res.features[0]?.place_name || '';
      });
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          this.getLocation();
          this.watchUserLocation();
          this.map = new mapboxgl.Map({
            accessToken: environment.mapbox_token,
            container: 'map',
            style: this.style,
            zoom: 13,
            center: [this.lng, this.lat],
          });
          this.createMarker(this.lng, this.lat)
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.error('User denied the request for Geolocation.');
              break;
            case error.POSITION_UNAVAILABLE:
              console.error('Location information is unavailable.');
              break;
            case error.TIMEOUT:
              console.error('The request to get user location timed out.');
              break;
            default:
              console.error('An unknown error occurred.');
              break;
          }
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }

  watchUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.getLocation();
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }

  handelConfirm() {
    this.locationChange.emit({ location: this.location, lat: this.lat, lng: this.lng });
    this.handleCancel()
  }

  handleCancel() {
    if (this.map) {
      this.map.remove();
      this.map = undefined;
    }
    this.isOpenMap = false
    this.closeModal.emit(this.isOpenMap)
  }

  getCityList(searchTerm: any) {
    if (!searchTerm) return;
    const baseUrl = devEnv.BASE_URL
    const token = localStorage.getItem('auth-token');
    const headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    });

    const context = new HttpContext().set(BypassInterceptorContext, true);

    this.httpClient.get(baseUrl + `/tariff/api/v1/internal/destination/search-destination?keyword=${searchTerm.term}`, { headers, context })
      .subscribe((res: any) => {
        this.cityList = res.data;
      });
  }

  searchLocation(e: any) {
    if (e === null) return;

    this.selectedCity = e;
    const apiMapbox = `https://api.mapbox.com/geocoding/v5/mapbox.places/${this.selectedCity}.json`;
    const params = {
      access_token: environment.mapbox_token,
      limit: '1',
    };

    const token = localStorage.getItem('auth-token');
    const headers = new HttpHeaders({
      Accept: 'application/pdf',
      Authorization: `Bearer ${token}`,
    });

    const context = new HttpContext().set(BypassInterceptorContext, true);
  
    this.httpClient.get(apiMapbox, { headers, params, context }).subscribe((res: any) => {
      if (res.features.length > 0) {
        const [lng, lat] = res.features[0].center;
        this.lat = lat;
        this.lng = lng;
        this.location = res.features[0].place_name;
        this.updateMapLocation();
      }
    });
  }
  
  updateMapLocation() {
    if (this.map) {
      this.map.flyTo({
        center: [this.lng, this.lat],
        essential: true,
        zoom: 13,
      });
      this.getLocation();
      this.createMarker(this.lng, this.lat);
    }
  }
  
}
