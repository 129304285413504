<div class="d-flex flex-column gap-3 mt-2">
  <h3>Dashboard</h3>
  <div class="row mb-2">
    <div class="col-4 col-lg-4 col-sm-12 col-12 mt-2">
      <div class="card shadow card-top">
        <nz-skeleton
          [ngClass]="loading ? 'p-3' : 'p-0'"
          [nzLoading]="loading"
          [nzActive]="true"
        >
          <div class="p-3">
            <div class="d-flex align-items-center gap-1">
              <div class="card-title d-flex align-self-center">Balance</div>
              <div
                class="d-flex align-self-center"
                placement="top left"
                ngbPopover="Your current balance"
                triggers="mouseenter:mouseleave"
              >
                <i class="icon-info-circle align-self-center"></i>
              </div>
            </div>
            <div class="nominal">
              <b>{{ balance.balance | currency : "IDR" : "symbol" : "1.0-0" }}</b>
            </div>
          </div>
          <div
            class="bg-footer-card p-3 d-flex flex-row justify-content-center align-items-center gap-4"
          >
            <button
              class="btn p-0 text-white d-flex align-items-center gap-2"
              (click)="handleTopUp()"
            >
              <i class="icon icon-send-square"></i>
              <div class="d-flex text-card-button">Top Up</div>
            </button>
            <button
              *ngIf="pinStatus === true && bankList !== null"
              class="btn p-0 text-white border-0 d-flex flex-row gap-2 align-items-center justify-content-center"
              [disabled]="isSandBoxMode"
              (click)="handleTarikSaldo($event, 'balance')"
            >
              <div><i class="icon icon-receive-square"></i></div>
              <div class="d-flex text-card-button">Withdrawl</div>
            </button>
            <button
              *ngIf="pinStatus === true && bankList === null"
              class="btn p-0 text-white border-0 d-flex flex-row gap-2 align-items-center justify-content-center"
              [disabled]="isSandBoxMode"
              (click)="handleNoRek($event)"
            >
              <div><i class="icon icon-receive-square"></i></div>
              <div class="d-flex text-card-button">Withdrawl</div>
            </button>
            <button
              *ngIf="pinStatus === false && bankList !== null"
              class="btn p-0 text-white border-0 d-flex flex-row gap-2 align-items-center justify-content-center"
              [disabled]="isSandBoxMode"
              (click)="handleNoPIN($event)"
            >
              <div><i class="icon icon-receive-square"></i></div>
              <div class="d-flex text-card-button">Withdrawl</div>
            </button>
            <button
              *ngIf="pinStatus === false && bankList === null"
              class="btn p-0 text-white border-0 d-flex flex-row gap-2 align-items-center justify-content-center"
              [disabled]="isSandBoxMode"
              (click)="handleNoPIN($event)"
            >
              <div><i class="icon icon-receive-square"></i></div>
              <div class="d-flex text-card-button">Withdrawl</div>
            </button>
            <a [routerLink]="['/finance/balance/detail']">
              <button
                class="d-flex text-white flex-row gap-2 align-items-center justify-content-center btn p-0"
              >
                <div><i class="icon icon-document-text"></i></div>
                <div class="d-flex text-card-button">Detail</div>
              </button>
            </a>
          </div>
        </nz-skeleton>
      </div>
    </div>
    <div class="col-4 col-lg-4 col-sm-12 col-12 mt-2">
      <div class="card shadow card-top">
        <nz-skeleton
          [ngClass]="loading ? 'p-3' : 'p-0'"
          [nzLoading]="loading"
          [nzActive]="true"
        >
          <div class="p-3">
            <div class="d-flex align-items-center gap-1">
              <div class="card-title d-flex align-self-center">
                Potensial Income
              </div>
              <div
                class="d-flex align-self-center"
                placement="top right"
                ngbPopover="Potential Income is the balance you will receive when the order status has changed from sent to received."
                triggers="mouseenter:mouseleave"
              >
                <i class="icon-info-circle align-self-center"></i>
              </div>
            </div>
            <div class="nominal">
              <b>{{
                possibleIncome.possible_income | currency : "IDR" : "symbol" : "1.0-0"
              }}</b>
            </div>
          </div>
          <div class="footer-card-text p-3 d-flex flex-column">
            <div>
              Of this value, <b class="text-primary">Rp 0,-</b> needs to be
              monitored
            </div>
            <div></div>
          </div>
        </nz-skeleton>
      </div>
    </div>
    <div class="col-4 col-lg-4 col-sm-12 col-12 mt-2">
      <div class="card shadow card-top">
        <nz-skeleton
          [ngClass]="loading ? 'p-3' : 'p-0'"
          [nzLoading]="loading"
          [nzActive]="true"
        >
          <div class="p-3">
            <div class="d-flex align-items-center gap-1">
              <div class="card-title d-flex align-self-center">Commission</div>
              <div
                class="d-flex align-self-center"
                placement="top right"
                ngbPopover="Commission is calculated at 1% of total shipping costs"
                triggers="mouseenter:mouseleave"
              >
                <i class="icon-info-circle align-self-center"></i>
              </div>
            </div>
            <div class="nominal">
              <b>{{ balance.commission | currency : "IDR" : "symbol" : "1.0-0" }}</b>
            </div>
          </div>
          <div
            class="bg-footer-card p-3 d-flex flex-row justify-content-center"
          >
            <button
              *ngIf="pinStatus === true && bankList !== null"
              class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
              style="border: none; background-color: transparent"
              [disabled]="isSandBoxMode"
              (click)="handleTarikKomisi($event, 'commission')"
            >
              <div><i class="icon icon-receive-square"></i></div>
              <div class="d-flex text-card-button">Withdrawal</div>
            </button>
            <button
              *ngIf="pinStatus === true && bankList === null"
              class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
              style="border: none; background-color: transparent"
              [disabled]="isSandBoxMode"
              (click)="handleNoRek($event)"
            >
              <div><i class="icon icon-receive-square"></i></div>
              <div class="d-flex text-card-button">Withdrawal</div>
            </button>
            <button
              *ngIf="pinStatus === false && bankList !== null"
              class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
              style="border: none; background-color: transparent"
              [disabled]="isSandBoxMode"
              (click)="handleNoPIN($event)"
            >
              <div><i class="icon icon-receive-square"></i></div>
              <div class="d-flex text-card-button">Withdrawal</div>
            </button>
            <button
              *ngIf="pinStatus === false && bankList === null"
              class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
              style="border: none; background-color: transparent"
              [disabled]="isSandBoxMode"
              (click)="handleNoPIN($event)"
            >
              <div><i class="icon icon-receive-square"></i></div>
              <div class="d-flex text-card-button">Withdrawal</div>
            </button>
            <a [routerLink]="['/finance/commission/detail']">
              <button
                class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
                style="border: none; background-color: transparent"
              >
                <div><i class="icon icon-document-text"></i></div>
                <div class="d-flex text-card-button">Detail</div>
              </button>
            </a>
          </div>
        </nz-skeleton>
      </div>
    </div>
  </div>
  <div class="card shadow">
    <nz-skeleton
      [ngClass]="loading ? 'p-3' : 'p-0'"
      [nzLoading]="loading"
      [nzActive]="true"
    >
    <div class="d-flex p-4 pb-0 justify-content-between align-items-center">
      <h4 class="text-black">Order Dashboard</h4>
      <div>
        <form class="row row-cols-sm-auto">
          <div class="col-12">
            <div
              class="form-control d-flex flex-row"
              (click)="openDatepicker()"
            >
              <input
                type="text"
                class="btn p-0 w-100"
                ngxDaterangepickerMd
                [(ngModel)]="selected"
                [ngModelOptions]="{ standalone: true }"
                [showCustomRangeLabel]="false"
                [keepCalendarOpeningWithRange]="true"
                [alwaysShowCalendars]="true"
                [ranges]="ranges"
                [linkedCalendars]="true"
                [isInvalidDate]="isInvalidDate"
                [showCancel]="true"
                opens="left"
                drops="down"
                [locale]="setlocale"
                (change)="onChangeDate($event)"
                style="border: none; font-size: 14px"
                #datePickerInput
              />
              <button
                class="btn ngx-daterangepicker-action"
                (click)="openDatepicker()"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/calendar.svg"
                  alt=""
                />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <hr>
      <div class="d-flex flex-column flex-lg-row gap-5 col-12 col-md-auto p-4 card-ov">
        <div *ngFor="let status of orderStatuses; let i = index" class="card border-radius col-12 col-md-auto col-lg-3 p-4 {{ status.class }}">
          <div class="label">{{ status.label }}</div>
          <div *ngIf="status.percentageKey" class="text-white percent-deliver mt-4">
            {{ accumulationOrder?.[status.key]?.percentage | number }}%
          </div>
          <span class="text-white mt-4">{{ status.description }}</span>
          <div class="text-white text-nominal">
            <b *ngIf="i !== 0">{{ accumulationOrder?.[status.key]?.total_order || 0 }}</b>
            <b *ngIf="i === 0">{{ accumulationOrder?.total_order || 0 }}</b>
          </div>
          <span class="text-white mt-4">Total COD Amount</span>
          <div class="text-white text-nominal">
            <b *ngIf="i !== 0">{{
              (accumulationOrder?.[status.key]?.total_cod | currency : "IDR" : "symbol" : "1.0-0") || 0
            }}</b>
            <b *ngIf="i === 0">{{
              (accumulationOrder?.total_cod | currency : "IDR" : "symbol" : "1.0-0") || 0
            }}</b>
          </div>
        </div>
      </div>
    </nz-skeleton>
  </div>
</div>
