import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-register-success',
  templateUrl: './modal-register-success.component.html',
  styleUrls: ['./modal-register-success.component.scss'],
})
export class ModalRegisterSuccessComponent implements OnInit {
  constructor(private modal: NzModalRef, private route: Router) {}

  destroyModal(): void {
    this.modal.destroy();
    this.route.navigate(['/login']);
  }
  ngOnInit(): void {
  }
}
