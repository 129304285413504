<div class="card p-3 d-flex flex-column gap-3 data-order mt-2"
  [ngStyle]="{'height': totalOrder < 5 ? '850px' : 'auto'}">
  <nz-skeleton [ngClass]="loading ? 'p-3' : 'p-0'" [nzLoading]="loading" [nzActive]="true">
    <div class="d-flex flex-column flex-lg-row justify-content-between mb-3">
      <div class="title d-flex">Order Data</div>
      <div class="d-flex">
        <button class="btn btn-outline-primary download d-flex align-items-center"
          (click)="download()">Download</button>
      </div>
    </div>
    <div class="d-flex flex-column flex-lg-row gap-3 justify-content-between align-items-center mb-3">
      <div class="total-order d-flex flex-column border rounded col-4 py-3 px-4">
        <span class="d-flex align-items-center gap-3 text-secondary">
          <span style="background-color: #DFF3FF; width: 30px; height: 30px;" class="d-flex align-items-center rounded justify-content-center">
            <img src="https://storage.googleapis.com/komerce/icon/bag-blue.svg" alt="bag">
          </span>
          Order Quantity
        </span>
        <span class="title mt-1">
          {{ totalOrder || 0 }}
        </span>
      </div>
      <div class="d-flex flex-row gap-2 align-items-center">
          <div class="form-group has-search">
            <span class="form-control-feedback"><i class="icon-search-normal-1"></i></span>
            <input [(ngModel)]="filterOrder.awb" (ngModelChange)="onChangeSearch($event)" type="text"
              class="form-control" placeholder="Receipt Number">
          </div>
        <div>
          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-primary btn-filter" id="dropdownfilterorder" ngbDropdownToggle>
              <i class="icon-filter-search text-white"></i>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownfilterorder">
              <button ngbDropdownItem disabled class="text-black">
                <b>Filter</b>
              </button>
              <div ngbDropdown placement="left" class="d-inline-block w-100">
                <button type="button" class="btn w-100" id="dropdownstatus" style="text-align: start;"
                  ngbDropdownToggle>
                  Status
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownstatus">
                  <button class="btn w-100" style="text-align: start;">
                    <div class="form-check" *ngFor="let list of status; let i = index">
                      <input class="form-check-input" type="checkbox" (value)="list.value" (id)="list.id"
                        (change)="getStatus($event, list)" [checked]="list.checked">
                      <label class="form-check-label" [for]="list.id">
                        {{list.label}}
                      </label>
                    </div>
                  </button>
                </div>
                <button type="button" class="btn w-100 ngx-daterangepicker-action" id="dropdownstatus"
                  style="text-align: start;" (click)="openDatepicker()">
                  Period
                </button>
                <div id="dropdownstatus">
                  <input type="text" class="btn w-100" ngxDaterangepickerMd [(ngModel)]="selected"
                    [ngModelOptions]="{standalone: true}" [showCustomRangeLabel]="false"
                    [keepCalendarOpeningWithRange]="true" [alwaysShowCalendars]="true" [ranges]="ranges"
                    [linkedCalendars]="true" [isInvalidDate]="isInvalidDate" [showCancel]="true" opens="center"
                    drops="down" [locale]="setlocale" (change)="onChangeDate($event)"
                    style="border: none; font-size: 14px; display: none;" #datePickerInput />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-table-data-order [dataOrder]="dataOrder" [loadData]="loadData" />
    <app-pagination
      [currentPage]="filterOrder.page"
      [totalItems]="totalOrder"
      [perPage]="filterOrder.per_page"
      [disablePrev]="isDisPrev"
      [disableNext]="isDisNext"
      [perPageOptions]="[20, 50]"
      (pageChange)="onPageChange($event)"
      (perPageChange)="onPerPageChange($event)"
      (itemsPerPageChange)="getListPage($event)">
    </app-pagination>
  </nz-skeleton>
</div>
