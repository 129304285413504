<div class="card p-4 d-flex flex-column mt-2">
  <div class="py-1 title">Integration</div>
  <div class="row mb-2 mt-4">
    <div class="col-4 col-lg-4 col-sm-12 col-12 mt-2">
      <nz-skeleton
        [ngClass]="skeleton ? 'p-3' : 'p-0'"
        [nzLoading]="skeleton"
        [nzActive]="true"
      >
        <div class="card border">
          <div class="card-body">
            <div
              class="card-title mb-3 fw-semibold d-flex justify-content-between"
            >
              <h4>Woocommerce</h4>
              <img
                src="https://storage.googleapis.com/komerce/rajaongkir/Woo_logo_color.svg"
                alt="woo"
              />
            </div>
            <p class="card-text">
              Connect your Woocommerce store with RajaOngkir, download directly
              here. For download and installation guidance,
              <a href="https://komerceapi.readme.io/reference/woocommerce" target="_blank">you can access it here</a>
            </p>
            <a
              href="https://storage.googleapis.com/komerce/rajaongkir/rajaongkir-official.zip"
              target="_parent"
            >
              <button
                nz-button
                nzDanger
                nzType="primary"
                class="rounded w-100 fw-semibold py-1"
              >
                Download Now
              </button>
            </a>
          </div>
        </div>
      </nz-skeleton>
    </div>
  </div>
</div>