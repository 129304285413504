import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject, takeUntil } from 'rxjs';
import { CALCULATE_DESTINATION } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-instant-rates',
  standalone: false,
  templateUrl: './instant-rates.component.html',
  styleUrl: './instant-rates.component.scss'
})
export class InstantRatesComponent {
  $destroy = new Subject
  instantForm: FormGroup = new FormGroup({
      originCity: new FormControl(null, [Validators.required]),
      destinationCity: new FormControl(null, [Validators.required]),
      packageWeight: new FormControl(null, [Validators.required]),
      itemValue: new FormControl(null, [Validators.required]),
      packageVolume1: new FormControl(null),
      packageVolume2: new FormControl(null),
      packageVolume3: new FormControl(null),
    })
  openOriginMap: boolean = false
  titleModal: string = 'Origin'
  loading: boolean = false
  listCalculate: any[] = []
  latOrigin: number = 0;
  lngOrigin: number = 0;
  latDestination: number = 0;
  lngDestination: number = 0;

  constructor(
    private baseService: BaseService,
    private message: NzMessageService
  ) { }

  openMapOrigin(title: 'Origin' | 'Destination') {
    this.openOriginMap = true
    this.titleModal = title
  }

  closeMapOrigin() {
    this.openOriginMap = false
  }

  onLocationChange(detail: any) {
    if (this.titleModal === 'Origin') {
      this.instantForm.patchValue({ originCity: detail.location });
      this.latOrigin = detail.lat;
      this.lngOrigin = detail.lng;
    } else {
      this.instantForm.patchValue({ destinationCity: detail.location });
      this.latDestination = detail.lat;
      this.lngDestination = detail.lng;
    }
  }
  resetValue() {
    this.instantForm.reset();
    this.listCalculate = [];
  }

  calculateDestination() {
      this.loading = true;
      const params = {
        origin_pin_point: this.latOrigin + ',' + this.lngOrigin,
        destination_pin_point: this.latDestination + ',' + this.lngDestination,
        weight: this.instantForm.value.packageWeight / 1000,
        length: this.instantForm.value.packageVolume1,
        width: this.instantForm.value.packageVolume2,
        height: this.instantForm.value.packageVolume3,
        item_value: this.instantForm.value.itemValue
      }
      this.baseService.getData(CALCULATE_DESTINATION, params)
        .pipe(takeUntil(this.$destroy))
        .subscribe((res: any) => {
          this.listCalculate = res.data.calculate_instant
          this.loading = false;

          if (res.data.calculate_instant.length === 0) {
            this.message.error('Rates Data not found');
          }
        }, (err) => {
          this.loading = false;
          this.message.error(err.error.message);
        })
    }
}
