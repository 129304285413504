import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  active: boolean = true;
  private destroy$ = new Subject<void>();

  constructor(
    public baseService: BaseService,
  ) {}

  ngOnInit(): void {}

  collapseToogle(e: boolean): void {
    this.active = e;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: EventTarget): void {
    if ((target as HTMLElement).className === 'layer') {
      this.active = false;
    }
  }

  isActive(e: boolean): void {
    this.active = e;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
