<div class="card mt-2">
  <div class="card-header">Finance</div>
  <div class="card-body">
    <div>
      <nz-skeleton class="d-flex" [nzLoading]="loading" [nzActive]="true" [nzParagraph]="false">
        <ul class="nav gap-3">
          <li class="nav-item">
            <a class="nav-link" routerLink="/finance/balance">Balance History</a>
          </li>
          <li class="nav-item active">
            <a class="nav-link active" aria-current="page"
              >Commission Withdrawal</a
            >
          </li>
        </ul>
      </nz-skeleton>
    </div>
    <div class="mt-5 mb-3 row">
      <div class="col-xl-5 col">
        <nz-skeleton class="d-flex col-xl-5" [nzLoading]="loading" [nzActive]="true">
          <div class="card shadow">
            <div class="p-3">
              <div class="d-flex align-items-center gap-1">
                <div class="card-title d-flex align-self-center">Commission</div>
                <div
                  class="d-flex align-self-center"
                  placement="top"
                  ngbPopover="Your current commission"
                  triggers="mouseenter:mouseleave"
                >
                  <i class="icon-info-circle align-self-center"></i>
                </div>
              </div>
              <div class="nominal">
                <b>{{ (balance.commission || 0) | idr }}</b>
              </div>
            </div>
            <div
              class="bg-footer-card p-3 d-flex flex-row justify-content-center"
            >
              <button
                *ngIf="pinStatus === true && bankList !== null"
                class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
                style="border: none; background-color: transparent"
                [disabled]="isSandBoxMode"
                (click)="handleTarikSaldo($event)"
              >
                <div><i class="icon icon-receive-square"></i></div>
                <div class="d-flex text-card-button">Withdrawal</div>
              </button>
              <button
                *ngIf="pinStatus === true && bankList === null"
                class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
                style="border: none; background-color: transparent"
                [disabled]="isSandBoxMode"
                (click)="handleNoRek($event)"
              >
                <div><i class="icon icon-receive-square"></i></div>
                <div class="d-flex text-card-button">Withdrawal</div>
              </button>
              <button
                *ngIf="pinStatus === false && bankList !== null"
                class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
                style="border: none; background-color: transparent"
                [disabled]="isSandBoxMode"
                (click)="handleNoPIN($event)"
              >
                <div><i class="icon icon-receive-square"></i></div>
                <div class="d-flex text-card-button">Withdrawal</div>
              </button>
              <button
                *ngIf="pinStatus === false && bankList === null"
                class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
                style="border: none; background-color: transparent"
                [disabled]="isSandBoxMode"
                (click)="handleNoPIN($event)"
              >
                <div><i class="icon icon-receive-square"></i></div>
                <div class="d-flex text-card-button">Withdrawal</div>
              </button>
              <div
                class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
                (click)="handleDetail($event)"
                style="cursor: pointer"
              >
                <div><i class="icon icon-document-text"></i></div>
                <div class="d-flex text-card-button">Detail</div>
              </div>
            </div>
          </div>
        </nz-skeleton>
      </div>
    </div>
    <div class="divider"></div>
    <nz-skeleton class="d-flex col-12 mt-3" [nzLoading]="loading" [nzActive]="true">
      <div class="mt-3 col-12">
          <app-table-riwayat-penarikan-saldo />
        </div>
      </nz-skeleton>
  </div>
</div>
