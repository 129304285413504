<div class="card p-4 d-flex flex-column mt-2">
    <nz-skeleton
      [ngClass]="loading ? 'p-3' : 'p-0'"
      [nzLoading]="loading"
      [nzActive]="true"
      [nzParagraph]="true"
    >
      <div class="py-1 title">Rates</div>

      <div class="d-flex flex-column flex-lg-row mt-5">
        <button
          class="btn d-flex justify-content-center gap-2 align-items-center"
          [ngClass]="isRegular ? 'active-tab' : 'nonactive-tab'"
          style="width: 300px;"
          (click)="moveTab('regular')"
        >
          <img
            [src]="
              isRegular
                ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/truck.svg'
                : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/truck.svg'
            "
            alt="general"
          />
          Regular
        </button>
        <button
          class="btn d-flex justify-content-center gap-2 align-items-center"
          [ngClass]="isInstant ? 'active-tab' : 'nonactive-tab'"
          style="width: 300px;"
          (click)="moveTab('instant')"
        >
          <img
            [src]="
              isInstant
                ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/truck-fast.svg'
                : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/truck-fast.svg'
            "
            alt="api_key"
          />
          Instant & Same Day
        </button>
      </div>
      <div *ngIf="isRegular">
        <app-regular-rates />
      </div>
      <div *ngIf="isInstant">
        <app-instant-rates />
      </div>
    </nz-skeleton>
</div>
