<form [formGroup]="printForm">
  <div class="d-flex flex-column p-4">
    <h6 class="text-bold">Print Label</h6>
    <hr />
    <div class="mt-3 table-responsive">
      <table class="table">
        <thead class="bg-light">
          <tr>
            <th>Name</th>
            <th>Input</th>
            <th>Expected Result</th>
            <th>cURL</th>
            <th>Result</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of tableData">
            <td class="text-grey col-2" [innerHTML]="row.name || '-'"></td>
            <td class="text-grey col-2 fst-italic" [innerHTML]="row.input || '-'"></td>
            <td class="text-grey col-2 fira-code" [innerHTML]="row.expected_result || '-'"></td>
            <td class="col-2">
              <textarea formControlName="curl" nz-input placeholder="-" nzAutosize></textarea>
            </td>
            <td class="col-2">
              <textarea formControlName="result" nz-input placeholder="-" nzAutosize></textarea>
            </td>
            <td class="col-2">{{ row.notes || '-' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex align-self-end gap-2 py-3">
      <button class="btn btn-outline-secondary" (click)="previus()">Back</button>
      <button class="btn text-white" [disabled]="printForm.invalid" [ngClass]="{
        'btn-primary': printForm.valid,
        'btn-secondary': !printForm.valid
      }" (click)="next()">Next</button>
    </div>
  </div>
</form>
