import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { Profile } from 'src/app/models/profile.model';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ProfileService } from 'src/app/services/profile.service';
import { ModeSwitchService } from 'src/app/services/mode-switch.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { BypassInterceptorContext } from 'src/app/interceptors/http.interceptor';
import { environment as prodEnv } from 'src/environments/environment.prod';
import { environment as sandEnv } from 'src/environments/environment.sandbox';
import { environment as devEnv } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() active: boolean = true;
  @Output() collapseToogle = new EventEmitter();
  destroy$ = new Subject<void>();
  detailProfile!: Profile;
  private profileSubscription!: Subscription;
  profileMode: boolean = false;
  baseUrl!: string;
  envName!: string;
  apiUrl!: string;
  nextEnv!: string;
  currentEnv!: string;
  currentMode = 'development';
  activeUrl = '';
  isActiveSwitchMode: boolean = true;
  openModalAlertNumber: boolean = false;
  openModalAlertSwitch: boolean = false;
  @Output() openModalAlertSwitchEvent = new EventEmitter<boolean>();

  constructor(
    private storageService: LocalStorageService,
    private router: Router,
    private profileService: ProfileService,
    private message: NzMessageService,
    private modeSwitchService: ModeSwitchService,
    private httpClient: HttpClient,
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const url = val.url || '';
        this.activeUrl = url
      }
    });
  }

  ngOnInit(): void {
    this.profileSubscription = this.profileService.profileData$.pipe(takeUntil(this.destroy$)).subscribe(
      (data) => {
        if (data) {
          this.detailProfile = data;
          if (this.detailProfile.phone_number === '') {
            this.openModalAlertNumber = true;
          }
        }
      },
      (error) => {
        console.error('Error fetching profile data:', error);
      }
    );
    this.modeSwitchService.mode$.pipe(takeUntil(this.destroy$)).subscribe((config) => {
      this.currentMode = config.mode;
      environment.BASE_URL = config.BASE_URL
      environment.RECAPTCHA_TOKEN = config.RECAPTCHA_TOKEN
      this.profileService.getProfile();
    });

    const currentMode = localStorage.getItem('mode') || 'development'
    this.currentMode = currentMode
    if (currentMode === 'development') {
      this.profileMode = false
      this.modeSwitchService.switchMode('development');
    } else {
      this.profileMode = true
      this.modeSwitchService.switchMode('sandbox');
    }
  }

  switchMode() {
    this.openModalAlertSwitch = true;
  }
  closeSwitchMode() {
    this.openModalAlertSwitch = false;
    this.currentMode = localStorage.getItem('mode') || 'development'
    this.profileMode = false
  }

  toggleEnvironment() {
      const baseUrl =
        this.currentMode === 'development'
          ? sandEnv.BASE_URL
          : devEnv.BASE_URL;
      const endPoint = '/user/api/v1/user/check-api-key';
      const apiUrl = baseUrl + endPoint;

      const token = localStorage.getItem('auth-token');
      const currentOrigin = window.location.origin;

      const headers = new HttpHeaders({
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      });

      const context = new HttpContext().set(BypassInterceptorContext, true);

      this.httpClient.get<any>(apiUrl, { headers, context }).subscribe({
        next: (res) => {
          if (res.data.is_active) {
            const saveMode = this.profileMode ? 'sandbox' : 'development';
            const newMode = this.currentMode === 'development' ? 'sandbox' : 'development';
            this.message.create(
              'success',
              `Your account has successfully switched to ${this.profileMode ? 'Live' : 'Sandbox'} Mode`
            );
            localStorage.setItem('mode', newMode);
            this.modeSwitchService.switchMode(saveMode);
            if (this.activeUrl.startsWith('/order-data')) {
              this.router.navigate(['/order-data'])
            } else if(this.activeUrl.startsWith('/finance/balance')) {
              this.router.navigate(['/finance/balance'])
            } else if(this.activeUrl.startsWith('/finance/commission')) {
              this.router.navigate(['/finance/commission'])
            } else if(this.activeUrl.startsWith('/ticketing')) {
              this.router.navigate(['/ticketing'])
            } else if(this.activeUrl.startsWith('/billings')) {
              this.router.navigate(['/dashboard'])
            }
            setTimeout(() => window.location.reload(), 500);
          } else {
            this.message.create(
              'warning',
              'Oops! Live mode isn’t available. Contact support for Live mode access'
            );
            this.profileMode = false;
            this.openModalAlertSwitch = true;
          }
        },
        error: (error) => {
          console.error('CORS Error:', error);
          this.message.create('error', 'Failed to switch modes. Please try again later.');
        },
      });
  }


  collapseClick(): void {
    this.active = !this.active;
    this.collapseToogle.emit(this.active);
  }

  logout(): void {
    this.storageService.signOut();
    window.location.reload();
  }

  ngOnDestroy(): void {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
