import { Component } from '@angular/core';

@Component({
  selector: 'app-track',
  standalone: false,
  templateUrl: './track.component.html',
  styleUrl: './track.component.scss'
})
export class TrackComponent {

}
