<div class="d-flex flex-column p-4" [formGroup]="businessForm">
  <h6 class="text-bold">Business Questionnaire</h6>
  <hr />
  <div class="d-flex flex-column gap-3">
    <div class="d-flex gap-2">
      <span>Does your company have a business entity deed?</span>
      <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
    </div>
    <div class="d-flex gap-2">
      <nz-radio-group formControlName="has_business_entity_deed">
        <label nz-radio [nzValue]="true">Yes</label>
        <label nz-radio [nzValue]="false">No</label>
      </nz-radio-group>
    </div>
  </div>

  <!-- Repeat for SPPKP -->
  <div class="d-flex flex-column gap-3 mt-3">
    <div class="d-flex gap-2">
      <span>Does your company have an Certificate of Taxable Entrepreneur (SPPKP)?</span>
      <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
    </div>
    <div class="d-flex flex-column flex-lg-row gap-2 col-12 col-lg-4" [ngClass]="validTax ? 'align-items-center':''">
      <nz-radio-group
        formControlName="has_taxable_entrepreneur_certificate"
        class="d-flex"
        [ngClass]="validTax ? '':'mt-1'"
      >
        <label nz-radio [nzValue]="true">Yes</label>
        <label nz-radio [nzValue]="false">No</label>
      </nz-radio-group>
      <div class="d-flex flex-column col-lg-10 col-12">
        <input
          nz-input
          placeholder="e.g : 873.8238.738278.9723"
          formControlName="taxable_certificate_number"
          type="number"
          maxlength="15"
          minlength="15"
          (keypress)="preventNonNumericTax($event)"
          [nzStatus]="validTax ? '' : 'error'"
          (input)="validateTax($event)"
        />
        <span class="text-danger">
          <img
            *ngIf="errMessTax !== '' && businessForm.get('has_taxable_entrepreneur_certificate')?.value"
            src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
            alt="warning"
            style="max-width: 10px; max-height: 10px"
          />
          {{ errMessTax }}
        </span>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column gap-3 mt-3">
    <div class="d-flex gap-2">
      <span>Does your company have a Taxpayer Identification Number (NPWP)?</span>
      <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
    </div>
    <div class="d-flex flex-column flex-lg-row gap-2 col-12 col-lg-4" [ngClass]="validNPWP ? 'align-items-center':''">
      <nz-radio-group
        formControlName="has_taxpayer_identification_number"
        class="d-flex"
        [ngClass]="validNPWP ? '':'mt-1'"
      >
        <label nz-radio [nzValue]="true">Yes</label>
        <label nz-radio [nzValue]="false">No</label>
      </nz-radio-group>
      <div class="d-flex flex-column col-lg-10 col-12">
        <input
          nz-input
          placeholder="e.g : 873.8238.738278.9723"
          formControlName="taxpayer_number"
          type="number"
          maxlength="15"
          minlength="15"
          (keypress)="preventNonNumericTax($event)"
          [nzStatus]="validNPWP ? '' : 'error'"
          (input)="validateNPWP($event)"
        />
        <span class="text-danger">
          <img
            *ngIf="errMessNPWP !== '' && businessForm.get('has_taxpayer_identification_number')?.value"
            src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
            alt="warning"
            style="max-width: 10px; max-height: 10px"
          />
          {{ errMessNPWP }}
        </span>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column gap-3 mt-3">
    <div class="d-flex gap-2">
      <span>Does your company have a Business Identification Number (NIB)?</span>
      <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
    </div>
    <div class="d-flex flex-column flex-lg-row gap-2 col-12 col-lg-4" [ngClass]="validNIB ? 'align-items-center':''">
      <nz-radio-group
        formControlName="has_business_identification_number"
        class="d-flex"
        [ngClass]="validNIB ? '':'mt-1'"
      >
        <label nz-radio [nzValue]="true">Yes</label>
        <label nz-radio [nzValue]="false">No</label>
      </nz-radio-group>
      <div class="d-flex flex-column col-lg-10 col-12">
        <input
          nz-input
          placeholder="e.g : 873.8238.738278.9723"
          formControlName="business_identification_number"
          type="number"
          maxlength="13"
          minlength="13"
          (keypress)="preventNonNumericTax($event)"
          [nzStatus]="validNIB ? '' : 'error'"
          (input)="validateNIB($event)"
        />
        <span class="text-danger">
          <img
            *ngIf="errMessNIB !== '' && businessForm.get('has_business_identification_number')?.value"
            src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
            alt="warning"
            style="max-width: 10px; max-height: 10px"
          />
          {{ errMessNIB }}
        </span>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column gap-3 mt-3">
    <div class="d-flex gap-2">
      <span>Is your company submitting the Service Level Agreement under a power of attorney?</span>
      <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
    </div>
    <div class="d-flex flex-column flex-lg-row gap-2 col-12 col-lg-4" [ngClass]="validNIB ? 'align-items-center':''">
      <nz-radio-group
        formControlName="is_sla_under_power_of_attorney"
        class="d-flex"
        [ngClass]="validNIB ? '':'mt-1'"
      >
        <label nz-radio [nzValue]="true">Yes</label>
        <label nz-radio [nzValue]="false">No</label>
      </nz-radio-group>
    </div>
  </div>
  <div class="d-flex flex-column gap-3 mt-3">
    <div class="d-flex gap-2">
      <span>Does the person granted the power of attorney have a KTP (Indonesian ID card) or KITAS (Temporary Stay Permit)?</span>
      <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
    </div>
    <div class="d-flex flex-column flex-lg-row gap-2 col-12 col-lg-4" [ngClass]="validNIB ? 'align-items-center':''">
      <nz-radio-group
        formControlName="has_authorization_ktp_or_kitas"
        class="d-flex"
        [ngClass]="validNIB ? '':'mt-1'"
      >
        <label nz-radio [nzValue]="true">Yes</label>
        <label nz-radio [nzValue]="false">No</label>
      </nz-radio-group>
    </div>
  </div>

  <div class="d-flex flex-column flex-lg-row gap-3 mt-5">
    <div class="d-flex flex-column gap-2 col">
      <div class="d-flex gap-2">
        <span
          >Name of Authorization Holder<span class="text-danger">*</span></span
        >
        <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
      </div>
      <input
        nz-input
        formControlName="authorization_holder_name"
        placeholder="e.g John Doe"
        type="text"
        (keypress)="setRegexBName($event)"
        (input)="validateInputField($event, 'BName')"
        [nzStatus]="validBName ? '' : 'error'"
      />
      <span class="text-danger">
        <img
          *ngIf="errMessBName !== ''"
          src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
          alt="warning"
          style="max-width: 10px; max-height: 10px"
        />
        {{ errMessBName }}</span
      >
      <div class="d-flex gap-2 mt-4">
        <span
          >Email of Authorization Holder<span class="text-danger">*</span></span
        >
        <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
      </div>
      <input
        nz-input
        formControlName="authorization_holder_email"
        placeholder="e.g example@mail.com"
        type="email"
        (keypress)="setRegex($event)"
        (keydown.enter)="$event.preventDefault()"
        (input)="validateEmail($event)"
        [nzStatus]="validEmail ? '' : 'error'"
      />
      <span class="text-danger">
        <img
          *ngIf="errMessEmail !== ''"
          src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
          alt="warning"
          style="max-width: 10px; max-height: 10px"
        />
        {{ errMessEmail }}</span
      >
    </div>

    <div class="d-flex flex-column gap-2 col">
      <div class="d-flex gap-2">
        <span
          >Job Title of Authorization Holder<span class="text-danger"
            >*</span
          ></span
        >
        <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
      </div>
      <input
        nz-input
        formControlName="authorization_holder_job_title"
        placeholder="e.g Manager"
        type="text"
        (keypress)="setRegexBName($event)"
        (input)="validateInputField($event, 'JName')"
        [nzStatus]="validJName ? '' : 'error'"
      />
      <span class="text-danger">
        <img
          *ngIf="errMessJName !== ''"
          src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
          alt="warning"
          style="max-width: 10px; max-height: 10px"
        />
        {{ errMessJName }}</span
      >
      <div class="d-flex gap-2 mt-4">
        <span
          >Address of Authorization Holder<span class="text-danger"
            >*</span
          ></span
        >
        <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
      </div>
      <nz-textarea-count [nzMaxCharacterCount]="100">
        <textarea
          nz-input
          rows="2"
          formControlName="authorization_holder_address"
          placeholder="e.g Jl.Jend. Soedirman, Jakarta Pusat, 55555"
          (keydown.enter)="$event.preventDefault()"
          (input)="validateAddress($event)"
          [nzStatus]="validAddress ? '' : 'error'"
        ></textarea>
      </nz-textarea-count>
      <span class="text-danger">
        <img
          *ngIf="errMessAddress !== ''"
          src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
          alt="warning"
          style="max-width: 10px; max-height: 10px"
        />
        {{ errMessAddress }}</span
      >
    </div>

    <div class="d-flex flex-column gap-2 col">
      <div class="d-flex gap-2">
        <span
          >Phone Number of Authorization Holder<span class="text-danger"
            >*</span
          ></span
        >
        <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
      </div>
      <input
        nz-input
        formControlName="authorization_holder_phone"
        placeholder="e.g +62 812 3456 7890"
        type="text"
        (keypress)="preventNonNumeric($event)"
        (input)="validateInput($event)"
        [nzStatus]="validNumber ? '' : 'error'"
      />
      <span class="d-flex align-items-center gap-1 text-danger">
        <img
          *ngIf="errMessNumber !== ''"
          src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
          alt="warning"
          style="max-width: 10px; max-height: 10px"
        />
        {{ errMessNumber }}</span
      >
    </div>
  </div>

  <div class="d-flex align-self-end gap-2 mt-5">
    <button class="btn btn-outline-secondary" (click)="onBack()">Back</button>
    <button
      type="submit"
      class="btn text-white"
      [disabled]="isFormInvalid"
      [ngClass]="{
        'btn-secondary': isFormInvalid,
        'btn-primary': !isFormInvalid
      }"
      (click)="onSubmit()"
    >
      Next
    </button>
  </div>
</div>
