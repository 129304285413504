<div class="mt-5">
    <nz-table #basicTable [nzShowPagination]="false" [nzData]="listReguler">
        <thead>
          <tr>
            <th>Courier</th>
            <th>Price</th>
            <th>Estimated</th>
            <th>COD</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let data of listReguler; let i = index">
              <tr>
                <td>
                  <div class="d-flex gap-3 align-items-center">
                    <img [src]="generateIcon(data.shipping_name)" alt="courier" width="40px">
                    {{ data.shipping_name }} - {{ data.service_name }}
                  </div>
                </td>
                <td>
                  <div class="d-flex flex-column">
                    <span>
                      {{ data.shipping_cost_net | idr }}
                    </span>
                    <small class="text-danger text-decoration-line-through">
                      {{ data.shipping_cost | idr }}
                    </small>
                  </div>
                </td>
                <td>{{ data.etd }}</td>
                <td>
                  <span *ngIf="data.is_cod" class="support">
                    <img src="https://storage.googleapis.com/komerce/icon/tick-circle-success.svg" alt="support">
                    Support</span>
                  <span *ngIf="!data.is_cod" class="not-support">
                    <img src="https://storage.googleapis.com/komerce/icon/close-circle-error.svg" alt="not-support">
                    Not Supported</span>
                </td>
              </tr>
          </ng-container>
          <ng-container *ngFor="let data of listCargo; let i = index">
              <tr>
                <td>
                  <div class="d-flex gap-3 align-items-center">
                    <img [src]="generateIcon(data.shipping_name)" alt="courier" width="40px">
                    {{ data.shipping_name }} - {{ data.service_name }}
                  </div>
                </td>
                <td>
                  <div class="d-flex flex-column">
                    <span>
                      {{ data.shipping_cost_net | idr }}
                    </span>
                    <small class="text-danger text-decoration-line-through">
                      {{ data.shipping_cost | idr }}
                    </small>
                  </div>
                </td>
                <td>{{ data.etd }}</td>
                <td>
                  <span *ngIf="data.is_cod" class="support">
                    <img src="https://storage.googleapis.com/komerce/icon/tick-circle-success.svg" alt="support">
                    Support</span>
                  <span *ngIf="!data.is_cod" class="not-support">
                    <img src="https://storage.googleapis.com/komerce/icon/close-circle-error.svg" alt="not-support">
                    Not Supported</span>
                </td>
              </tr>
          </ng-container>
        </tbody>
      </nz-table>
</div>