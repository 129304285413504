<form [formGroup]="businessForm">
  <div class="d-flex flex-column p-4">
    <h6 class="text-bold">Business Information</h6>
    <hr />
    <div class="d-flex flex-column gap-3">
      <div class="d-flex gap-2">
        <label>Business Entity<span class="text-danger">*</span></label>
        <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
      </div>
      <div class="d-flex gap-2">
        <nz-radio-group
          formControlName="business_entity"
          class="d-flex flex-column gap-3"
        >
          <div nz-row [nzGutter]="16">
            <div nz-col class="d-flex flex-column gap-2">
              <label nz-radio [nzValue]="1">Limited Company / PT</label>
              <label nz-radio [nzValue]="2">Limited Partnership / CV</label>
            </div>
            <div nz-col class="d-flex flex-column gap-2">
              <label nz-radio [nzValue]="3"
                >State Institution / Instansi Negara</label
              >
              <label nz-radio [nzValue]="4">Cooperative / Koperasi</label>
            </div>
            <div nz-col>
              <label nz-radio [nzValue]="5">Others</label>
            </div>
          </div>
        </nz-radio-group>
      </div>
    </div>
    <div class="d-flex flex-column flex-lg-row gap-3 mt-5">
      <div class="d-flex flex-column gap-2 col">
        <div class="d-flex gap-2">
          <label>Business Name<span class="text-danger">*</span></label>
          <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
        </div>
        <input
          nz-input
          formControlName="business_name"
          placeholder="e.g PT. John Doe"
          type="text"
          (keypress)="setRegexBName($event)"
          (input)="validateBusinessName()"
          [nzStatus]="validBName ? '' : 'error'"
        />
        <span class="text-danger">
          <img
            *ngIf="errMessBName !== ''"
            src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
            alt="warning"
            style="max-width: 10px; max-height: 10px"
          />
          {{ errMessBName }}</span
        >
        <div class="d-flex gap-2 mt-4">
          <label>Business Email<span class="text-danger">*</span></label>
          <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
        </div>
        <input
          nz-input
          formControlName="business_email"
          placeholder="e.g example@domain.com"
          type="email"
          (keypress)="setRegex($event)"
          (keydown.enter)="$event.preventDefault()"
          (input)="validateEmail()"
          [nzStatus]="validEmail ? '' : 'error'"
        />
        <span class="text-danger">
          <img
            *ngIf="errMessEmail !== ''"
            src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
            alt="warning"
            style="max-width: 10px; max-height: 10px"
          />
          {{ errMessEmail }}</span
        >
      </div>
      <div class="d-flex flex-column gap-2 col">
        <div class="d-flex gap-2">
          <label>Business Type<span class="text-danger">*</span></label>
          <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
        </div>
        <ng-select
          formControlName="business_type"
          [items]="businessTypeList"
          bindLabel="name"
          bindValue="id"
        >
        </ng-select>
        <div class="d-flex gap-2 mt-4">
          <label>Business Address<span class="text-danger">*</span></label>
          <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
        </div>
        <nz-textarea-count [nzMaxCharacterCount]="100">
          <textarea
            nz-input
            formControlName="business_address"
            rows="2"
            maxlength="100"
            placeholder="e.g Jl.Jend. Soedirman, Jakarta Pusat, 55555"
            (keydown.enter)="$event.preventDefault()"
            (input)="validateAddress()"
            [nzStatus]="validAddress ? '' : 'error'"
          ></textarea>
        </nz-textarea-count>
        <span class="text-danger">
          <img
            *ngIf="errMessAddress !== ''"
            src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
            alt="warning"
            style="max-width: 10px; max-height: 10px"
          />
          {{ errMessAddress }}</span
        >
      </div>
      <div class="d-flex flex-column gap-2 col">
        <div class="d-flex gap-2">
          <label>Business Phone<span class="text-danger">*</span></label>
          <span nz-icon nzType="question-circle" nzTheme="outline"></span> :
        </div>
        <input
          nz-input
          formControlName="business_phone"
          placeholder="e.g 8123456789"
          type="text"
          maxlength="12"
          (keypress)="preventNonNumeric($event)"
          (input)="validateInput($event)"
          [nzStatus]="validNumber ? '' : 'error'"
        />
        <span class="d-flex align-items-center gap-1 text-danger">
          <img
            *ngIf="errMessNumber !== ''"
            src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
            alt="warning"
            style="max-width: 10px; max-height: 10px"
          />
          {{ errMessNumber }}</span
        >
      </div>
    </div>
    <div class="d-flex align-self-end gap-2 mt-5">
      <button class="btn btn-outline-secondary" (click)="back()">Back</button>
      <button
        type="submit"
        class="btn text-white"
        [disabled]="isFormInvalid"
        [ngClass]="{
          'btn-secondary': isFormInvalid,
          'btn-primary': !isFormInvalid
        }"
        (click)="onSubmit()"
      >
        Next
      </button>
    </div>
  </div>
</form>
<app-modal-confirm-back [isShowConfirm]="isConfirmBack" [docType]="'SLA'" (isShowConfirmEvent)="closeConfirmBack()"></app-modal-confirm-back>
