<form [formGroup]="SlaFiles">
  <div class="card p-3 pb-5 mb-5 d-flex flex-column gap-3 mt-2">
    <nz-skeleton
      [nzLoading]="loading"
      [nzActive]="true"
      class="d-flex flex-column"
    >
      <div class="d-flex align-items-center gap-3">
        <img
          src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
          alt="back"
          (click)="handleBack()"
        />
        <div class="title">Submission SLA Document</div>
      </div>
      <h6 class="text-bold mt-5">Upload Your Company Document</h6>
      <div class="table-responsive">
        <table class="table mt-4">
          <thead class="bg-light">
            <tr>
              <th></th>
              <th>
                <div class="d-flex flex-column">
                  Document
                  <small class="text-light fst-italic" style="font-weight: 400"
                    >(Upload business legal documents)</small
                  >
                </div>
              </th>
              <th>
                <div class="d-flex flex-column">
                  Notes
                  <small class="text-light fst-italic" style="font-weight: 400">
                    (To be filled in for any missing legal documents)</small
                  >
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let doc of documents; let i = index">
              <td class="col-5">
                {{ doc.title }}
                <span class="text-danger" *ngIf="doc.isRequired">*</span>
                <small class="text-light fst-italic" *ngIf="doc.subtitle">{{
                  doc.subtitle
                }}</small>
              </td>
              <td class="col">
                <div class="d-flex gap-2 align-items-center">
                  <input
                    type="file"
                    [formControlName]="doc.key"
                    (change)="handleFileUpload($event, doc.key)"
                    #fileupload
                    [id]="'fileupload_' + doc.key"
                    class="form-control d-none"
                  />

                  <button
                    nz-button
                    class="rounded d-flex align-items-center"
                    (click)="triggerFileInput(doc.key)"
                  >
                    <span nz-icon nzType="upload"></span>
                    <span>Upload</span>
                  </button>

                  <div
                    *ngIf="getUploadedFileName(doc.key)"
                    class="d-flex align-items-center"
                    style="margin-bottom: 5px"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/icons/document-link.svg"
                      alt=""
                      width="16px"
                    />
                    <a
                      class="mx-2"
                      style="
                        color: #08a0f7;
                        font-size: 12px;
                        text-decoration: underline;
                        cursor: pointer;
                      "
                      *ngIf="getUploadedFilePath(doc.key)"
                      [href]="getUploadedFilePath(doc.key)"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {{ getUploadedFileName(doc.key) }}
                    </a>
                    <span
                      *ngIf="!getUploadedFilePath(doc.key)"
                      class="mx-2"
                      style="
                        color: #08a0f7;
                        font-size: 12px;
                        text-decoration: underline;
                        cursor: pointer;
                      "
                    >
                      <a
                        *ngIf="handleFilePreview(doc.key)?.url"
                        [href]="handleFilePreview(doc.key)?.url"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ handleFilePreview(doc.key)?.name }}
                      </a>
                    </span>
                    <button
                      class="btn d-flex justify-content-center align-items-center"
                      style="
                        background-color: rgb(242, 242, 242);
                        height: 18px;
                        width: 14px;
                      "
                      (click)="deleteFile(doc.key)"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/trash.svg"
                        alt=""
                        width="12px"
                      />
                    </button>
                  </div>
                </div>
                <small class="text-light fst-italic">PDF - Max 500KB</small>
              </td>
              <td class="col-3">
                <div nz-form-item nz-form-control>
                  <input
                    [formControlName]="doc.key + '_note'"
                    nz-input
                    placeholder="Add notes here"
                    type="text"
                    (input)="handleNotes($event, doc.key)"
                  />
                  <small
                    class="text-danger"
                    *ngIf="SlaFiles.controls[doc.key+'_note'].errors?.['minlength']"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                      alt="warning"
                      style="max-width: 10px; max-height: 10px"
                    />
                    Note must be at least 5 characters long.
                  </small>
                  <small
                    class="text-danger"
                    *ngIf="SlaFiles.controls[doc.key+'_note'].errors?.['maxlength']"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                      alt="warning"
                      style="max-width: 10px; max-height: 10px"
                    />
                    Note cannot exceed 250 characters.
                  </small>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
      <div class="d-flex gap-2 align-self-end">
        <button class="btn btn-outline-secondary" (click)="handleBack()">
          Back
        </button>
        <button
          type="submit"
          [disabled]="
            slaFile.length === 0 || abfFile.length === 0 || idFile.length === 0
          "
          class="btn btn-primary text-white"
          [ngClass]="{
            'btn-secondary':
              slaFile.length === 0 ||
              abfFile.length === 0 ||
              idFile.length === 0
          }"
          (click)="onSubmit()"
        >
          Send Request
        </button>
      </div>
    </nz-skeleton>
  </div>
</form>
<app-modal-confirm-back
  [isShowConfirm]="isConfirmBack"
  [docType]="'SLA Document'"
  (isShowConfirmEvent)="closeConfirmBack()"
></app-modal-confirm-back>
