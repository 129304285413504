import { Component, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject, takeUntil } from 'rxjs';
import { ADD_PHONE } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';
import { WhatsappService } from 'src/app/services/whatsapp.service';

@Component({
  selector: 'app-modal-add-phone',
  templateUrl: './modal-add-phone.component.html',
  styleUrl: './modal-add-phone.component.scss',
})
export class ModalAddPhoneComponent {
  @Input() isVisibleInputNumber = false;
  validNumber: boolean = true;
  errMessNumber: string = '';
  debounceTimeout: any;
  destroy$ = new Subject();

  formGroup: FormGroup = new FormGroup({
    telephone: new FormControl('', [
      Validators.required,
      Validators.minLength(9),
      Validators.maxLength(12),
    ]),
  });

  constructor(
    private message: NzMessageService,
    private BaseService: BaseService,
    private whatsappService: WhatsappService,
  ) {}

  handleOk(): void {
    const param = {
      phone_number: this.formGroup.get('telephone')?.value,
    }
    this.BaseService.putData(ADD_PHONE, param)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.message.create('success', 'Phone number added');
        this.isVisibleInputNumber = false;
      },
      (error) => {
        this.message.create('error', 'Failed to add phone number');
      });
  }

  preventNonNumeric(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;

    if (
      !/^\d$/.test(event.key) &&
      !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(
        event.key
      )
    ) {
      event.preventDefault();
    }

    if (input.value.length === 0 && event.key !== '8') {
      event.preventDefault();
    }
  }

  validateInput(event: Event): void {
    clearTimeout(this.debounceTimeout);
    const input = event.target as HTMLInputElement;

    this.debounceTimeout = setTimeout(() => {
      if (input.value.length > 12) {
        input.value = input.value.slice(0, 12);
      }

      if (input.value.length === 0) {
        this.validNumber = false;
        this.errMessNumber = 'Phone number must be filled';
      } else if (!/^\d+$/.test(input.value)) {
        this.validNumber = false;
        this.errMessNumber = 'Must be a number';
      } else if (!input.value.startsWith('8')) {
        this.validNumber = false;
        this.errMessNumber = 'Phone number must start with number 8';
      } else if (input.value.length < 9) {
        this.validNumber = false;
        this.errMessNumber = 'Minimum 9 digits';
      } else {
        this.checkWA();
      }
    }, 500);
  }

  async checkWA() {
    const result = await this.whatsappService.checkWA(this.formGroup.get('telephone')?.value);
    this.validNumber = result.valid;
    this.errMessNumber = result.message;
  }
}
