<div class="mt-4">
  <form [formGroup]="regulerForm">
    <div class="d-flex flex-column gap-3">
      <div class="d-flex">
        <span class="d-flex flex-column col-3 gap-4">Origin City</span>
        <div class="col-6">
          <ng-select
            formControlName="originCity"
            [items]="cityList"
            bindLabel="label"
            bindValue="id"
            placeholder="Origin City"
            notFoundText="City not found"
            (search)="getCityList($event)"
          >
          </ng-select>
        </div>
      </div>

      <div class="d-flex">
        <span class="d-flex flex-column col-3 gap-4">Destination City</span>
        <div class="col-6">
          <ng-select
            formControlName="destinationCity"
            [items]="cityList"
            bindLabel="label"
            bindValue="id"
            placeholder="Destinationity"
            notFoundText="City not found"
            (search)="getCityList($event)"
          >
          </ng-select>
        </div>
      </div>
      
      <div class="d-flex">
        <span class="d-flex flex-column col-3 gap-4">Package Value</span>
        <div class="col-6">
          <nz-input-group nzAddOnBefore="Rp" class="rounded">
            <input
              nz-input
              formControlName="itemValue"
              placeholder="Package Value"
              type="text"
              style="
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
              "
            />
          </nz-input-group>          
        </div>
      </div>

      <div class="d-flex">
        <span class="d-flex flex-column col-3 gap-4">Package Weight</span>
        <div class="col-6">
          <nz-input-group nzAddOnAfter="grams" class="rounded">
            <input
              nz-input
              formControlName="packageWeight"
              placeholder="Package Weight"
              type="text"
              style="
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
              "
            />
          </nz-input-group>
        </div>
      </div>

      <div class="d-flex">
        <span class="d-flex flex-column col-3 gap-4">Package Volume</span>
        <div class="col-6 d-flex gap-1 flex-column flex-lg-row">
          <div class="col-12 col-lg-4">
            <nz-input-group nzAddOnAfter="cm" class="rounded">
              <input
                nz-input
                formControlName="packageVolume1"
                placeholder="Package Length"
                type="text"
                style="
                  border-top-right-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                "
              />
            </nz-input-group>
          </div>
          <div class="col-12 col-lg-4">
            <nz-input-group nzAddOnAfter="cm" class="rounded">
              <input
                nz-input
                formControlName="packageVolume2"
                placeholder="Package Width"
                type="text"
                style="
                  border-top-right-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                "
              />
            </nz-input-group>
          </div>
          <div class="col-12 col-lg-4">
            <nz-input-group nzAddOnAfter="cm" class="rounded">
              <input
                nz-input
                formControlName="packageVolume3"
                placeholder="Package Height"
                type="text"
                style="
                  border-top-right-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                "
              />
            </nz-input-group>
          </div>
        </div>
      </div>

      <div class="d-flex mt-3">
        <span class="d-flex flex-column col-3 gap-4"></span>
        <div class="col-4 d-flex gap-2">
          <button class="btn btn-outline-secondary col-6" (click)="resetValue()">Reset</button>
          <button
            class="btn btn-primary text-white col-6"
            [disabled]="regulerForm.invalid"
            [ngClass]="{ 'btn-secondary': regulerForm.invalid }"
            (click)="calculateDestination()"
          >
            Check
          </button>
        </div>
      </div>
    </div>
  </form>
  <ng-container *ngIf="listCalculate">
    <nz-skeleton
      [ngClass]="loading ? 'p-3' : 'p-0'"
      [nzLoading]="loading"
      [nzActive]="true"
      [nzParagraph]="true"
    >
      <app-table-regular-rates
        [listReguler]="listCalculate"
        [listCargo]="listCargo"
      ></app-table-regular-rates>
    </nz-skeleton>
  </ng-container>
</div>
