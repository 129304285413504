import {Injectable} from "@angular/core";
import {BaseService} from "../base.service";
import {
  COMISSION_HISTORY,
  DETAIL_BALANCE,
  DOWNLOAD_BALANCE,
  GET_BALANCE, HISTORY_BALANCE,
  POSSIBLE_INCOME,
  REQUEST_BALANCE,
  WITHDRAWL_DETAIL,
  WITHDRAWL_LIST
} from "../../constants/endpoint.const";
import {BalanceRequestType} from "../../types/request/balance-request.type";
import { wdListRequestType } from "src/app/types/request/wd-list-request.type";
import { wdDetailRequestType } from "src/app/types/request/detail-wd-saldo-request.type";
import { BalanceHistoryRequestType } from "src/app/types/request/balanace-history-request";
import { DownloadBalanceRequsetType } from "src/app/types/request/download-balance-requset.type";

@Injectable({
  providedIn: 'root',
})

export class BalanceService{
  constructor(
    private baseService: BaseService
  ) {
  }

  balance(){
    return this.baseService.getData(GET_BALANCE, null, null);
  }

  request(params: BalanceRequestType){
    return this.baseService.postData(REQUEST_BALANCE, params, null, null);
  }

  detail(params: BalanceRequestType){
    return this.baseService.getData(DETAIL_BALANCE + '/' + params.withdrawal_id, null, null);
  }

  possibleIncome(){
    return this.baseService.getData(POSSIBLE_INCOME, null, null);
  }

  balanceHistory(params: BalanceHistoryRequestType) {
    return this.baseService.getData(HISTORY_BALANCE, params, null);
  }
  balanceDownload(params: DownloadBalanceRequsetType) {
    return this.baseService.getData(DOWNLOAD_BALANCE, params);
  }
  commissionHistory(params: BalanceHistoryRequestType) {
    return this.baseService.getData(COMISSION_HISTORY, params, null);
  }
  withdrawlList(params: wdListRequestType) {
    return this.baseService.getData(WITHDRAWL_LIST, params, null);
  }
  detailWithdraw(id: number) {
    return this.baseService.getData(`${WITHDRAWL_DETAIL}/${id}`, null);
  }
}
