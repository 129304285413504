<div class="table-responsive" style="overflow-x: auto; z-index: 0; position: relative;">
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col">Order Date</th>
        <th scope="col">Customer</th>
        <th scope="col">Product</th>
        <th scope="col"></th>
        <th scope="col">Total Payment</th>
        <th scope="col">Status</th>
        <th scope="col">Detail</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody *ngIf="dataOrder && dataOrder.length > 0; else noData">
      <tr *ngFor="let item of dataOrder; let i = index">
        <td>{{ item?.order_date?.slice(0, 10) }}</td>
        <td>
          {{ item?.receiver_name }}<br />
          <div class="d-flex align-items-center mt-3">
            <img
              [src]="item.shipping_logo"
              alt="expedition"
              style="max-width: 100px"
            />
            <span class="ms-2 shipping">{{ item.shipping_type.charAt(0).toUpperCase() + item.shipping_type.slice(1).toLowerCase() }}</span>
          </div>
        </td>
        <td>
          <div
            *ngFor="let order of item.order_details?.slice(0, 1); let i = index"
            class="d-flex align-items-center gap-4"
          >
            <div>
            <div>{{ order.product_name }}</div>
              <div class="text-primary">
                {{ order.product_variant_name }}
              </div>
            </div>
          </div>
          <div
            *ngFor="let order of item.order_details?.slice(1); let i = index"
            class=""
          >
            <div
              *ngIf="item.order_details.length > 1"
              class=""
              style="margin-top: 30px"
            >
              <div *ngIf="item.isCollapsed" class="d-flex align-items-center">
                <div>
                  <div>{{ order.product_name }}</div>
                  <div class="text-primary">
                    {{ order.product_variant_name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td>
          <div
            *ngFor="let order of item.order_details?.slice(0, 1); let i = index"
            style="margin-right: 30px"
          >
            X{{ order.qty }}
          </div>
          <div
            *ngFor="let order of item.order_details?.slice(1); let i = index"
          >
            <div *ngIf="item.order_details.length > 1" class="">
              <div *ngIf="item.isCollapsed" class="d-flex align-items-center">
                <div style="margin-right: 30px; margin-top: 60px">
                  X{{ order.qty }}
                </div>
              </div>
            </div>
          </div>
        </td>
        <td>
          <div class="d-flex flex-row gap-2 align-items-start">
            <div>
              <i class="icon-document-text"></i>
            </div>
          <div class="d-flex flex-column">
            <div>{{ item.grand_total | currency : 'IDR' : true : '1.0' }}</div>
            <div class="text-primary">{{ item.payment_method }}</div>
            </div>
          </div>
        </td>
        <td [ngClass]="item.order_status === 'Diajukan' ? 'diajukan' : ''">
          <span *ngIf="item.order_status === 'Diajukan'" class="status-danger">Order Created</span>
          <span [ngClass]="handleClassStatus(item.order_status)">{{ handleStatus(item.order_status) }}</span>
        </td>
        <td>
          <div class="d-flex justify-content-between flex-column">
            <div class="d-flex justify-content-start">
              <button
                (click)="moveDetail(i)"
                style="
                  border: none;
                  background-color: transparent;
                  color: rgb(0, 187, 255);
                "
              >
                View Detail
              </button>
            </div>
            <div
              class="d-flex justify-content-end"
              style="flex-direction: column; align-items: flex-start"
            >
              <button
                *ngIf="item.order_details.length > 1"
                type="button"
                class=""
                (click)="item.isCollapsed = !item.isCollapsed"
                [attr.aria-expanded]="!isCollapsed"
                aria-controls="collapseExample"
                style="border: none; background-color: transparent"
              >
                <span *ngIf="!item.isCollapsed"
                  >{{ item.order_details.length - 1 }} Other Product
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
                    alt=""
                    width="14px"
                  />
                </span>
                <span *ngIf="item.isCollapsed"
                  >Close
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-up-2.svg"
                    alt=""
                    width="14px"
                  />
                </span>
              </button>
            </div>
          </div>
        </td>
        <td>
          <button *ngIf="item.shipping === 'GOSEND' || item.shipping === 'gosend'" class="btn p-0" nz-tooltip [nzTooltipTitle]="titleTemplate" [nzTooltipTitleContext]="{ $implicit: 'Icon' }" [nzTooltipColor]="'#ffffff'" [nzTooltipPlacement]="'top'">
            <img src="https://storage.googleapis.com/komerce/icon/more-gray.svg" alt="more">
          </button>
          <ng-template #titleTemplate let-thing>
            <button class="btn d-flex gap-2" (click)="requestPickup(item.id)">
              <img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/truck-fast.svg" alt="pickup">
              <span class="text-black">Request Pickup</span>
            </button>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #noData>
    <tbody>
      <tr>
        <td colspan="7" class="empty"><div class="text-center p-4">No data available</div></td>
      </tr>
    </tbody>
</ng-template>
