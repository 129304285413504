import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rates',
  standalone: false,
  templateUrl: './rates.component.html',
  styleUrl: './rates.component.scss'
})
export class RatesComponent {
  loading: boolean = true;
  isRegular: boolean = true;
  isInstant: boolean = false;

  constructor(private route: Router) {
    }

  ngOnInit(): void {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  moveTab(e: 'regular' | 'instant') {
    const queryParams: any = { tab: e };
    if (e === 'regular') {
      this.isRegular = true;
      this.isInstant = false;
    } else {
      this.isInstant = true;
      this.isRegular = false;
    } 
    this.route.navigate(['rates'], { queryParams });
  }
}
