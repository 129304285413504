import { environment } from '../../environments/environment.prod';

const BASE_URL = environment.BASE_URL;
const USERV1 = '/user/api/v1';
const USERV2 = '/user/api/v2';
const BANKV1 = '/user/api/v1';
const ORDERV1 = '/order/api/v1';
const BALANCEV1 = '/user/api/v1/balance';
const COMMISSIONV1 = '/user/api/v1/commission';
const TICKETV1 = '/ticket/api/v1/ticket';
const TARIF = '/tariff/api/v1';
export const RECAPTCHA_TOKEN = environment.RECAPTCHA_TOKEN;

export const CHECK_API_KEY = USERV1 + '/user/check-api-key'

const OTP = `${BASE_URL + USERV1}` + '/otp';
const USER = `${BASE_URL + USERV1}`;
const USER_AUTH = `${BASE_URL + USERV2}`;
const BANK = `${BASE_URL + BANKV1}/bank-account`;
const ORDER = `${BASE_URL + ORDERV1}/orders`;
const BALANCE = `${BASE_URL + BALANCEV1}`;
const COMISSION = `${BASE_URL + COMMISSIONV1}`;
const TICKET = `${BASE_URL + TICKETV1}`;
const BILLINGS = `${BASE_URL + USERV1}/subscription`;
const INVOICE = `${BASE_URL + USERV1}/invoice`;

// REGISTER
export const REGISTER = USER + '/user/register';

// VERIFY
export const VERIFY = USER + '/user/activate';
export const RESEND_VERIFY = USER + '/user/resend-activation';

// FORGOT PASSWORD
export const FORGOT_PASSWORD = USER + '/user/forget-password';

// RESET PASSWORD
export const RESET_PASSWORD = USER + '/user/reset-password';

//USER
export const LOGIN = USER_AUTH + '/auth/login';
export const PROFILE = USER + '/user/profile';
export const CHECK_PIN = USER + '/user/check-pin';
export const UPDATE_PIN = USER + '/user/update-pin';
export const REFRESH_TOKEN = USER + '/auth/refresh-token';

//BANK ACCOUNT
export const BANKLIST = BANK + '/list';
export const BANKLISTAV = BANK + '/list-bank-available';
export const BANKSTORE = BANK + '/store';
export const BANKUPDATE = BANK + '/update';

//ORDER
export const ACCUMULATION_DATA_ORDER = ORDER + '/accumulation-data-order';
export const DATA_ORDER = ORDER + '/data-orders';
export const DETAIL_DATA_ORDER = ORDER + '/detail-order';
export const AWB_HISTORY = ORDER + '/data-airway-bill';
export const DOWNLOAD_ORDER = ORDER + '/download';

//BALANCE
export const GET_BALANCE = USER + '/user/balance';
export const REQUEST_BALANCE = USER + '/withdrawal/request';
export const DETAIL_BALANCE = USER + '/withdrawal/detail';
export const WITHDRAWL_LIST = USER + '/withdrawal/list';
export const WITHDRAWL_DETAIL = USER + '/withdrawal/detail';
export const POSSIBLE_INCOME = ORDER + '/possible-income';
export const HISTORY_BALANCE = BALANCE + '/history';
export const DOWNLOAD_BALANCE = BALANCE + '/download';
export const TOPUP_BALANCE = BALANCE + '/top-up';


//COMISSION
export const COMISSION_HISTORY = COMISSION + '/history';
export const COMISSION_DETAIL = COMISSION + '/detail';

//TICKET
export const CREATE_TICKET = TICKET + '/create';
export const TYPE_TICKET = TICKET + '/type-ticket';
export const LIST_TICKET = TICKET + '/list';
export const LIST_AWB = TICKET + '/find';
export const COUNT_TICKET = TICKET + '/count-ticket';
export const CHAT_TICKET = TICKET + '/chat';
export const CANCEL_TICKET = TICKET + '/cancel';
export const COUNT_UNREAD = TICKET + '/count-unread';
export const EXPEDITION_LIST = TICKET + '/expedition';

//BILLINGS
export const HISTORY_BILLINGS = BILLINGS + '/history';
export const STATUS_BILLINGS = BILLINGS + '/status';
export const CART_PACKAGE = BILLINGS + '/cart';
export const PAY_PACKAGE = BILLINGS + '/pay-now';
export const LIST_PACKAGE = BASE_URL + USERV1 + '/package';

//INVOICE
export const CHECK_ACTIVE = INVOICE + '/check-active-bill';
export const CHECK_PAYMENT = INVOICE + '/check-payment-status';
export const CANCEL_INVOICE = INVOICE + '/cancel-invoice';
export const EXPORT_INVOICE = INVOICE + '/export';

//PROFILE
export const CHANGE_PASSWORD = `${BASE_URL + USERV1}` + '/user/change-password';
export const LIST_APIKEY = `${BASE_URL + USERV1}` + '/user/api-key';
export const LIST_APIKEY_COST = `${BASE_URL + USERV1}` + '/limit/user/cost';
export const LIST_APIKEY_DELIVERY =
  `${BASE_URL + USERV1}` + '/limit/user/delivery';
export const PASS_APIKEY = `${BASE_URL + USERV1}` + '/user/validate-password';
export const SHOW_APICOST = `${BASE_URL + USERV1}` + '/user/api-key/cost';
export const SHOW_APIDELIVERY =
  `${BASE_URL + USERV1}` + '/user/api-key/delivery';
export const VALIDATE_PHONE =
  `${BASE_URL + USERV1}` + '/user/profile/phone-number/validate';
export const WEBHOOK =
  `${BASE_URL + USERV1}` + '/user/webhook';
export const ADD_PHONE = `${BASE_URL + USERV1}` + '/user/profile/phone-number';

// REFERRER
export const LIST_IP_ADDRESS = `${BASE_URL + USERV1}` + '/referrer/ip_address';
export const LIST_KEY = `${BASE_URL + USERV1}` + '/referrer/key';
export const DELETE_REF = `${BASE_URL + USERV1}` + '/referrer/ip_address';
export const DELETE_KEY = `${BASE_URL + USERV1}` + '/referrer/key';

//CHECHK WHATSAPP GLOBAL
export const CHECK_WA = 'https://api.komship.komerce.pw/api/v1/check-wa-global';

//OTP
export const REQUEST_OTP = OTP + '/request-otp';
export const VERIFY_OTP = OTP + '/verify-otp';
export const UPDATE_PHONE = PROFILE + '/phone-number';

//DOCUMENT
export const DOC_STATE = `${BASE_URL + USERV1}` + '/user-document-state'
export const PDF_SLA = `${BASE_URL + USERV1}` + '/service-level-agreement/download'
export const PDF_CA = `${BASE_URL + USERV1}` + '/collaborator-agreement/download'
export const BUSINESS_TYPE = `${BASE_URL + USERV1}` + '/business-type'
export const SLA_DATA = `${BASE_URL + USERV1}` + '/service-level-agreement'
export const DOC_UPLOAD = `${BASE_URL + USERV1}` + '/document'
export const CA_DATA = `${BASE_URL + USERV1}` + '/collaborator-agreement'
export const TEST_CASE = `${BASE_URL + USERV1}` + '/test-case'
export const UAT_DATA = `${BASE_URL + USERV1}` + '/user-acceptance-test'

//SIMULATOR
export const CHECK_ORDER_STATUS = `${BASE_URL + ORDERV1}` + '/orders/check-order-status'
export const CHANGE_ORDER_STATUS = `${BASE_URL + ORDERV1}` + '/callback/inject-order'

//TARIF
export const GET_CITY = `${BASE_URL + TARIF}` + '/internal/destination/search-destination'
export const CALCULATE_DESTINATION = `${BASE_URL + TARIF}` + '/internal/calculate/calculate-destination'

//PICKUP
export const REQUEST_PICKUP = `${BASE_URL + ORDERV1}` + '/pickup/internal/request'
export const CANCEL_REQUEST = `${BASE_URL + ORDERV1}` + '/orders/internal/cancel'