import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpContextToken,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { LocalStorageService } from '../services/local-storage.service';
import { AuthService } from '../services/auth.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ModeSwitchService } from '../services/mode-switch.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { environment as devEnv } from 'src/environments/environment';
import { environment as sandEnv } from 'src/environments/environment.sandbox';
import { environment as prodEnv } from 'src/environments/environment.prod';

const TOKEN_HEADER_KEY = 'Authorization';
const BYPASS_INTERCEPTOR = new HttpContextToken<boolean>(() => false);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private tokenService: LocalStorageService,
    private authService: AuthService,
    private message: NzMessageService,
    private modeSwitchService: ModeSwitchService,
    private router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the request should bypass the interceptor
    if (req.context.get(BYPASS_INTERCEPTOR)) {
      return next.handle(req);
    }

    let authReq = req;
    const token = this.tokenService.getToken();
    const currentUrl = this.router.url;

    let baseUrl = '';

    if (
      (currentUrl.includes('/billings') || currentUrl.includes('/document')) &&
      (localStorage.getItem('mode') === 'sandbox')
    ) {
      baseUrl = sandEnv.BASE_URL;
    } else if (
      req.url.endsWith('/profile') &&
      (localStorage.getItem('mode') === 'sandbox')
    ) {
      baseUrl = sandEnv.BASE_URL;
    } else if (localStorage.getItem('mode') === 'sandbox') {
      baseUrl = devEnv.BASE_URL;
    } else {
      baseUrl = sandEnv.BASE_URL;
    }

    const spliceUrl = req.url
      .replace(environment.BASE_URL, '')
      .replace('https://api.collaborator.komerce.id', '')
      .replace('https://api.collaborator.komerce.my.id', '')
      .replace('https://api-sandbox.collaborator.komerce.id', '');

    if (token) {
      const modifiedUrl = `${baseUrl}${spliceUrl}`;
      authReq = req.clone({
        url: modifiedUrl,
        headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token),
      });
    }

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.handle401Error(authReq, next);
        }
        return throwError(error);
      })
    );
  }

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  
  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
  
      return from(this.authService.refreshToken()).pipe(
        switchMap(() => {
          const newToken = this.tokenService.getToken();
          this.isRefreshing = false;
          this.refreshTokenSubject.next(newToken);
  
          return next.handle(this.addTokenToRequest(request, newToken));
        }),
        catchError((error) => {
          this.isRefreshing = false;
          this.authService.logout();
          this.message.error('Session expired. Please log in again.');
          this.router.navigate(['/login']);
          return throwError(error);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => next.handle(this.addTokenToRequest(request, token)))
      );
    }
  }
  
  private addTokenToRequest(request: HttpRequest<any>, token: string | null): HttpRequest<any> {
    if (!token) {
      return request;
    }
  
    const spliceUrl = request.url
      .replace(environment.BASE_URL, '')
      .replace('https://api.collaborator.komerce.id', '')
      .replace('https://api.collaborator.komerce.my.id', '')
      .replace('https://api-sandbox.collaborator.komerce.id', '');
  
    const currentBaseUrl = this.modeSwitchService.getCurrentMode().BASE_URL;
    const modifiedUrl = `${currentBaseUrl}${spliceUrl}`;
  
    return request.clone({
      url: modifiedUrl,
      headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token),
    });
  }
  
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

export const BypassInterceptorContext = BYPASS_INTERCEPTOR;
