import {
  Component,
  EventEmitter,
  Input,
  Output,
  LOCALE_ID,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { BalanceService } from 'src/app/services/api/balance.service';
import { ToastService } from 'src/app/services/toast.service';
import * as moment from 'moment';
import 'moment/locale/id';
import {
  LocaleConfig,
  DaterangepickerDirective,
} from 'ngx-daterangepicker-material';
import { NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Dayjs } from 'dayjs';
import { HistoryBalanceModel } from 'src/app/models/history-balance.model';
import { BalanceResponseType } from 'src/app/types/response/balance-response.type';
import { ResponseCustomType } from 'src/app/types/response/response.type';
import { balanceModel } from 'src/app/models/balance.model';
import { ModalDlRincianSaldo } from 'src/app/modules/shared/components/modal-download-rincian-saldo/modal-download-rincian-saldo.component';
import { LocaleConfigDate } from 'src/app/constants/date-config.const';

@Component({
  selector: 'app-detail-saldo',
  templateUrl: './detail-saldo.component.html',
  styleUrls: ['./detail-saldo.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'id' }],
})
export class DetailSaldoComponent {
  @Input() HistoryList: HistoryBalanceModel[] = [];
  @Input() filterHistory: any = {
    start_date: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    transaction_type:
      'top_up,withdrawal,order_shipping,order_returned,order_received,order_cancel,insurance_fee',
    limit: 50,
    offset: 0,
    airway_bill: '',
  };
  keepCalendarOpeningWithRange: boolean;
  @Output() filterResult = new EventEmitter();
  destroy$ = new Subject();
  @ViewChild(DaterangepickerDirective, { static: false })
  pickerDirective!: DaterangepickerDirective;
  @ViewChild('checkBoxRef', { static: false }) checkBoxRef!: ElementRef;
  @Input() balance: balanceModel = {};
  form!: FormGroup;
  uncheck = false;
  uncheckList!: boolean;
  private searchSubject = new Subject<string>();

  selected: any = {
    startDate: moment().subtract(6, 'days'),
    endDate: moment(),
  };

  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
  };

  invalidDates: moment.Moment[] = [
    moment().add(2, 'days'),
    moment().add(3, 'days'),
    moment().add(5, 'days'),
  ];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  setlocale: LocaleConfig = LocaleConfigDate

  status: any = [
    {
      value: 'withdrawal',
      label: 'Withdrawl',
      id: 'withdrawal',
      checked: false,
    },
    {
      value: 'order_received',
      label: 'COD Orders (Received)',
      id: 'Received',
      checked: false,
    },
    {
      value: 'order_returned',
      label: 'COD Orders (Return)',
      id: 'Retur',
      checked: false,
    },
    {
      value: 'order_shipping',
      label: 'Non-COD Orders (Shipping Cost)',
      id: 'Ongkir',
      checked: false,
    },
    {
      value: 'order_cancel',
      label: 'Non-COD Orders (Canceled)',
      id: 'Cancel',
      checked: false,
    },
    {
      value: 'insurance_fee',
      label: 'Insurance',
      id: 'Insurance',
      checked: false,
    },
    {
      value: 'top_up',
      label: 'Top Up',
      id: 'TopUp',
      checked: false,
    },
  ];

  isSemua: boolean = false;
  selectedStatuses: string[] = [];
  resi: string = '';
  loading: boolean = true;

  constructor(
    private balanceService: BalanceService,
    private route: Router,
    private toastService: ToastService,
    public formatter: NgbDateParserFormatter,
    private modalService: NgbModal
  ) {
    this.keepCalendarOpeningWithRange = true;
  }
  ngOnInit(): void {
    this.getHistory();
    this.getBalance();

    this.searchSubject.pipe(debounceTime(300)).subscribe((value) => {
      this.filterHistory.airway_bill = value;
      this.filterResult.emit(this.filterHistory);
      this.getHistory();
    });

    setTimeout(() => {
      this.loading = false
    }, 1000)
  }
  getBalance() {
    this.balanceService
      .balance()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: ResponseCustomType<BalanceResponseType>) => {
          this.balance = res.data;
        },
        (error) => {}
      );
  }
  back() {
    this.route.navigate(['finance/balance']);
  }

  onChangeSearch(value: any) {
    this.searchSubject.next(value);
  }

  getStatus(event: any, list: any) {
    const inputElement = event.target as HTMLInputElement;
    const isChecked = inputElement.checked;

    if (isChecked && !this.selectedStatuses.includes(list.value)) {
      this.selectedStatuses.push(list.value);
    } else if (!isChecked && this.selectedStatuses.includes(list.value)) {
      this.selectedStatuses = this.selectedStatuses.filter(
        (value) => value !== list.value
      );
    }

    if (this.selectedStatuses.length > 0) {
      this.filterHistory.transaction_type = this.selectedStatuses.join(',');
    } else {
      this.filterHistory.transaction_type =
        'withdrawal,order_shipping,order_returned,order_received,order_cancel,insurance_fee,top_up';
      this.selectedStatuses = [];
    }

    this.status = this.status.map((item: any) => {
      if (item.value === list.value) {
        return { ...item, checked: isChecked };
      }
      return item;
    });

    this.filterResult.emit(this.filterHistory);
    this.getHistory();
  }

  resetFilter() {
    this.filterHistory.start_date = moment()
      .subtract(6, 'days')
      .format('YYYY-MM-DD');
    this.filterHistory.end_date = moment().format('YYYY-MM-DD');
    (this.filterHistory.transaction_type =
      'withdrawal,order_shipping,order_returned,order_received,order_cancel,insurance_fee,top_up'),
      (this.selectedStatuses = []);

    this.filterResult.emit(this.filterHistory);
    this.getHistory();

    this.status.forEach((item: any) => {
      item.checked = false;
    });
  }
  openDatepicker() {
    this.pickerDirective.open();
  }
  onChangeDate(e: any) {
    const endDate: Dayjs = e.endDate;
    const startDate: Dayjs = e.startDate;

    this.filterHistory.start_date = startDate ?
    startDate.format('YYYY-MM-DD') + ' ' + '00:00:00' : this.selected.startDate.format('YYYY-MM-DD');

    this.filterHistory.end_date = endDate ?
    endDate.format('YYYY-MM-DD') + ' ' + '23:59:59' : this.selected.endDate.format('YYYY-MM-DD');

    this.filterResult.emit(this.filterHistory);
    this.getHistory();
  }

  salinResi(v: any): void {
    const noresi = v;
    navigator.clipboard
      .writeText(noresi)
      .then(() => {
        this.toastService.show(
          null,
          'Receipt Number successfully copied',
          'success',
          {
            classname: 'bg-success',
            delay: 3000,
          }
        );
      })
      .catch((e) => console.log(e));
  }

  getHistory(): void {
    this.balanceService
      .balanceHistory(this.filterHistory)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.HistoryList = res.data;
        },
        () => {}
      );
  }

  handleDownload(): void {
    const modal = this.modalService.open(ModalDlRincianSaldo, {
      backdrop: 'static',
      centered: true,
      size: 'lg',
    });

    modal.componentInstance.data = this.balance;
  }
  typePayment(v: any) {
    if (v === 'BANK TRANSFER') {
      return 'Non-COD Orders';
    } else if (v === '') {
      return 'Topup Balance';
    } else {
      return 'COD Orders';
    }
  }
  typeTransaction(v: any) {
    if (v === 'withdrawal') {
      return 'Withdrawl';
    }
    if (v === 'order_shipping') {
      return 'Shipping Cost';
    }
    if (v === 'order_returned') {
      return 'Return';
    }
    if (v === 'order_received') {
      return 'Received';
    }
    if (v === 'order_cancel') {
      return 'Canceled';
    }
    if (v === 'top_up') {
      return 'Topup Balance';
    }
    if (v === 'insurance_fee') {
      return 'Insurance';
    } else {
      return '';
    }
  }
  classTransaction(v: any) {
    if (v === 'order_shipping') {
      return 'text-secondary';
    }
    if (v === 'order_returned') {
      return 'text-danger';
    }
    if (v === 'order_received') {
      return 'text-success';
    }
    if (v === 'order_cancel') {
      return 'text-secondary';
    }
    if (v === 'insurance_fee') {
      return 'text-danger';
    } else {
      return 'text-secondary';
    }
  }
  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }
}
