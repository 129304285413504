import * as moment from 'moment';
import 'moment/locale/id';

export const rangesDate = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [
    moment().subtract(1, 'month').startOf('month'),
    moment().subtract(1, 'month').endOf('month'),
  ],
};

export const rangesDateDashboard = {
    Today: [moment(), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 1 Month': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
}

export const LocaleConfigDate = {
    applyLabel: 'Save',
    cancelLabel: 'Cancel',
    separator: ' - ',
    clearLabel: 'Cancel',
    format: 'DD MMM YYYY',
    displayFormat: 'DD MMM YYYY',
    daysOfWeek: ['Sun |', 'Mon |', 'Tue |', 'Wed |', 'Thu |', 'Fri |', 'Sat'],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
      ],
};