import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as devEnv } from 'src/environments/environment';
import { environment as sandEnv } from 'src/environments/environment.sandbox';

const environmentConfigs = { sandbox: devEnv, production: sandEnv };

@Injectable({
  providedIn: 'root',
})
export class ModeSwitchService {
  // Load initial mode from localStorage or fallback to 'sandbox'
  private initialMode = (localStorage.getItem('mode') as 'sandbox' | 'production') || 'sandbox';

  // BehaviorSubject to store mode and environment settings
  private modeSubject = new BehaviorSubject({
    ...environmentConfigs[this.initialMode], // ✅ Ensure `mode` is not duplicated
    mode: this.initialMode, // ✅ Explicitly set mode separately
  });

  // Observable for mode updates
  mode$: Observable<{
    BASE_URL: string;
    RECAPTCHA_TOKEN: string;
    fcm_token: string;
    durian_pay: object;
    mode: string; // ✅ Explicitly included
  }> = this.modeSubject.asObservable();

  // Method to switch between sandbox and production
  switchMode(newMode: 'sandbox' | 'production') {
    if (newMode in environmentConfigs) {
      localStorage.setItem('mode', newMode); // ✅ Save mode in localStorage

      this.modeSubject.next({
        ...environmentConfigs[newMode], // ✅ Spread only environment config
        mode: newMode, // ✅ Explicitly add mode last to avoid overwrites
      });
    }
  }

  // Get the current mode synchronously
  getCurrentMode() {
    return this.modeSubject.getValue();
  }
}
