<nz-modal
  [(nzVisible)]="isOpenMap"
  nzCentered 
  [nzFooter]="null"
  [nzClosable]="false"
>
  <ng-container *nzModalContent>
    <div class="d-flex flex-column align-items-center py-2">
      <h5 class="mt-3 text-bold text-capitalize">Set Pinpoint {{ title }}</h5>
      <div id="map" style="width: 100%; height: 400px"></div>
      <span class="d-flex justify-content-center align-items-center gap-2 mt-3">
        <img
          src="https://storage.googleapis.com/komerce/icon/location-point-red.svg"
          alt="map"
        />
        <span class="col-10 text-truncate">
          {{ this.location }}
        </span>
      </span>
      <button
        class="btn btn-outline-primary w-100 mt-3 location-btn"
        (click)="getCurrentLocation()"
      >
        <img
          src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/gps.svg"
          alt="gps"
          class="gps-icon"
        />
        <span> Use Current Location </span>
      </button>
      <ng-select
          [(ngModel)]="selectedCity"
          (ngModelChange)="searchLocation($event)"
          [items]="cityList"
          bindLabel="label"
          bindValue="label"
          placeholder="Search city..."
          notFoundText="City not found"
          style="width: 100%;"
          class="mt-3"
          [minTermLength]="3"
          (clear)="searchLocation('')"
          (search)="getCityList($event)"
        >
        </ng-select>
      <ng-template #suffixIconSearch>
        <img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/search-normal-1.svg" alt="search" width="20px">
      </ng-template>
      <div class="mt-4 d-flex gap-2 col-12">
        <button class="btn btn-outline-primary col-6" (click)="handleCancel()">Cancel</button>
        <button class="btn btn-primary text-white col-6" (click)="handelConfirm()">Confirm</button>
      </div>
    </div>
  </ng-container>
</nz-modal>
