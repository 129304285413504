<nav
  id="sidebar"
  class="sidebar position-fixed card bg-white h-100 mt-4"
  style="z-index: 100"
>
  <div class="card-body d-flex flex-column" style="position: relative">
    <div class="d-flex flex-column flex-grow-0 p-0 card-body">
      <ng-container
        *ngIf="
          profileData?.is_rajaongkir === false ||
          profileData?.is_openapi === true
        "
      >
        <ng-container *ngFor="let item of menuItems">
          <a
            (click)="item.children ? toggleMenu() : null"
            [routerLink]="item.link"
            class="nav-link navItemClass d-flex justify-content-between"
            routerLinkActive="active-link"
            *ngIf="item.name !== 'Billing' && item.name !== 'Simulator'"
          >
            <div
              [ngClass]="{
                'd-flex flex-row nav justify-content-start ps-3': true,
                active: activeButton === item.name
              }"
              (click)="activeButton = item.name"
            >
              <img
                class="non"
                [ngClass]="active ? 'me-2' : 'me-0'"
                [src]="
                  activeButton === item.name
                    ? item.icon?.active
                    : item.icon?.default
                "
                alt=""
              />
              <img class="hov me-2" [src]="item.icon?.active" alt="" />
              <span *ngIf="active" class="link">{{ item.label }}</span>
              <img
                *ngIf="item.children"
                [src]="isRateOpen ? icons.arrow.down : icons.arrow.up"
                alt="arrow"
                class="ms-1"
                width="24px"
              />
            </div>
          </a>
          <div #collapse="ngbCollapse" [(ngbCollapse)]="isRateOpen">
            <ng-container *ngFor="let subItem of item.children">
              <a
                *ngIf="subItem.name !== 'Document' || isShowDoc === true"
                [routerLink]="subItem.link"
                class="accordion accordion-flush nav-link navItemClass"
                routerLinkActive="active-link"
              >
                <div
                  [ngClass]="{
                    'collapsed d-flex flex-row nav justify-content-start ps-5 pe-3': true,
                    active: activeButton === subItem.name
                  }"
                  (click)="activeButton = subItem.name"
                >
                  <span class="link pe-2">{{ subItem.label }}</span>
                </div>
              </a>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isShowBillings === true">
        <ng-container *ngFor="let item of menuItems">
          <ng-container *ngIf="item.name === 'Billing'">
            <a
              [routerLink]="item.link"
              class="nav-link navItemClass"
              routerLinkActive="active-link"
            >
              <div
                [ngClass]="{
                  'd-flex flex-row nav justify-content-start ps-3 pe-3': true,
                  active: activeButton === item.name
                }"
                (click)="activeButton = item.name"
              >
                <img
                  class="non"
                  [ngClass]="active ? 'me-2' : 'me-0'"
                  [src]="
                    activeButton === item.name
                      ? item.icon?.active
                      : item.icon?.default
                  "
                  alt=""
                />
                <img class="hov me-2" [src]="item.icon?.active" alt="" />
                <span *ngIf="active" class="link">{{ item.label }}</span>
              </div>
            </a>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isShowSimulator === true">
        <ng-container *ngFor="let item of menuItems">
          <ng-container *ngIf="item.name === 'Simulator'">
            <a
              [routerLink]="item.link"
              class="nav-link navItemClass"
              routerLinkActive="active-link"
            >
              <div
                [ngClass]="{
                  'd-flex flex-row nav justify-content-start ps-3 pe-3': true,
                  active: activeButton === item.name
                }"
                (click)="activeButton = item.name"
              >
              <div>
                <img
                  class="non"
                  [ngClass]="active ? 'me-2' : 'me-0'"
                  [src]="
                    activeButton === item.name
                      ? item.icon?.active
                      : item.icon?.default
                  "
                  alt=""
                />
                <img class="hov me-2" [src]="item.icon?.active" alt="" />
              </div>
                <span *ngIf="active" class="link">{{ item.label }}</span>
              </div>
            </a>
          </ng-container>
        </ng-container>
      </ng-container>

      <div (click)="toggleSettings()">
        <div
          [ngClass]="{
            'collapsed d-flex flex-row nav justify-content-between ps-3 pe-3': true,
            active: activeButton === 'Setting'
          }"
          data-bs-toggle="collapse"
        >
        <div class="d-flex justify-content-start">
          <img
            class="non me-2"
            [src]="
              activeButton === 'Setting'
                ? icons.settings.active
                : icons.settings.default
            "
            alt=""
          />
          <img class="hov me-2" [src]="icons.settings.active" alt="" />
          <span class="link">Settings</span>
        </div>
          <img
            class="ms-2 d-flex justify-content-end"
            [src]="isOpen ? icons.arrow.down : icons.arrow.up"
            alt=""
          />
        </div>
      </div>

      <div #collapse="ngbCollapse" [(ngbCollapse)]="isOpen">
        <ng-container *ngFor="let subItem of subMenuItems">
          <a
            *ngIf="subItem.name !== 'Document' || isShowDoc === true"
            [routerLink]="subItem.link"
            class="accordion accordion-flush nav-link navItemClass"
            routerLinkActive="active-link"
          >
            <div
              [ngClass]="{
                'collapsed d-flex flex-row nav justify-content-start ps-5 pe-3': true,
                active: activeButton === subItem.name
              }"
              (click)="activeButton = subItem.name"
            >
              <span class="link pe-2">{{ subItem.label }}</span>
            </div>
          </a>
        </ng-container>
      </div>
    </div>
    <div
      class="d-flex flex-column justify-content-end flex-grow-1 gap-3"
      style="margin-bottom: 100px; font-size: 14px; padding-left: 2rem"
    >
      <a
        style="text-decoration: none"
        class="text-black"
        href="https://komerceapi.readme.io/reference/rajaongkir-api"
        target="_blank"
      >
        <div class="d-flex flex-row gap-2 align-items-center">
          <img
            src="https://storage.googleapis.com/komerce/assets/LP-Rajaongkir/metadata/apple-touch-icon.png"
            alt="docs"
            width="20px"
          />
          <span>RajaOngkir API Docs</span>
        </div>
      </a>
      <a
        style="text-decoration: none"
        class="text-black"
        href="https://komerceapi.readme.io/reference/delivery-api"
        target="_blank"
      >
        <div class="d-flex flex-row gap-2 align-items-center">
          <img
            src="https://storage.googleapis.com/komerce/assets/LP-Komerce/komship.svg"
            alt="docs"
            width="20px"
          />
          <span>Delivery API Docs</span>
        </div>
      </a>
    </div>
  </div>
</nav>
