import {NavigationType, SubMenuType} from "../types/navigation.type";


export const navigation: NavigationType[] = [
    {
      name: 'dashboard',
      label: 'Dashboard',
      link: '/dashboard',
      icon: {
        active:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/home-hashtag.svg',
        default:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/home-hashtag.svg',
      },
    },
    {
      name: 'Order Data',
      label: 'Order Data',
      link: '/order-data',
      icon: {
        active:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/bag.svg',
        default:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/bag.svg',
      },
    },
    {
      name: 'Rates & Track',
      label: 'Rates & Track',
      icon: {
        active:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/routing.svg',
        default:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/routing.svg',
      },
      children: [
        {
          name: 'Rates',
          label: 'Rates',
          link: '/rates',
        },
        // {
        //   name: 'Track',
        //   label: 'Track',
        //   link: '/track',
        // },
      ]
    },
    {
      name: 'Finance',
      label: 'Finance',
      link: '/finance/balance',
      icon: {
        active:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/empty-wallet.svg',
        default:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/empty-wallet.svg',
      },
    },
    {
      name: 'Billing',
      label: 'Billing',
      link: '/billings',
      icon: {
        active:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/bill.svg',
        default:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/bill.svg',
      },
    },
    {
      name: 'Issues',
      label: 'Issues',
      link: '/ticketing',
      icon: {
        active:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/message-remove.svg',
        default:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/message-remove.svg',
      },
    },
    {
      name: 'Integration',
      label: 'Integration',
      link: '/integration',
      icon: {
        active:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/category-2.svg',
        default:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/category.svg',
      },
    },
    {
      name: 'Simulator',
      label: 'Simulator',
      link: '/simulator',
      icon: {
        active:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/truck-fast.svg',
        default:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/truck-fast.svg',
      },
    },
  ];

export const subMenu: SubMenuType[] = [
  {
    name: 'Profile',
    label: 'Profile',
    link: '/profile',
  },
  {
    name: 'Document',
    label: 'Document',
    link: '/document',
  },
  {
    name: 'PIN',
    label: 'PIN',
    link: '/pin',
  },
  {
    name: 'Bank Account',
    label: 'Bank Account',
    link: '/bank-account',
  },
]

export const subMenuRates: SubMenuType[] = [
  {
    name: 'Rates',
    label: 'Rates',
    link: '/rates',
  },
  {
    name: 'Track',
    label: 'Track',
    link: '/track',
  },
]