import {
  Component,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { OrderModel } from 'src/app/models/data-order.model';
import { OrderService } from 'src/app/services/api/order.service';

@Component({
  selector: 'app-table-data-order',
  templateUrl: './table-data-order.component.html',
  styleUrls: ['./table-data-order.component.scss'],
})
export class TableDataOrderComponent {
  @Input() dataOrder: OrderModel[] = [];
  destroy$ = new Subject();
  public isCollapsed = false;

  page: number = 1;
  perPage: number = 20;
  @Input()loadData: boolean = true;

  constructor(private route: Router) {}
  ngOnInit(): void {}

  moveDetail(i: any) {
    const id = this.dataOrder[i].id;

    this.route.navigate(['order-data/order-detail', id]);
  }

  handleStatus(e: string) {
    if (e === 'Dipacking') {
      return 'Packed'
    } else if (e === 'Dikirim') {
      return 'Sent'
    } else if (e === 'Diterima') {
      return 'Received'
    } else if (e === 'Retur') {
      return 'Return'
    } else return ''
  }

  handleClassStatus(e: string) {
    if (e === 'Dipacking') {
      return 'status-info'
    } else if (e === 'Dikirim') {
      return 'status-warning'
    } else if (e === 'Diterima') {
      return 'status-success'
    } else if (e === 'Retur') {
      return 'status-danger'
    } else return ''
  }

  requestPickup(id: number) {
    this.route.navigate(['order-data/pickup', id]);
  }
}
