import { Subject, Subscription, takeUntil } from 'rxjs';
import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DOC_STATE, SLA_DATA } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';

interface SlaData {
  businessInformation?: any;
  businessQuestionnaire?: any;
  businessEscalation?: any;
  serviceUsage?: any;
}

@Component({
  selector: 'app-sla-data-input',
  templateUrl: './sla-data-input.component.html',
  styleUrl: './sla-data-input.component.scss'
})
export class SlaDataInputComponent {
  loading: boolean = true;
  destroy$ = new Subject<void>();
  current: number = 0;
  isMediumScreen: boolean = false;
  slaData: SlaData = {};
  private queryParamsSubscription!: Subscription;
  collectedData: SlaData = {};
  state: number = 2;
  isConfirmBack: boolean = false
  @Output() openModalConfirmBack = new EventEmitter<boolean>();


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private BaseService: BaseService,
    private message: NzMessageService,
  ) {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize.bind(this));
  }

  ngOnInit(): void {
    this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
      this.current = this.getCurrentStep(params);
      const mode = params['mode'];

      if (localStorage.getItem('slaData')) {
        this.loadLocalStorageData();
      } else {
        this.getSLAData();
      }

      if (mode !== 'edit') {
        this.updateState();
      } else {
        this.getSLAData()
      }
    });

    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }


  private getCurrentStep(params: any): number {
    return params['step'] ? +params['step'] - 1 : 0;
  }

  getSLAData(): void {
    this.BaseService.getData(SLA_DATA)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.data !== null) {
            this.slaData = res.data;
            localStorage.setItem('slaData', JSON.stringify(this.slaData));
          } else {
            this.slaData = {}
          }
        },
        (err) => {
          this.message.create('error', 'Failed to fetch SLA data.');
        }
      );
  }

  loadLocalStorageData(): void {
    const storedData = localStorage.getItem('slaData');
    if (storedData) {
      this.slaData = JSON.parse(storedData);
    }
  }

  back(): void {
    this.isConfirmBack = true
  }

  closeConfirmBack() {
    this.isConfirmBack = false
  }

  checkScreenSize(): void {
    this.isMediumScreen = window.innerWidth >= 768 && window.innerWidth <= 1024;
  }

  updateState(): void {
    const params = {
      document_type: 'SLA',
      state: this.state,
    };
    this.BaseService.putData(DOC_STATE, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {},
        (err) => {
          console.error(err);
        }
      );
  }

  collectData(data: Partial<SlaData>): void {
    this.collectedData = {
      ...this.collectedData,
      ...data,
    };

    const storedData = localStorage.getItem('slaData');
    if (storedData) {
      const existingData = JSON.parse(storedData);
      this.collectedData = { ...existingData, ...this.collectedData };
    }

    localStorage.setItem('slaData', JSON.stringify(this.collectedData));
  }


  submitFinal(): void {
    const request = Object.keys(this.slaData).length === 0
      ? this.BaseService.postData(SLA_DATA, this.collectedData) :
      this.BaseService.putData(SLA_DATA, this.collectedData)
      request.pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.message.create('success', 'SLA Data has been submitted successfully');
            this.state = 3;
            this.updateState();
            localStorage.removeItem('slaData');
            this.router.navigate(['/document']);
          },
          (err) => {
            this.message.create('error', err.error?.data?.errors || 'Submission SLA Data Document failed, please check the completeness of the data.');
          }
        );
  }
}
