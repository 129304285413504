<div
  class="d-flex flex-column flex-md-row align-items-center justify-content-between mt-4"
>
  <div class="d-flex align-items-center">
    <div>Items per page:</div>
    <div class="btn-group ms-2" role="group">
      <div class="d-flex">
        <ng-container *ngFor="let option of perPageOptions">
          <div
            [ngClass]="perPage === option ? 'btn-primary' : ''"
            class="d-flex gap-3 btn-pg ms-1 radio-container"
          >
            <input
              type="radio"
              [name]="'radio'"
              [id]="'radio'"
              [value]="option"
              class="btn btn-prim"
              (change)="changePerPage(option)"
            />
            <label for="">{{ option }}</label>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="btn-group mt-3 mt-md-0 d-flex align-items-center" role="group">
    <button
      [disabled]="isPrevDisabled"
      type="button"
      class="btn-pg"
      (click)="changePage(currentPage - 1)"
    >
      <img
        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-left-2.svg"
        alt=""
        width="20px"
      />
    </button>
    <button
      *ngIf="currentPage > 1"
      class="btn number-pn d-flex align-items-center justify-content-center"
      (click)="changePage(currentPage - 1)"
    >
      {{ currentPage - 1 }}
    </button>
    <button class="btn number-page">
      {{ currentPage }}
    </button>
    <button
      [disabled]="isNextDisabled"
      *ngIf="currentPage < totalPages"
      class="btn number-pn d-flex align-items-center justify-content-center"
      [ngClass]="currentPage + 1 > totalPages ? 'd-none' : ''"
      (click)="changePage(currentPage + 1)"
    >
      {{ +currentPage + 1 }}
    </button>
    <button
      [disabled]="isNextDisabled"
      *ngIf="totalPages > 2"
      class="btn number-pn d-flex align-items-center justify-content-center"
      [ngClass]="currentPage !== 1 ? 'd-none' : ''"
      (click)="changePage(currentPage + 2)"
    >
      3
    </button>
    <button
      [disabled]="isNextDisabled"
      type="button"
      class="btn-pg"
      (click)="changePage(currentPage + 1)"
    >
      <img
        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-right-2.svg"
        alt=""
        width="20px"
      />
    </button>
  </div>
</div>
