import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-service-usage-input',
  standalone: false,
  templateUrl: './service-usage-input.component.html',
  styleUrl: './service-usage-input.component.scss',
})
export class ServiceUsageInputComponent {
  businessForm!: FormGroup;
  current: number = 2;
  @Input() slaDataInput: any;
  @Output() businessData = new EventEmitter<any>();
  @Output() submitFinal = new EventEmitter<number>();
  isValidPlatform: boolean = true;
  errPN: string = '';

  constructor(private fb: FormBuilder, private router: Router) {}

  ngOnInit() {
    this.initForm();

    if (this.businessForm) {
      this.businessForm.patchValue(this.slaDataInput);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['slaDataInput'] && changes['slaDataInput'].currentValue) {
      const slaData = changes['slaDataInput'].currentValue;

      if (this.businessForm) {
        this.businessForm.patchValue(slaData);
      }
    }
  }

  initForm() {
    this.businessForm = this.fb.group({
      type_of_service: [1, Validators.required],
      business_application_url: ['', [Validators.required, Validators.minLength(7)]],
      account_settings: [true, Validators.required],
    });
  }

  setValueRulePlatform() {
    const regex = /^[a-zA-Z0-9:.\\/]+$/;
    const value = this.businessForm.get('business_application_url')?.value || '';
    const invalidSequenceRegex = /\.\.+/;

    if (value.length === 0) {
      this.isValidPlatform = false;
      this.errPN = 'URL must be filled';
    } else if (value.length < 7 && value.length >= 1) {
      this.isValidPlatform = false;
      this.errPN = 'URL must be at least 7 characters long';
    } else if (invalidSequenceRegex.test(value)) {
      this.isValidPlatform = false;
      this.errPN = 'Format not supported';
    } else {
      if (value.startsWith('https://') || value.startsWith('http://')) {
        this.isValidPlatform = true;
        this.errPN = '';
      } else {
        this.isValidPlatform = false;
        this.errPN =
          'URL must start with https:// or http://';
      }
    }
  }

  onBack(): void {
    this.router.navigate(['/document/sla-data-input'], {
      queryParams: { step: 3 },
    });
  }
  onSubmit(): void {
    if (this.businessForm.valid) {
      this.businessData.emit(this.businessForm.value);
      this.submitFinal.emit();
      localStorage.removeItem('mode-sla-input');
    } else {
      console.log('Form is invalid!');
    }
  }
}
