import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { CA_DATA, DOC_STATE } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';
import { DocumentStateResponseType } from 'src/app/types/response/document-state.response.type';
import { ResponseCustomType } from 'src/app/types/response/response.type';

@Component({
  selector: 'app-ca-data-input',
  standalone: false,
  templateUrl: './ca-data-input.component.html',
  styleUrl: './ca-data-input.component.scss'
})
export class CaDataInputComponent {
  loading: boolean = true;
  destroy$ = new Subject
  caInputForm!: FormGroup;
  [key: string]: any;
  validBNumber: boolean = true;
  errMessBNumber: string = '';

  validBEmail: boolean = true;
  validCEmail: boolean = true;
  errMessBEmail: string = '';
  errMessCEmail: string = '';

  validBName: boolean = true;
  errMessBName: string = '';

  validAddress: boolean = true;
  errMessAddress: string = '';

  validPicName: boolean = true;
  errMessPicName: string = '';
  validPicJob: boolean = true;
  errMessPicJob: string = '';
  validCName: boolean = true;
  errMessCName: string = '';
  private queryParamsSubscription!: Subscription;
  isConfirmBack: boolean = false
  @Output() openModalConfirmBack = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private BaseService: BaseService,
    private fb: FormBuilder,
    private message: NzMessageService,
  ) {
  }

  ngOnInit() {
    this.initForm()
    this.getCAData()
    setTimeout(()=>{
      this.loading = false
    }, 1000)

    this.queryParamsSubscription = this.route.queryParams.subscribe(params => {

      if (params['mode'] !== 'edit') {
        this.updateState(2)
      }
    });
  }

  handleBack() {
    this.isConfirmBack = true
  }

  closeConfirmBack() {
    this.isConfirmBack = false
  }

  initForm() {
    this.caInputForm = this.fb.group({
      business_name: ['', Validators.required],
      business_pic: ['', Validators.required],
      job_title_pic: ['', Validators.required],
      business_email: ['', [Validators.required, Validators.email]],
      correspondence_name: ['', Validators.required],
      correspondence_phone_number: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
      correspondence_email: ['', [Validators.required, Validators.email]],
      correspondence_address: ['', [Validators.required, Validators.maxLength(100)]],
    })
  }

  getCAData() {
    const mode = localStorage.getItem('mode-ca-input');
    if (mode === 'create') return;
    else {
      this.BaseService.getData(CA_DATA)
        .pipe(takeUntil(this.destroy$))
      .subscribe((res: ResponseCustomType<any>) => {
        if (res.data) {
          this.caInputForm.patchValue({
            business_name: res.data.business_name,
            business_pic: res.data.business_pic,
            job_title_pic: res.data.job_title_pic,
            business_email: res.data.business_email,
            correspondence_name: res.data.correspondence_name,
            correspondence_phone_number: res.data.correspondence_phone_number,
            correspondence_email: res.data.correspondence_email,
            correspondence_address: res.data.correspondence_address,
          })
        }
      }
    )
    }
  }

  setRegex(event: any) {
    const regex = /^[a-zA-Z0-9+@._-]+$/g;
    return regex.test(event.key);
  }

  validateInput(event: any, fieldType: 'PicName' | 'PicJob' | 'CName') {
    const input = event.target as HTMLInputElement;
    const minLength = 3;
    const maxLength = 50;
    const fieldValidKey = `valid${fieldType}`;
    const fieldErrorKey = `errMess${fieldType}`;
    const fieldLabel = fieldType.includes('Name') ? 'Name' : 'Job title';

    if (input.value === '') {
      this[fieldValidKey] = false;
      this[fieldErrorKey] = `${fieldLabel} must be filled in`;
    } else if (input.value.length < minLength) {
      this[fieldValidKey] = false;
      this[fieldErrorKey] = `Minimum ${minLength} characters`;
    } else if (input.value.length > maxLength) {
      this[fieldValidKey] = false;
      this[fieldErrorKey] = `Maximum ${maxLength} characters`;
    } else {
      this[fieldValidKey] = true;
      this[fieldErrorKey] = '';
    }
  }

  preventNonNumeric(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;

    if (
      !/^\d$/.test(event.key) &&
      !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(
        event.key
      )
    ) {
      event.preventDefault();
    }

    if (input.value.length === 0 && event.key !== '8') {
      event.preventDefault();
    }
  }

  validateInputNumber(event: Event ): void {
    const input = event.target as HTMLInputElement;

    if (input.value.length === 0) {
      this.validBNumber = false;
      this.errMessBNumber = 'Phone number must be filled';
    } else if (!/^\d+$/.test(input.value)) {
      this.validBNumber = false;
      this.errMessBNumber = 'Must be a number';
    } else if (!input.value.startsWith('8')) {
      this.validBNumber = false;
      this.errMessBNumber = 'Phone number must start with number 8';
      input.value = '';
    } else if (input.value.length < 9) {
      this.validBNumber = false;
      this.errMessBNumber = 'Minimum 9 digits';
    } else if (input.value.length > 12) {
      this.validBNumber = false;
      this.errMessBNumber = 'Maximum 12 digits';
    } else {
      this.validBNumber = true;
      this.errMessBNumber = '';
    }
  }
  setRegexBName(event: any) {
    const regex = /^[a-zA-Z ]+$/g;
    return regex.test(event.key);
  }

  validateBusinessName() {
    const value = this.caInputForm.get('business_name')?.value || '';

    if (value === '') {
      this.validBName = false
      this.errMessBName = 'Business Name must be filled in';
    } else if (value.length < 3) {
      this.validBName = false;
      this.errMessBName = 'Minimum 3 characters';
    } else if (value.length > 50) {
      this.validBName = false;
      this.errMessBName = 'Maximum 50 characters';
    } else {
      this.validBName = true;
      this.errMessBName = '';
    }
  }

  validateEmail(event: Event, fieldType: 'BEmail' | 'CEmail') {
    const email = event.target as HTMLInputElement;
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const doubleSymbolRegex = /(\.\.|@@|--|\+\+|__|\.@|@\.|-@|@-|_\.|@\+|\+@)/;
    const fieldValidKey = `valid${fieldType}`;
    const fieldErrorKey = `errMess${fieldType}`;
    const fieldLabel = fieldType.includes('BEmail') ? ' Business Email' : 'Email';

    if (email.value === '') {
      this[fieldValidKey] = false;
      this[fieldErrorKey] = fieldLabel + ' must be filled in';
    } else {
      if (email.value.length < 10) {
        this[fieldErrorKey] = 'Minimum 10 characters';
        this[fieldValidKey] = false;
      } else if (email.value.length > 50) {
        this[fieldErrorKey] = 'Maximum 50 characters';
        this[fieldValidKey] = false;
      } else if (!regex.test(email.value)) {
        this[fieldValidKey] = false;
        this[fieldErrorKey] = fieldLabel + ' must be a valid email address';
      } else if (doubleSymbolRegex.test(email.value)) {
        this[fieldValidKey] = false;
        this[fieldErrorKey] = 'Email contains invalid consecutive symbols';
    } else {
        const atIndex = email.value.indexOf('@');
        const lastDotIndex = email.value.lastIndexOf('.');
        const dotBeforeAt = email.value.substring(0, atIndex).includes('.');
        const dotAfterAt = email.value.substring(atIndex + 1).includes('.');

        if (
          atIndex === -1 ||
          lastDotIndex === -1 ||
          lastDotIndex === email.value.length - 1 ||
          email.value[atIndex + 1] === '.' ||
          !dotAfterAt
        ) {
          this[fieldErrorKey] = fieldLabel + ' must be a valid email address';
          this[fieldValidKey] = false;
        } else {
          this[fieldValidKey] = true;
          this[fieldErrorKey] = '';
        }
      }
    }
  }

  validateAddress(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    const regex = /^[a-zA-Z0-9.\-():=/, ]+$/;

    if (value.length === 0) {
      this.validAddress = false;
      this.errMessAddress = 'Address cannot be empty';
    } else if (!regex.test(value)) {
      this.validAddress = false;
      this.errMessAddress = 'There are characters that are not allowed';
    } else if (value.length > 0 && value.length < 10) {
      this.validAddress = false;
      this.errMessAddress = 'Minimum 10 characters';
    } else if (value.length > 100) {
      this.validAddress = false;
      this.errMessAddress = 'Maximum 100 characters';
    }else {
      this.validAddress = true;
      this.errMessAddress = '';
    }
  }

  onSubmit() {
    const mode = localStorage.getItem('mode-ca-input');

    const formValue = {
      ...this.caInputForm.value,
      correspondence_phone_number: String(this.caInputForm.value.correspondence_phone_number)
    };
    const request = mode === 'create'
      ? this.BaseService.postData(CA_DATA, formValue)
      : this.BaseService.putData(CA_DATA, formValue);

    request.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.message.success('CA Data has been submitted');
        this.updateState(3);
        this.router.navigate(['/document']);
        localStorage.removeItem('mode-ca-input');
      },
      error: (err) => {
        console.error(err);
        this.message.error(err.error.data.errors);
      },
    });
  }


  updateState(v: number): void {
      const params = {
        document_type: 'CA',
        state: v,
      }
      this.BaseService.putData(DOC_STATE, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: ResponseCustomType<DocumentStateResponseType[]>) => {
        },
        (err) => {
          console.error(err)
        }
      )
  }

  get isFormInvalid(): boolean {
    return (
      this.caInputForm.invalid ||
      this.errMessBName !== '' ||
      this.errMessAddress !== '' ||
      this.errMessBNumber !== '' ||
      this.errMessBEmail !== '' ||
      this.errMessCEmail !== '' ||
      this.errMessPicName !== '' ||
      this.errMessPicJob !== '' ||
      this.errMessCName !== ''
    );
  }
}
