<div class="card mt-2">
  <div class="card-header">Finance</div>
  <div class="card-body">
    <div>
      <nz-skeleton class="d-flex" [nzLoading]="loading" [nzActive]="true" [nzParagraph]="false">
        <ul class="nav gap-3 d-flex">
          <li class="nav-item active">
            <a class="nav-link active" aria-current="page">Balance History</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/finance/commission"
              >Commission Withdrawal</a
            >
          </li>
        </ul>
      </nz-skeleton>
    </div>
    <div class="mt-5 mb-3 row">
      <div class="col-xl-4 col">
        <nz-skeleton class="d-flex" [nzLoading]="loading" [nzActive]="true">
          <div class="card shadow">
            <div class="p-3">
              <div class="d-flex align-items-center gap-1">
                <div class="card-title d-flex align-self-center">Balance</div>
                <div
                  class="d-flex align-self-center"
                  placement="top"
                  ngbPopover="Your current balance"
                  triggers="mouseenter:mouseleave"
                >
                  <i class="icon-info-circle align-self-center"></i>
                </div>
              </div>
              <div class="nominal">
                <b>{{ (balance.balance || 0) | idr }}</b>
              </div>
            </div>
            <div
              class="bg-footer-card p-3 d-flex flex-row justify-content-center align-items-center gap-4"
            >
              <button
                class="btn p-0 text-white d-flex align-items-center gap-2"
                (click)="handleTopUp()"
              >
                <i class="icon icon-send-square"></i>
                <div class="d-flex text-card-button">Top Up</div>
              </button>
              <button
                *ngIf="pinStatus === true && bankList !== null"
                class="btn p-0 text-white border-0 d-flex flex-row gap-2 align-items-center justify-content-center"
                [disabled]="isSandBoxMode"
                (click)="handleTarikSaldo($event)"
              >
                <div><i class="icon icon-receive-square"></i></div>
                <div class="d-flex text-card-button">Withdrawl</div>
              </button>
              <button
                *ngIf="pinStatus === true && bankList === null"
                class="btn p-0 text-white border-0 d-flex flex-row gap-2 align-items-center justify-content-center"
                [disabled]="isSandBoxMode"
                (click)="handleNoRek($event)"
              >
                <div><i class="icon icon-receive-square"></i></div>
                <div class="d-flex text-card-button">Withdrawl</div>
              </button>
              <button
                *ngIf="pinStatus === false && bankList !== null"
                class="btn p-0 text-white border-0 d-flex flex-row gap-2 align-items-center justify-content-center"
                [disabled]="isSandBoxMode"
                (click)="handleNoPIN($event)"
              >
                <div><i class="icon icon-receive-square"></i></div>
                <div class="d-flex text-card-button">Withdrawl</div>
              </button>
              <button
                *ngIf="pinStatus === false && bankList === null"
                class="btn p-0 text-white border-0 d-flex flex-row gap-2 align-items-center justify-content-center"
                [disabled]="isSandBoxMode"
                (click)="handleNoPIN($event)"
              >
                <div><i class="icon icon-receive-square"></i></div>
                <div class="d-flex text-card-button">Withdrawl</div>
              </button>
              <a [routerLink]="['/finance/balance/detail']">
                <button
                  class="d-flex text-white flex-row gap-2 align-items-center justify-content-center btn p-0"
                >
                  <div><i class="icon icon-document-text"></i></div>
                  <div class="d-flex text-card-button">Detail</div>
                </button>
              </a>
            </div>
          </div>
        </nz-skeleton>
      </div>
    </div>
    <div class="divider"></div>
    <nz-skeleton class="d-flex col-12 mt-3" [nzLoading]="loading" [nzActive]="true">
    <div class="mt-3 col-12">
        <app-table-riwayat-penarikan-saldo></app-table-riwayat-penarikan-saldo>
      </div>
    </nz-skeleton>
  </div>
</div>
