<nav
  class="navbar fixed-top navbar-light bg-white ps-3 pe-3 d-flex justify-content-between"
>
  <span class="navbar-brand mb-0 h1">
    <button class="btn" (click)="collapseClick()">
      <img src="assets/icons/black/menu.svg" />
    </button>
    <img class="ms-3 img-logo" height="30px" src="assets/logo.svg" alt="" />
  </span>
  <div class="flex-row d-flex align-items-center gap-3">
    <div *ngIf="detailProfile" class="d-flex flex-column" style="width: auto">
      <span class="title"> Hallo {{ detailProfile.name || 'User' }} </span>
      <div class="d-flex flex-column" *ngIf="detailProfile?.rajaongkir_level === 3 || detailProfile?.is_openapi === true">
        <span *ngIf="!profileMode" class="mode-on-sandbox px-1"
          >Sandbox Mode</span
        >
        <span *ngIf="profileMode" class="mode-on-live px-1">Live Mode</span>
      </div>
    </div>
    <div ngbDropdown display="dynamic">
      <img
        *ngIf="detailProfile"
        [src]="
          detailProfile.photo_profile_url
            ? detailProfile.photo_profile_url
            : 'https://cdn.pixabay.com/photo/2014/04/03/10/32/businessman-310819_960_720.png'
        "
        class="main-profile-img rounded-circle"
        style="width: 35px; cursor: pointer; height: 35px"
        alt="Avatar"
        id="dropdownBasic1"
        ngbDropdownToggle
      />
      <div
        ngbDropdownMenu
        aria-labelledby="dropdownBasic1"
        class="dropdown-menu dropdown-menu-end drop-profile shadow-lg"
        style="width: 300px"
      >
        <div class="py-2 px-4">
          <div *ngIf="detailProfile?.rajaongkir_level === 3 || detailProfile?.is_openapi === true">
            <span class="profile">Profile</span>
            <hr />
            <div class="d-flex align-items-center gap-3">
              <div class="d-flex flex-column col-4">
                <small
                  class="px-1"
                  [ngClass]="profileMode ? 'mode-non' : 'mode-on-sandbox'"
                  >Mode</small
                >
                <span
                  class=""
                  [ngClass]="profileMode ? 'sandbox-off' : 'sandbox-on'"
                  >Sandbox</span
                >
              </div>
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="switchMode"
                  [checked]="profileMode"
                  [(ngModel)]="profileMode"
                  style="width: 50px; height: 30px"
                  (change)="toggleEnvironment()"
                />
              </div>
              <div class="d-flex flex-column col-4">
                <small
                  class="px-1"
                  [ngClass]="profileMode ? 'mode-on-live' : 'mode-non'"
                  >Mode</small
                >
                <span class="" [ngClass]="profileMode ? 'live-on' : 'live-off'"
                  >Live</span
                >
              </div>
            </div>
            <hr />
          </div>
          <button (click)="logout()" ngbDropdownItem class="p-0">
            <img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/logout.svg"
              alt=""
              width="28px"
            />
            <span class="mx-3 text-primary">Logout</span>
          </button>
        </div>
      </div>
    </div>
    <img class="d-none" src="assets/icons/black/notification.svg" />
  </div>
</nav>

<app-modal-alert-add-phone [isVisibleAlert]="openModalAlertNumber"></app-modal-alert-add-phone>
<app-modal-alert-switch [isVisibleSwitch]="openModalAlertSwitch" (isVisibleSwitchEvent)="closeSwitchMode()"/>
