<div class="card p-4 d-flex flex-column mt-2">
  <nz-skeleton
    [ngClass]="skeleton ? 'p-3' : 'p-0'"
    [nzLoading]="skeleton"
    [nzActive]="true"
    [nzParagraph]="false"
  >
    <div class="py-1 title">Simulator</div>
    <span class="text-grey">Page Simulator</span>
    <div class="mt-4 d-flex flex-column gap-3">
      <div class="d-flex flex-column flex-lg-row">
        <span class="col-12 col-lg-3">Order Status</span>
        <span
          [ngClass]="classStatus(statusOrder)"
          class="col-12 col-lg-8 text-capitalize"
          >{{ labelStatus(statusOrder) || "-" }}</span
        >
      </div>
      <div class="d-flex flex-column flex-lg-row">
        <span class="col-12 col-lg-3">Order Number</span>
        <div class="col-12 col-lg-4 d-flex flex-column flex-lg-row gap-2">
          <input
            [(ngModel)]="orderNumber"
            class="col-8"
            nz-input
            type="text"
            placeholder="Ex: KOM12345"
            (keypress)="setRegex($event)"
            (input)="validateInput($event, 'order')"
          />
          <button
            [disabled]="!orderNumber || !awb || !isValidAwb || !isValidOrder"
            [ngClass]="{ 'btn-secondary': !orderNumber || !awb || !isValidAwb || !isValidOrder }"
            class="col-4 btn btn-primary text-white text-bold"
            style="font-size: 12px"
            (click)="checkStatus()"
          >
            Check Status
          </button>
        </div>
      </div>
      <div class="d-flex flex-column flex-lg-row">
        <span class="col-12 col-lg-3">AWB Number</span>
        <div class="col-12 col-lg-4 d-flex">
          <input
            [(ngModel)]="awb"
            nz-input
            type="text"
            placeholder="Ex: KOM12345"
            (keypress)="setRegex($event)"
            (input)="validateInput($event, 'awb')"
          />
        </div>
      </div>
      <div class="d-flex flex-column flex-lg-row">
        <span class="col-12 col-lg-3">Action Delievery</span>
        <div class="col-12 col-lg-4 d-flex flex-column flex-lg-row gap-2">
          <ng-select
            class="col-12 text-capitalize"
            [items]="listStatus"
            bindLabel="label"
            bindValue="value"
            [(ngModel)]="selectedStatus"
            [searchable]="false"
            placeholder="Select status condition here"
          >
            <ng-container *ngFor="let item of listStatus; let i = index">
              <ng-option [value]="item.value">
                {{ item.label }}
              </ng-option>
            </ng-container>
          </ng-select>
          <button
            [disabled]="
              statusOrder === 'return_done' ||
              statusOrder === 'received' ||
              !orderNumber ||
              !awb ||
              !selectedStatus ||
              !statusOrder
            "
            [ngClass]="{
              'btn-secondary':
                statusOrder === 'return_done' ||
                statusOrder === 'received' ||
                !orderNumber ||
                !awb ||
                !selectedStatus ||
                !statusOrder
            }"
            class="col-4 btn btn-primary text-white text-bold"
            style="font-size: 12px"
            (click)="changeStatus()"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </nz-skeleton>
</div>
