<div class="d-flex flex-column p-4" [formGroup]="businessForm">
  <h6 class="text-bold">Business Escalation Information</h6>
  <hr />
  <div>
    <table class="table">
      <thead class="bg-light">
        <tr>
          <th></th>
          <th>Operational Department</th>
          <th>Finance Department</th>
          <th>Technology Department</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Name<span class="text-danger">*</span></td>
          <td>
            <input
              nz-input
              placeholder="e.g John Doe"
              formControlName="operational_name"
              type="text"
              (keypress)="setRegexName($event)"
              (input)="validateInput($event, 'OName')"
              [nzStatus]="validOName ? '' : 'error'"
            />
            <span class="d-flex align-items-center gap-1 text-danger">
              <img
                *ngIf="errMessOName !== ''"
                src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                alt="warning"
                style="max-width: 10px; max-height: 10px"
              />
              {{ errMessOName }}</span
            >
          </td>
          <td>
            <input
              nz-input
              placeholder="e.g John Doe"
              formControlName="finance_name"
              (keypress)="setRegexName($event)"
              type="text"(input)="validateInput($event, 'FName')"
              [nzStatus]="validFName ? '' : 'error'"
            />
            <span class="d-flex align-items-center gap-1 text-danger">
              <img
                *ngIf="errMessFName !== ''"
                src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                alt="warning"
                style="max-width: 10px; max-height: 10px"
              />
              {{ errMessFName }}</span
            >
          </td>
          <td>
            <input
              nz-input
              placeholder="e.g John Doe"
              formControlName="technology_name"
              type="text"
              (keypress)="setRegexName($event)"
              (input)="validateInput($event, 'TName')"
              [nzStatus]="validTName ? '' : 'error'"
            />
            <span class="d-flex align-items-center gap-1 text-danger">
              <img
                *ngIf="errMessTName !== ''"
                src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                alt="warning"
                style="max-width: 10px; max-height: 10px"
              />
              {{ errMessTName }}</span
            >
          </td>
        </tr>
        <tr>
          <td>Job Title<span class="text-danger">*</span></td>
          <td>
            <input
              nz-input
              placeholder="e.g Manager"
              formControlName="operational_job_title"
              type="text"
              (input)="validateInput($event, 'OJob')"
              (keypress)="setRegexBName($event)"
              [nzStatus]="validOJob ? '' : 'error'"
            />
            <span class="d-flex align-items-center gap-1 text-danger">
              <img
                *ngIf="errMessOJob !== ''"
                src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                alt="warning"
                style="max-width: 10px; max-height: 10px"
              />
              {{ errMessOJob }}</span
            >
          </td>
          <td>
            <input
              nz-input
              placeholder="e.g Manager"
              formControlName="finance_job_title"
              type="text"
              (input)="validateInput($event, 'FJob')"
              (keypress)="setRegexBName($event)"
              [nzStatus]="validFJob ? '' : 'error'"
            />
            <span class="d-flex align-items-center gap-1 text-danger">
              <img
                *ngIf="errMessFJob !== ''"
                src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                alt="warning"
                style="max-width: 10px; max-height: 10px"
              />
              {{ errMessFJob }}</span
            >
          </td>
          <td>
            <input
              nz-input
              placeholder="e.g Manager"
              formControlName="technology_job_title"
              type="text"
              (input)="validateInput($event, 'TJob')"
              (keypress)="setRegexBName($event)"
              [nzStatus]="validTJob ? '' : 'error'"
            />
            <span class="d-flex align-items-center gap-1 text-danger">
              <img
                *ngIf="errMessTJob !== ''"
                src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                alt="warning"
                style="max-width: 10px; max-height: 10px"
              />
              {{ errMessTJob }}</span
            >
          </td>
        </tr>
        <tr>
          <td>Phone Number<span class="text-danger">*</span></td>
          <td>
            <input
              nz-input
              placeholder="e.g 812 3456 7890"
              formControlName="operational_phone_number"
              type="text"
              (keypress)="preventNonNumeric($event)"
              (input)="validateInputNumber($event, 'ONumber')"
              [nzStatus]="validONumber ? '' : 'error'"
            />
            <span class="d-flex align-items-center gap-1 text-danger">
              <img
                *ngIf="errMessONumber !== ''"
                src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                alt="warning"
                style="max-width: 10px; max-height: 10px"
              />
              {{ errMessONumber }}</span
            >
          </td>
          <td>
            <input
              nz-input
              placeholder="e.g 812 3456 7890"
              formControlName="finance_phone_number"
              type="text"
              (keypress)="preventNonNumeric($event)"
              (input)="validateInputNumber($event, 'FNumber')"
              [nzStatus]="validFNumber ? '' : 'error'"
            />
            <span class="d-flex align-items-center gap-1 text-danger">
              <img
                *ngIf="errMessFNumber !== ''"
                src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                alt="warning"
                style="max-width: 10px; max-height: 10px"
              />
              {{ errMessFNumber }}</span
            >
          </td>
          <td>
            <input
              nz-input
              placeholder="e.g 812 3456 7890"
              formControlName="technology_phone_number"
              type="text"
              (keypress)="preventNonNumeric($event)"
              (input)="validateInputNumber($event, 'TNumber')"
              [nzStatus]="validTNumber ? '' : 'error'"
            />
            <span class="d-flex align-items-center gap-1 text-danger">
              <img
                *ngIf="errMessTNumber !== ''"
                src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                alt="warning"
                style="max-width: 10px; max-height: 10px"
              />
              {{ errMessTNumber }}</span
            >
          </td>
        </tr>
        <tr>
          <td>Email Address<span class="text-danger">*</span></td>
          <td>
            <input
              nz-input
              placeholder="e.g john.doe@example.com"
              formControlName="operational_email"
              type="email"
              (keypress)="setRegex($event)"
              (keydown.enter)="$event.preventDefault()"
              (input)="validateEmail($event, 'OEmail')"
              [nzStatus]="validOEmail ? '' : 'error'"
            />
            <span class="text-danger">
              <img
                *ngIf="errMessOEmail !== ''"
                src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                alt="warning"
                style="max-width: 10px; max-height: 10px"
              />
              {{ errMessOEmail }}</span
            >
          </td>
          <td>
            <input
              nz-input
              placeholder="e.g john.doe@example.com"
              formControlName="finance_email"
              type="email"
              (keypress)="setRegex($event)"
              (keydown.enter)="$event.preventDefault()"
              (input)="validateEmail($event, 'FEmail')"
              [nzStatus]="validFEmail ? '' : 'error'"
            />
            <span class="text-danger">
              <img
                *ngIf="errMessFEmail !== ''"
                src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                alt="warning"
                style="max-width: 10px; max-height: 10px"
              />
              {{ errMessFEmail }}</span
            >
          </td>
          <td>
            <input
              nz-input
              placeholder="e.g john.doe@example.com"
              formControlName="technology_email"
              type="email"
              (keypress)="setRegex($event)"
              (keydown.enter)="$event.preventDefault()"
              (input)="validateEmail($event, 'TEmail')"
              [nzStatus]="validTEmail ? '' : 'error'"
            />
            <span class="text-danger">
              <img
                *ngIf="errMessTEmail !== ''"
                src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                alt="warning"
                style="max-width: 10px; max-height: 10px"
              />
              {{ errMessTEmail }}</span
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="d-flex align-self-end gap-2 mt-5">
    <button class="btn btn-outline-secondary" (click)="onBack()">Back</button>
    <button
      type="submit"
      class="btn text-white"
      [disabled]="isFormInvalid"
      [ngClass]="{
        'btn-secondary': isFormInvalid,
        'btn-primary': !isFormInvalid
      }"
      (click)="onSubmit()"
    >
      Next
    </button>
  </div>
</div>
