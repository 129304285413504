<div class="d-flex flex-row justify-content-between align-items-center header">
  <div class="title-header d-flex align-items-center">
    Balance Withdrawal History
  </div>
  <div>
    <form class="row row-cols-sm-auto">
      <div class="col-12">
        <div class="form-control d-flex flex-row">
          <input
            type="text"
            class="w-100"
            ngxDaterangepickerMd
            [(ngModel)]="selected"
            [ngModelOptions]="{ standalone: true }"
            [showCustomRangeLabel]="false"
            [keepCalendarOpeningWithRange]="true"
            [alwaysShowCalendars]="true"
            [ranges]="ranges"
            [linkedCalendars]="true"
            [isInvalidDate]="isInvalidDate"
            [showCancel]="true"
            opens="left"
            drops="down"
            [locale]="setlocale"
            (change)="onChangeDate($event)"
            style="border: none; font-size: 14px"
            readonly
          />
          <img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/calendar.svg"
            alt=""
          />
        </div>
      </div>
    </form>
  </div>
</div>
<div class="d-flex flex-column table-wrapper" style="overflow-x: auto">
  <nz-table class="table table-hover table-responsive" [nzData]="wdList"
  [nzShowPagination]="false"
  class="mt-3"
  [nzLoading]="loading">
    <thead class="sticky-top">
      <tr>
        <th scope="col">Date</th>
        <th scope="col">Withdrawal Purpose</th>
        <th scope="col">Status</th>
        <th scope="col">Total Withdrawal</th>
        <th scope="col">Detail</th>
      </tr>
    </thead>
        <tbody class="tabb-body" style="overflow: auto">
          <tr *ngFor="let item of wdList; let i = index">
            <td>
              <div class="d-flex flex-column">
                <div>{{ item.date | date : "dd MMMM yyyy" : "id-ID" }}</div>
                <div class="text-muted">
                  {{ item.date.slice(10, -3) || "-" }} WIB
                </div>
              </div>
            </td>
            <td>
              <div>{{ item.bank_owner || "-" }}</div>
              <div class="text-muted">
                {{ item.bank_code }} - ******{{ bankNumber.slice(-3) || "-" }}
              </div>
            </td>
            <td>
              <div class="d-flex flex-row gap-2 align-items-center">
                <div *ngIf="item.status === 'approved'" class="success">
                  Approved
                </div>
                <div *ngIf="item.status === 'request'" class="request">Request</div>
                <div *ngIf="item.status === 'review'" class="pending">
                  Review&nbsp;
                  <div
                    class="d-flex align-self-center"
                    placement="top"
                    [ngbPopover]="item.note"
                    triggers="mouseenter:mouseleave"
                  >
                    <i class="icon-info-circle d-flex align-self-center"></i>
                  </div>
                </div>
                <div *ngIf="item.status === 'rejected'" class="failed">
                  Rejected&nbsp;
                  <div
                    class="d-flex align-self-center"
                    placement="top"
                    [ngbPopover]="item.note"
                    triggers="mouseenter:mouseleave"
                  >
                    <i class="icon-info-circle d-flex align-self-center"></i>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <b>{{ item.nominal | currency : "IDR" : true : "1.0" }}</b>
            </td>
            <td>
              <button (click)="moveDetail(i)" class="btn" style="color: #08a0f7">
                See Detail
              </button>
            </td>
          </tr>
        </tbody>
  </nz-table>
</div>
