import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RECAPTCHA_TOKEN } from 'src/app/constants/endpoint.const';
import { takeUntil, Subject } from 'rxjs';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ModalRegisterSuccessComponent } from './modal-register-success/modal-register-success.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from 'src/app/services/api/user.service';
import { Title } from '@angular/platform-browser';
import { ModalExistingEmailComponent } from './modal-alert-existing/modal-existing-email.component';
import { validatePassword } from 'src/app/constants/auth-config.const';
import { WhatsappService } from 'src/app/services/whatsapp.service';
import { FormValidationService } from 'src/app/services/form-validation.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
  formGroup: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(50),
    ]),
    fullname: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50),
    ]),
    address: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(100),
    ]),
    telephone: new FormControl('', [
      Validators.required,
      Validators.minLength(9),
      Validators.maxLength(12),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(32),
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(32),
    ]),
    checked: new FormControl(false, [Validators.required]),
  });

  isLoading: boolean = false;
  isShowPassword: boolean = false;
  isShowPassword2: boolean = false;
  destroy$ = new Subject<void>();
  errorUser = '';
  checked: boolean = false;

  eyeOn =
    'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye.svg';
  eyeOff =
    'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye-slash.svg';

  validationPassword = validatePassword;
  visibleValPass: boolean = false;
  isValidPass: boolean = true;
  errMessPass: string = '';
  errMessPass2: string = '';
  isErrPass: boolean = false;
  captchaToken: string = '';
  addressLength: number = 0;
  validAddress: boolean = true;
  errMessAddress: string = '';
  validNumber: boolean = true;
  errMessNumber: string = '';
  errMessFullName: string = '';
  validFullName: boolean = true;
  errMessEmail: string = '';
  validEmail: boolean = true;
  confirmModal?: NzModalRef;
  debounceTimeout: any;

  constructor(
    private userService: UserService,
    private renderer: Renderer2,
    private modalService: NzModalService,
    private message: NzMessageService,
    private titleService: Title,
    private whatsappService: WhatsappService,
    private formValidation: FormValidationService
  ) {}

  ngOnInit(): void {
    this.addScriptToHead(
      `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_TOKEN}`
    );
    this.titleService.setTitle('RajaOngkir - Registration');
  }

  checkEvent() {
    this.checked = !this.checked;
  }

  validateEmail() {
    const value = this.formGroup.get('email')?.value || '';

    const result = this.formValidation.validateEmail(value)
    this.validEmail = result.valid;
    this.errMessEmail = result.message;
  }

  validateFullname() {
    const value = this.formGroup.get('fullname')?.value || '';
    const regex = /^[a-zA-Z ]+$/g;

    if (value === '') {
      this.validFullName = false;
      this.errMessFullName = 'Full Name/Company must be filled';
    } else if (value.length < 3) {
      this.validFullName = false;
      this.errMessFullName = 'Minimum 3 characters';
    } else if (value.length > 50) {
      this.validFullName = false;
      this.errMessFullName = 'Maximum 50 characters';
    } else if (!regex.test(value)) {
      this.validFullName = false;
      this.errMessFullName = 'There are characters that are not allowed';
    } else {
      this.validFullName = true;
      this.errMessFullName = '';
    }
  }

  addScriptToHead(src: string): void {
    const script = this.renderer.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    script.async = true;
    this.renderer.appendChild(document.head, script);
  }

  disableEnterKey(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  setRegex(event: any) {
    const regex = /^[a-zA-Z0-9+@._-]+$/g;
    return regex.test(event.key);
  }
  setRegexName(event: any) {
    const regex = /^[a-zA-Z ]+$/g;
    return regex.test(event.key);
  }

  setRegexPassword(event: any) {
    const regex = /^[a-zA-Z0-9`~!()\-_+={}\[\]:;'.<>\/]+$/g;
    return regex.test(event.key);
  }

  get form() {
    return {
      formGroup: this.formGroup,
      email: this.formGroup.get('email'),
      password: this.formGroup.get('password'),
    };
  }
  preventNonNumeric(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;

    if (
      !/^\d$/.test(event.key) &&
      !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(
        event.key
      )
    ) {
      event.preventDefault();
    }

    if (input.value.length === 0 && event.key !== '8') {
      event.preventDefault();
    }
  }

  validateInput(event: Event): void {
    clearTimeout(this.debounceTimeout);
    const input = event.target as HTMLInputElement;

    const result = this.formValidation.validatePhoneNumber(input.value)
    
    this.debounceTimeout = setTimeout(() => {
      if (input.value.length > 12) {
        input.value = input.value.slice(0, 12);
      }
      this.validNumber = result.valid;
      this.errMessNumber = result.message;
  
      if (this.validNumber) {
        this.checkWA();
      }
    }, 500);
  }

  async checkWA() {
    const result = await this.whatsappService.checkWA(this.formGroup.get('telephone')?.value);
    this.validNumber = result.valid;
    this.errMessNumber = result.message;
  }

  validatePassword() {
    this.visibleValPass = true;
    const value = this.formGroup.get('password')?.value || '';
  
    const result = this.formValidation.validatePassword(value, this.validationPassword);
    
    this.isValidPass = result.isValid;
    this.errMessPass = result.isValid ? '' : 'Password does not comply with the provisions';
  
    if (value.length === 0) {
      this.errMessPass = 'Password must be filled';
    }
  
    const value2 = this.formGroup.get('confirmPassword')?.value || '';
    this.errMessPass2 = value !== value2 && value2 !== '' ? 
      'Make sure the password confirmation is the same as the previous password.' : '';
  
    this.formGroup.get('password')?.setErrors(
      result.errors.length > 0 ? { passwordInvalid: result.errors.join(', ') } : null
    );
  }

  validateAddress() {
    const value = this.formGroup.get('address')?.value || '';
    const regex = /^[a-zA-Z0-9.\-():=/, ]+$/;

    this.addressLength = value.length;

    if (value.length === 0) {
      this.validAddress = false;
      this.errMessAddress = 'Address cannot be empty';
    } else if (!regex.test(value)) {
      this.validAddress = false;
      this.errMessAddress = 'There are characters that are not allowed';
    } else if (value.length > 0 && value.length < 6) {
      this.validAddress = false;
      this.errMessAddress = 'Minimum 6 characters';
    } else {
      this.validAddress = true;
      this.errMessAddress = '';
    }
  }

  confirmPassword() {
    const value1 = this.formGroup.get('password')?.value || '';
    const value2 = this.formGroup.get('confirmPassword')?.value || '';

    if (value1 !== value2 && value2 !== '') {
      this.errMessPass2 =
        'Make sure the password confirmation is the same as the previous password.';
    } else if (value2.length === 0) {
      this.errMessPass2 = 'Password confirmation must be filled in';
    } else {
      this.errMessPass2 = '';
    }
  }

  async getCaptchaToken(event: Event) {
    event.preventDefault();

    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(
          `${RECAPTCHA_TOKEN}`,
          { action: 'REGISTER_RAJAONGKIR' }
        );
        this.captchaToken = token;
        this.handleRegister(token);
      } catch (error) {
        console.error('Error executing reCAPTCHA:', error);
        this.message.create('error', 'Error executing reCAPTCHA');
      }
    });
  }
  handleRegister(e: string) {
    if (this.formGroup.valid) {
      const params = {
        email: this.formGroup.get('email')?.value,
        name: this.formGroup.get('fullname')?.value,
        phone_number: '0' + this.formGroup.get('telephone')?.value,
        address: this.formGroup.get('address')?.value,
        password: this.formGroup.get('password')?.value,
        confirm_password: this.formGroup.get('confirmPassword')?.value,
        recaptcha_token: e,
      };

      this.isLoading = true;
      this.userService
        .register(params)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response: any) => {
            this.modalService.create({
              nzContent: ModalRegisterSuccessComponent,
              nzFooter: null,
            });
            this.isLoading = false;
          },
          (err) => {
            this.isLoading = false;
            if (err.error.data.errors === 'Email already exist') {
              this.modalService.create({
                nzContent: ModalExistingEmailComponent,
                nzFooter: null,
              });
              this.validEmail = false;
            } else {
              this.message.create(
                'error',
                err.error.data.errors || 'Registration Failed, Please Try Again'
              );
            }
          }
        );
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
