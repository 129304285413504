<nz-modal
  [(nzVisible)]="isShowConfirm"
  nzCentered
  [nzFooter]="null"
  nzCloseIcon="null"
>
  <ng-container *nzModalContent>
    <div class="text-center d-flex flex-column p-4">
      <div>
        <img
          src="https://storage.googleapis.com/komerce/assets/icons/popup-warning.png"
          alt="warning"
          width="100px"
        />
        <div class="title mt-3">Are you sure you want to go back?</div>
      </div>
      <span class="mt-3 text-grey">
        Any unsaved changes to the {{ docType }} data will be lost
      </span>
      <div class="d-flex justify-content-betweeen mt-3 gap-2">
        <button
          class="btn btn-primary text-white col-6"
          (click)="handleCancel()"
        >
          No
        </button>
        <button
          class="btn btn-outline-primary col-6"
          (click)="handleContinue()"
        >
          Yes
        </button>
      </div>
    </div>
  </ng-container>
</nz-modal>
