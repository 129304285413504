import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { TEST_CASE, UAT_DATA } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-uat-calculate-shipping',
  standalone: false,
  templateUrl: './uat-calculate-shipping.component.html',
  styleUrl: './uat-calculate-shipping.component.scss',
})
export class UatCalculateShippingComponent {
  destroy$: Subject<void> = new Subject<void>();
  uatCalculate: any[] = [];
  calculateForm!: FormGroup;
  current: number = 2;

  constructor(
    private baseService: BaseService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.getUatTestCase();
    this.initForm();
    this.getUatData()
  }

  initForm() {
    const formControls: { [key: string]: any[] } = {};
    for (let i = 2; i <= 5; i++) {
      formControls[`curl_calculate${i}`] = [null, [Validators.required]];
      formControls[`result_calculate${i}`] = [null, [Validators.required]];
    }
    this.calculateForm = this.fb.group(formControls);
  }


  next(): void {
    this.submitUat()
    this.router.navigate(['/document/uat-data-input'], { queryParams: { step: this.current + 1 } });
  }
  previus(): void {
    this.router.navigate(['/document/uat-data-input'], { queryParams: { step: this.current - 1 } });
  }

  getUatTestCase() {
    this.baseService
      .getData(TEST_CASE + '/calculate')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {
          this.uatCalculate = res.data;
        },
        (err) => {}
      );
  }

  getUatData() {
    this.baseService.getData(`${UAT_DATA}/calculate`)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          res.data.forEach((item: any, index: number) => {
            if (this.calculateForm && index < 5) {
              this.calculateForm.patchValue({
                [`curl_calculate${index + 2}`]: item?.curl,
                [`result_calculate${index + 2}`]: item?.result,
              });
            }
            if (this.uatCalculate && this.uatCalculate[index]) {
              this.uatCalculate[index].notes = item?.notes;
            }
          });
        },
        error: (err) => console.error(err.error.data.errors),
      });
  }


  submitUat() {
    const input = this.uatCalculate.slice(0, 4).map((item, index) => ({
      test_case_id: item.id,
      curl: this.calculateForm.get(`curl_calculate${index + 2}`)?.value,
      result: this.calculateForm.get(`result_calculate${index + 2}`)?.value,
    }));

    this.baseService.postData(UAT_DATA, { input })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          localStorage.setItem('uat-calculate-shipping', 'complete')
        },
        error: (err) => console.error(err.error.data.errors),
      });
  }

}
