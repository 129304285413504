import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ProfileService } from 'src/app/services/profile.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Output() isActive = new EventEmitter();
  @Input() active: boolean = false;

  public isOpen = true;
  activeButton: string = 'dashboard';
  isRajaOngkir: boolean = false;
  profileData!: any;
  private profileSubscription!: Subscription;
  isShowDoc: boolean = true;
  isShowBillings: boolean = true;
  isShowSimulator: boolean = true;

  icons = {
    settings: {
      active:
        'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/setting-2.svg',
      default:
        'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/setting-2.svg',
    },
    arrow: {
      up: 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-up-2.svg',
      down: 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg',
    },
  };

  menuItems = [
    {
      name: 'dashboard',
      label: 'Dashboard',
      link: '/dashboard',
      icon: {
        active:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/home-hashtag.svg',
        default:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/home-hashtag.svg',
      },
    },
    {
      name: 'Order Data',
      label: 'Order Data',
      link: '/order-data',
      icon: {
        active:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/bag.svg',
        default:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/bag.svg',
      },
    },
    {
      name: 'Finance',
      label: 'Finance',
      link: '/finance/balance',
      icon: {
        active:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/empty-wallet.svg',
        default:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/empty-wallet.svg',
      },
    },
    {
      name: 'Billing',
      label: 'Billing',
      link: '/billings',
      icon: {
        active:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/bill.svg',
        default:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/bill.svg',
      },
    },
    {
      name: 'Issues',
      label: 'Issues',
      link: '/ticketing',
      icon: {
        active:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/message-remove.svg',
        default:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/message-remove.svg',
      },
    },
    {
      name: 'Simulator',
      label: 'Simulator',
      link: '/simulator',
      icon: {
        active:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/truck-fast.svg',
        default:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/truck-fast.svg',
      },
    },
  ];

  subMenuItems = [
    {
      name: 'Profile',
      label: 'Profile',
      link: '/profile',
    },
    {
      name: 'Document',
      label: 'Document',
      link: '/document',
    },
    {
      name: 'PIN',
      label: 'PIN',
      link: '/pin',
    },
    {
      name: 'Bank Account',
      label: 'Bank Account',
      link: '/bank-account',
    },
  ];

  constructor(
    private elementRef: ElementRef,
    private route: Router,
    private profileService: ProfileService,
    private cdr: ChangeDetectorRef,
  ) {
    route.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const url = val.url || '';
        this.setActiveButtonFromUrl(url);
      }
    });
  }

  inside = false;

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.isActive.emit(false);
    }
  }

  ngOnInit() {
    const mode = localStorage.getItem('mode')
    this.profileSubscription = this.profileService.profileData$.subscribe((data) => {
      if (data) {
        this.profileData = data;
  
        // Atur logika berdasarkan data yang diterima
        this.isShowDoc = false;
        this.isShowBillings = true; // Default Billing tetap muncul
        this.isShowSimulator = false; // Default Billing tetap muncul
  
        if (
          this.profileData.rajaongkir_level === 0 ||
          this.profileData.is_openapi === true ||
          this.profileData.is_lifetime === true
        ) {
          this.isShowDoc = true; // Tampilkan menu Document
          this.isShowBillings = false; // Sembunyikan menu Billing
          this.isShowSimulator = true;
        }
        
        if (this.profileData.rajaongkir_level === 3) {
          this.isShowDoc = true; // Tampilkan menu Document
          this.isShowBillings = true; // Tampilkan menu Billing
          this.isShowSimulator = true;
        }

        if (mode === 'development') {
          if (this.profileData.rajaongkir_level === 3 ||
            this.profileData.is_openapi === true) {
            this.isShowSimulator = true;
          }
        } else {
          this.isShowSimulator = false;
        }
      }
    });
  }
  

  setActiveButtonFromUrl(url: string) {
    this.menuItems.forEach((item) => {
      if (url.startsWith(item.link)) {
        this.activeButton = item.name;
        this.isOpen =
          item.name !== 'Profile' &&
          item.name !== 'PIN' &&
          item.name !== 'Bank Account' &&
          item.name !== 'Document';
      }
    });

    this.subMenuItems.forEach((subItem) => {
      if (url.startsWith(subItem.link)) {
        this.activeButton = subItem.name;
        this.isOpen = false;
      }
    });
  }

  toggleSettings() {
    this.isOpen = !this.isOpen;
  }

  setNavActive(data: any): void {
    this.isActive.emit(false);
  }

  ngOnDestroy(): void {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
  }
}
