<div class="card p-3 pb-5 mb-5 d-flex flex-column gap-3 mt-2">
  <nz-skeleton
    [nzLoading]="loading"
    [nzActive]="true"
    class="d-flex flex-column"
  >
    <div class="d-flex align-items-center gap-3">
      <img
        src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
        alt="back"
        (click)="back()"
      />
      <div class="title">Service Level Aggrement Data</div>
    </div>
    <div class="mt-4 py-3 px-4" style="background-color: #f8f8f8">
      <nz-steps
        [nzCurrent]="current"
        [nzDirection]="isMediumScreen ? 'vertical' : 'horizontal'"
        [nzStartIndex]="0"
        nzSize="small"
        class="d-flex flex-column flex-lg-row"
      >
        <nz-step nzTitle="Business Information"></nz-step>
        <nz-step nzTitle="Business Questionaire"></nz-step>
        <nz-step nzTitle="Business Escalation Information"></nz-step>
        <nz-step nzTitle="Service Usage"></nz-step>
      </nz-steps>
    </div>
    <app-business-information-input
      *ngIf="current === 0 && slaData"
      [slaDataInput]="slaData"
      (businessData)="collectData($event)"
    ></app-business-information-input>
    <app-business-questionaire-input
      *ngIf="current === 1 && slaData"
      [slaDataInput]="slaData"
      (businessData)="collectData($event)"
    ></app-business-questionaire-input>
    <app-business-escalation-input
      *ngIf="current === 2 && slaData"
      [slaDataInput]="slaData"
      (businessData)="collectData($event)"
    ></app-business-escalation-input>
    <app-service-usage-input
      *ngIf="current === 3 && slaData"
      [slaDataInput]="slaData"
      (businessData)="collectData($event)"
      (submitFinal)="submitFinal()"
    ></app-service-usage-input>
  </nz-skeleton>
</div>
<app-modal-confirm-back [isShowConfirm]="isConfirmBack" [docType]="'SLA'" (isShowConfirmEvent)="closeConfirmBack()"></app-modal-confirm-back>
